import { RadioButton } from 'core-ui';
import { MaximalQuantityProgramType } from 'graphql/queries';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { AdvanceField, CustomField } from 'scenes/program/components';
import { DepoSelectionModal } from 'scenes/program/modals';

export function DepotSelection() {
  const [isDepoSelectionVisible, setIsDepoSelectionVisible] = useState(false);

  const { values, onValuesChange } = useFormContext();
  const { maximalQuantityLimit, selectedDepots } = values;

  return (
    <>
      <CustomField
        headerText="Data Depo"
        bodyText="Tentukan depo yang akan mendapatkan program ini."
        buttonText="Pilih Depo"
        selectedText={`${selectedDepots.length} depo terpilih`}
        onPress={() => setIsDepoSelectionVisible(true)}
      >
        <AdvanceField label="Limit Kuantitas Maksimal">
          <View style={styles.flex}>
            <RadioButton<string>
              label="Limited"
              value="LIMITED"
              selected={maximalQuantityLimit === 'LIMITED'}
              onSelect={(value) =>
                onValuesChange({
                  maximalQuantityLimit: value as MaximalQuantityProgramType,
                })
              }
            />
          </View>
          <View style={styles.flex}>
            <RadioButton<string>
              label="Unlimited"
              value="UNLIMITED"
              selected={maximalQuantityLimit === 'UNLIMITED'}
              onSelect={(value) =>
                onValuesChange({
                  maximalQuantityLimit: value as MaximalQuantityProgramType,
                })
              }
            />
          </View>
        </AdvanceField>
      </CustomField>

      {isDepoSelectionVisible && (
        <DepoSelectionModal
          onClose={() => setIsDepoSelectionVisible(false)}
          selectedItems={selectedDepots}
          onChangeSelected={(selectedArray) =>
            onValuesChange({
              selectedDepots: selectedArray,
              selectedCustomers: [],
            })
          }
          isVisible={isDepoSelectionVisible}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
});
