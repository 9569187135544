import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button, Icon, Separator, Text } from '../../../core-ui';
import {
  AccessProps,
  ClosingParams,
  ClosingsData,
  GET_CLOSINGS,
  GET_COMPLETED_ORDER_LIST,
  GET_PROVIDER_INFO,
  Order,
  GetOrderListResult as OrdersData,
} from '../../../graphql/queries';
import withToast, { ToastContextProps } from '../../../helpers/withToast';

import OrderDetailModal from '../../assignmentLists/components/OrderDetailModal';
import { calculatePoValue } from '../../order/helpers';

import {
  DepotPicker,
  Query,
  ResetFilterButton,
  RowsPerPage,
} from '../../../components';
import { PRIMARY } from '../../../constants/colors';
import { formatPriceToRupiah } from '../../../helpers';
import { ClosingDayTable } from './Table';

export type ClosingDaySceneProps = ToastContextProps & AccessProps;

export type ClosingFilter = {
  depot: Nullable<string>;
};

function ClosingDaySceneComponent({ access }: ClosingDaySceneProps) {
  const { create, role } = access;

  const [isClosing, setIsClosing] = useState(false);
  const [actionData, setActionData] = useState<Nullable<Order>>(null);
  const [isAlreadyClosed, setIsAlreadyClosed] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState<RowsPerPage>(10);
  const [filter, setFilter] = useState<ClosingFilter>({
    depot: null,
  });
  const [page, setPage] = useState(0);

  const closingStartDate = new Date().setHours(0, 0, 0);
  const closingEndDate = new Date().setHours(24, 0, 0);

  const getTransactionIncome = (orders: Array<Order>) => {
    return orders.reduce((acc: number, order) => {
      const { creditPointUse = 0, paymentStatus } = order;
      return (
        acc +
        (paymentStatus === 'PAID'
          ? Math.max(0, calculatePoValue(order) - creditPointUse)
          : 0)
      );
    }, 0);
  };

  const onDetail = (order: Order) => {
    setActionData(order);
  };

  const onDetailModalClose = () => {
    setActionData(null);
  };

  const onClosingConfirmModalClose = () => {
    setIsClosing(false);
  };

  const onRowsPerPageChange = (rowsPerPage: RowsPerPage) => {
    setRowsPerPage(rowsPerPage);
  };

  const clearFilter = () => {
    setFilter({
      depot: null,
    });
    setPage(0);
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text size="xlarge">Tutup Penjualan</Text>
        {create && (
          <Button
            text="Tutup Pembukuan"
            inverted
            onPress={() => setIsClosing(true)}
            disabled={
              isAlreadyClosed || (role === 'SUPER_ADMIN' && !filter.depot)
            }
          />
        )}
      </View>
      <Separator />
      {role === 'SUPER_ADMIN' && (
        <View style={styles.filters}>
          <View style={styles.filterRow}>
            <DepotPicker
              isFilter
              style={styles.filterMargin}
              selectedOption={filter.depot}
              onChange={(selected) => {
                setFilter({
                  depot: selected ? selected.value : null,
                });
                setPage(0);
              }}
            />
          </View>
          <ResetFilterButton onPress={clearFilter} />
        </View>
      )}
      <Query<{
        info: { id: string; role: string; depot: { id: string } };
      }>
        query={GET_PROVIDER_INFO}
      >
        {({ data: providerData }) => {
          const closingVariables = {
            where: {
              closingDate_gte: new Date(closingStartDate).toISOString(),
              closingDate_lt: new Date(closingEndDate).toISOString(),
              transactions_some:
                filter.depot || providerData?.info?.role !== 'SUPER_ADMIN'
                  ? {
                      depot: {
                        id:
                          filter.depot ||
                          (providerData && providerData.info.depot
                            ? providerData.info.depot.id
                            : null),
                      },
                    }
                  : undefined,
            },
          };

          return (
            <Query<ClosingsData, ClosingParams>
              query={GET_CLOSINGS}
              variables={closingVariables}
              keyData="closings"
              onCompleted={(data) => {
                setIsAlreadyClosed([...(data.closings || [])].length > 0);
              }}
            >
              {({ data, loading, fetchMore, refetch }) => {
                if (data && [...(data.closings || [])].length > 0) {
                  const { closings } = data;
                  const todayIncome = getTransactionIncome(
                    closings[0].transactions,
                  );

                  return (
                    <View style={styles.emptyContainer}>
                      <Icon
                        isCustomSVG
                        name="clockCancel"
                        color={PRIMARY}
                        size="xxlarge"
                      />
                      <Text style={{ paddingBottom: 10 }}>
                        Anda telah menutup penjualan pada hari ini.
                      </Text>
                      <Text>
                        Pemesanan di atas jam 14.00 dan di hari kerja akan
                        diproses hari berikutnya.
                      </Text>
                      <Text>
                        Pemasukkan hari ini {formatPriceToRupiah(todayIncome)}
                      </Text>
                      <View style={styles.bodyWrapper}>
                        <ClosingDayTable
                          count={closings[0].transactions.length}
                          data={closings[0].transactions}
                          dataKey="closings"
                          fetchMore={fetchMore}
                          refetch={refetch}
                          loading={loading}
                          isModalOpen={isClosing}
                          onDetail={onDetail}
                          onModalClose={onClosingConfirmModalClose}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={onRowsPerPageChange}
                          filter={{
                            depot:
                              filter.depot ||
                              (providerData && providerData.info.depot
                                ? providerData.info.depot.id
                                : null),
                          }}
                          page={page}
                          onPageChange={(nextPage) => setPage(nextPage)}
                          variables={closingVariables}
                        />
                      </View>
                    </View>
                  );
                }

                const orderVariables = {
                  first: rowsPerPage,
                  skip: 0,
                  where: {
                    AND: [
                      { status: 'COMPLETED' },
                      { paymentStatus: 'PAID' },
                      { OR: [{ closingId: null }, { closingId: '' }] },
                    ],
                    depot:
                      filter.depot || providerData?.info?.role !== 'SUPER_ADMIN'
                        ? {
                            id:
                              filter.depot ||
                              (providerData && providerData.info.depot
                                ? providerData.info.depot.id
                                : null),
                          }
                        : undefined,
                  },
                };

                return (
                  <Query<OrdersData>
                    query={GET_COMPLETED_ORDER_LIST}
                    variables={orderVariables}
                    keyData="orders"
                  >
                    {({ data, loading: ordersLoading, fetchMore, refetch }) => (
                      <ClosingDayTable
                        count={data?.count ?? 0}
                        data={data?.orders ?? []}
                        dataKey="orders"
                        fetchMore={fetchMore}
                        isModalOpen={isClosing}
                        loading={ordersLoading}
                        onDetail={onDetail}
                        refetch={refetch}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={onRowsPerPageChange}
                        onModalClose={onClosingConfirmModalClose}
                        filter={{
                          depot:
                            filter.depot ||
                            (providerData && providerData.info.depot
                              ? providerData.info.depot.id
                              : null),
                        }}
                        page={page}
                        onPageChange={(nextPage) => setPage(nextPage)}
                        variables={orderVariables}
                      />
                    )}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
      {actionData ? (
        <OrderDetailModal
          order={actionData}
          isVisible={!!actionData}
          onClose={onDetailModalClose}
        />
      ) : null}
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  bodyWrapper: {
    flex: 1,
    paddingTop: 20,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column: { paddingVertical: 8 },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  firstRowText: { letterSpacing: 1.5, paddingBottom: 8 },
  filters: {
    alignItems: 'flex-start',
    paddingVertical: 10,
    zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterMargin: {
    marginRight: 15,
  },
});

export const ClosingDayScene = withToast(ClosingDaySceneComponent);
