import { Box } from '@material-ui/core';
import { DARK_GRAY } from 'constants/colors';
import {
  ADD_USAGE_CREDIT_POINT_PERCENTAGE,
  AddUsageCreditPointPercentageParams,
  AddUsageCreditPointPercentageResult,
} from 'graphql/mutations/usageCreditPointPercentageMutation';
import {
  GET_USAGE_PERCENTAGE_CREDIT_POINT,
  UsagePercentageCreditPointResult,
} from 'graphql/queries/usageCreditPointPercentageQuery';
import React, { useCallback, useState } from 'react';
import { Mutation, Query } from 'react-apollo';
import { IncrementField, Modal, Text } from '../../../core-ui';
import withToast, { ToastContextProps } from '../../../helpers/withToast';

export type PercentageCreditPointModalProps = {
  isCreditPoinSettingVisible: boolean;
  setIsCreditPoinSettingVisible: (visible: boolean) => void;
} & ToastContextProps;

function PercentageCreditPointModalComponent({
  isCreditPoinSettingVisible,
  setIsCreditPoinSettingVisible,
  openToast,
}: PercentageCreditPointModalProps) {
  const [percentageValue, setPercentageValue] = useState<number | undefined>();

  const onSyncError = useCallback(() => {
    openToast('fail', 'Gagal memperbarui data pemesanan.');
  }, [openToast]);

  return (
    <Query<UsagePercentageCreditPointResult, any>
      query={GET_USAGE_PERCENTAGE_CREDIT_POINT}
      fetchPolicy="cache-and-network"
    >
      {({ data }) => {
        if (data?.usagePercentageCreditPoint && percentageValue === undefined) {
          setPercentageValue(
            data?.usagePercentageCreditPoint[0]?.usagePercentage,
          );
        }
        return (
          <Mutation<
            AddUsageCreditPointPercentageResult,
            AddUsageCreditPointPercentageParams
          >
            mutation={ADD_USAGE_CREDIT_POINT_PERCENTAGE}
            variables={{
              data: percentageValue || 0,
            }}
            onCompleted={() => {
              openToast &&
                openToast(
                  'success',
                  'Persentase kredit poin berhasil diperbarui.',
                );
              setIsCreditPoinSettingVisible(false);
            }}
            onError={onSyncError}
          >
            {(submit, { loading }) => (
              <Modal
                isVisible={isCreditPoinSettingVisible}
                title="Pengaturan Kredit Poin"
                description={
                  <Text size="small" color={DARK_GRAY}>
                    Masukkan persentase kredit poin maksimal
                  </Text>
                }
                buttonText="Terapkan"
                closeButtonText="Batal"
                onSubmit={() => {
                  submit();
                }}
                submitLoading={loading}
                fullWidth={true}
                children={
                  <Box
                    style={{
                      marginTop: 20,
                    }}
                  >
                    <IncrementField
                      stretch
                      labelHorizontal
                      label="Persentase Potongan"
                      style={{ height: 30 }}
                      placeholder=" "
                      value={percentageValue || 0}
                      minValue={0}
                      maxValue={100}
                      onChangeValue={(value) => {
                        setPercentageValue(value > 100 ? 100 : value);
                      }}
                      onButtonPress={(value) => setPercentageValue(value)}
                    />
                  </Box>
                }
                onClose={() => setIsCreditPoinSettingVisible(false)}
              />
            )}
          </Mutation>
        );
      }}
    </Query>
  );
}

export const PercentageCreditPointModal = withToast(
  PercentageCreditPointModalComponent,
);
