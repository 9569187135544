import React, { Fragment, useState, useMemo, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { StyleSheet, View } from 'react-native';
import { STATUS_OPTS } from '../../program/helpers/programOptions';

import { sanitizeNumericInput } from '../../../helpers/formatNumber';
import { Query, FormPage } from '../../../components';
import {
  Card,
  TextField,
  Dropdown,
  DatePickerField,
  CurrencyField,
  Dropzone,
  Text,
} from '../../../core-ui';
import { convertPascalCase, formatThousandSeparator } from '../../../helpers';

import {
  GET_COUPON,
  CouponType,
  GetCouponResult,
  GetCouponParams,
  CouponProduct,
  AdditionalProduct,
} from '../../../graphql/queries';
import { GRAY70 } from '../../../constants/colors';
import ButtonField from '../../../core-ui/ButtonField';
import ProductSelectionModal from './ProductSelectionModal';

type FormState = {
  title: string;
  couponType: Nullable<CouponType>;
  couponRewardValue: string;
  couponLimit: string;
  couponPrice: string;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  status: Nullable<boolean>;
  description: string;
  couponImage?: File;
  couponImageUrl?: string;
  initialProducts?: Array<CouponProduct>;
  products?: Array<CouponProduct>;
  initialAdditionalProducts?: Array<AdditionalProduct>;
  additionalProducts?: Array<AdditionalProduct>;
};

type FormProps = {
  couponID?: string;
  isEdit: boolean;
  submitLoading: boolean;
  onSubmit: (data: FormState) => void;
} & Pick<RouteComponentProps, 'history'>;

export function Form({
  couponID,
  isEdit,
  submitLoading,
  history,
  onSubmit,
}: FormProps) {
  const COUPON_TYPE_OPTIONS = [
    { label: 'Cashback', value: 'CASHBACK' as CouponType },
    { label: 'Potongan', value: 'POTONGAN' as CouponType },
    { label: 'Produk', value: 'PRODUK' as CouponType },
  ];

  // NOTE: this is used to indicate whether the Query to get coupon data has completed.
  //       defaults to true if it's a create form.
  const [isReady, setReady] = useState(false);
  // NOTE: this is used to reset form state value when the user discard changes.
  const [defaultState, setDefaultState] = useState<FormState>({
    title: '',
    couponType: null,
    couponRewardValue: '0',
    couponLimit: '',
    couponPrice: '',
    startDate: null,
    endDate: null,
    status: null,
    description: '',
    products: [],
    additionalProducts: [],
  });
  // NOTE: this is used to store form state when user fills the form.
  const [formState, setFormState] = useState<FormState>(defaultState);
  const [isDirty, setDirty] = useState(false);
  const [photoPreview, setPhotoPreview] = useState('');
  const [photoFile, setPhotoFile] = useState<File>();

  // NOTE: this is used to check whether the form is valid based on form state and product selections.
  const isFormValid = useMemo(() => {
    const {
      title,
      couponType,
      couponRewardValue,
      couponLimit,
      couponPrice,
      startDate,
      endDate,
      status,
      description,
      products,
      additionalProducts,
    } = formState;
    return (
      title !== '' &&
      couponType != null &&
      couponRewardValue !== '' &&
      couponLimit !== '' &&
      !Number.isNaN(Number(couponLimit)) &&
      couponPrice !== '' &&
      startDate != null &&
      endDate != null &&
      status != null &&
      description !== '' &&
      products !== null &&
      additionalProducts !== null
    );
  }, [formState]);

  // NOTE: this is used as the lowest value for end date calendar picker.
  const minDate = useMemo(() => {
    if (formState.startDate) {
      const date = new Date(formState.startDate);
      date.setDate(date.getDate() + 1);
      return date;
    }
    return null;
  }, [formState.startDate]);

  const [isModalClicked, setIsModalClicked] = useState<boolean>(false);

  const _trailingText = () => {
    let numberOfProducts = 0;
    numberOfProducts += formState.products?.length ?? 0;
    numberOfProducts += formState.additionalProducts?.length ?? 0;
    const text = numberOfProducts.toString() + ' Produk dipilih';
    return text;
  };

  const _couponTypeLabel = (couponType: CouponType) => {
    switch (couponType) {
      case 'CASHBACK':
        return 'Jumlah Cashback';
      default:
        return 'Jumlah Potongan';
    }
  };

  const _renderJumlah = () => {
    return (
      (formState.couponType === 'CASHBACK' ||
        formState.couponType === 'POTONGAN') && (
        <CurrencyField
          stretch
          labelHorizontal
          label={_couponTypeLabel(formState.couponType!)}
          placeholder={_couponTypeLabel(formState.couponType!)}
          currencyValue={Number(formState.couponRewardValue)}
          onCurrencyChange={(value) => {
            setFormState((prevState) => ({
              ...prevState,
              couponRewardValue: String(value),
            }));
            setDirty(true);
          }}
        />
      )
    );
  };

  const _onModalClick = () => {
    setIsModalClicked(!isModalClicked);
  };

  const _renderModal = () => {
    return (
      isModalClicked && (
        <ProductSelectionModal
          onClose={_onModalClick}
          selectedItems={formState.products ?? []}
          onChangeSelected={(selectedArray) => {
            setFormState((prevState) => ({
              ...prevState,
              products: selectedArray,
            }));
            setDirty(true);
          }}
          otherProducts={formState.additionalProducts ?? []}
          onChangeOtherProductFields={(values) => {
            setFormState((prevState) => ({
              ...prevState,
              additionalProducts: values
            }));
          }}
        />
      )
    );
  };

  return (
    <Query<GetCouponResult, Partial<GetCouponParams>>
      query={GET_COUPON}
      variables={{ id: couponID }} // NOTE: we skip the query if couponID is null so this should be OK
      skip={!couponID}
      fetchPolicy="network-only" // NOTE: add this to get onCompleted called
      onCompleted={(data) => {
        if (!isReady && Object.getOwnPropertyNames(data).includes('coupon')) {
          const { coupon } = data as GetCouponResult;
          const newDefaultState = {
            title: convertPascalCase(coupon.title),
            couponType: coupon.couponType,
            couponRewardValue:
              coupon.couponType === 'PRODUK'
                ? '0'
                : String(coupon.couponRewardValue),
            couponLimit: String(coupon.couponLimit),
            couponPrice: String(coupon.couponPrice),
            startDate: new Date(coupon.startDate),
            endDate: new Date(coupon.endDate),
            status: coupon.active,
            description: coupon.description,
            couponImageUrl: coupon.couponImage,
            products: coupon.products,
            initialProducts: coupon.products,
            additionalProducts: coupon.additionalProducts,
            initialAdditionalProducts: coupon.additionalProducts,
          };
          setDefaultState(newDefaultState);
          setFormState(newDefaultState);
          setReady(true);
          setPhotoPreview(coupon.couponImage);
        }
      }}
    >
      {() => (
        <FormPage
          showBackButton
          isValid={isFormValid}
          isDirty={isDirty}
          isSubmitLoading={submitLoading}
          handleSubmit={() =>
            onSubmit({
              ...formState,
              couponImage: photoFile,
            })
          }
          handleDiscard={() => {
            setFormState(defaultState);
            setDirty(false);
          }}
          handleGoBack={history.goBack}
          submitTitle={isEdit ? 'Simpan' : 'Tambah'}
        >
          {_renderModal()}
          <Card title={isEdit ? 'Ubah Informasi Kupon' : 'Tambah Kupon'}>
            <>
              <TextField
                stretch
                labelHorizontal
                label="Nama Kupon"
                placeholder="Nama Kupon"
                value={formState.title}
                onChangeText={(text) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    title: text,
                  }));
                  setDirty(true);
                }}
              />
              <Dropdown<CouponType>
                labelHorizontal
                label="Jenis Kupon"
                placeholder="Jenis Kupon"
                selectedOption={formState.couponType}
                options={COUPON_TYPE_OPTIONS}
                onChange={(selectedOption) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    couponType: selectedOption ? selectedOption.value : null,
                  }));
                  setDirty(true);
                }}
                style={{ zIndex: 4 }}
              />
              {formState.couponType === 'PRODUK' && (
                <ButtonField
                  inverted
                  labelHorizontal
                  label="Pilih Produk"
                  buttonText="Pilih Produk"
                  onPress={_onModalClick}
                  trailingText={_trailingText()}
                />
              )}
              {_renderJumlah()}
              <TextField
                stretch
                labelHorizontal
                label="Jumlah Kupon"
                placeholder="Jumlah Kupon"
                value={formatThousandSeparator(Number(formState.couponLimit))}
                onChangeText={(text) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    couponLimit: sanitizeNumericInput(text),
                  }));
                  setDirty(true);
                }}
              />
              <TextField
                stretch
                labelHorizontal
                label="Harga Poin/Kupon"
                placeholder="Harga Poin/Kupon"
                value={formatThousandSeparator(Number(formState.couponPrice))}
                onChangeText={(text) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    couponPrice: sanitizeNumericInput(text),
                  }));
                  setDirty(true);
                }}
              />
              <DatePickerField
                labelHorizontal
                label="Tanggal Mulai Berlaku"
                placeholder="Tanggal Mulai Berlaku"
                selectedDate={formState.startDate}
                onChange={(selectedDate) => {
                  let { endDate } = formState;
                  if (endDate && endDate <= selectedDate) {
                    endDate = new Date(selectedDate);
                    endDate.setDate(endDate.getDate() + 1);
                  }
                  setFormState((prevState) => ({
                    ...prevState,
                    startDate: selectedDate,
                    endDate,
                  }));
                  setDirty(true);
                }}
                style={{ zIndex: 3 }}
              />
              <DatePickerField
                labelHorizontal
                label="Tanggal Akhir Berlaku"
                placeholder="Tanggal Akhir Berlaku"
                selectedDate={formState.endDate}
                onChange={(selectedDate) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    endDate: selectedDate,
                  }));
                  setDirty(true);
                }}
                style={{ zIndex: 2 }}
                {...(minDate ? { minDate } : {})}
              />
              <Dropdown<boolean>
                labelHorizontal
                label="Status Kupon"
                placeholder="Status Kupon"
                selectedOption={formState.status}
                options={STATUS_OPTS}
                onChange={(selectedOption) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    status: selectedOption
                      ? selectedOption.value
                      : selectedOption,
                  }));
                  setDirty(true);
                }}
                style={{ zIndex: 1 }}
              />
              <TextField
                stretch
                labelHorizontal
                multiline
                label="Deskripsi Kupon"
                placeholder="Deskripsi Kupon"
                value={formState.description}
                onChangeText={(text) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    description: text,
                  }));
                  setDirty(true);
                }}
              />
              <View style={styles.imageField}>
                <Text
                  size="small"
                  color={GRAY70}
                  style={styles.imageFieldTitle}
                >
                  Foto Kupon
                </Text>
                <View style={styles.dropzone}>
                  <Dropzone
                    multiple={false}
                    getPreview={(previews) => {
                      setPhotoPreview(previews[0].preview);
                      setPhotoFile(previews[0].file);
                      setDirty(true);
                    }}
                    source={photoPreview ? { uri: photoPreview } : undefined}
                  />
                  <Text size="small" color={GRAY70} style={styles.messagePhoto}>
                    Ratio 4:1.5 (Contoh 400px x 150px, 1200px x 450px, 2400px x
                    900px
                  </Text>
                </View>
              </View>
            </>
          </Card>
        </FormPage>
      )}
    </Query>
  );
}

const styles = StyleSheet.create({
  imageField: {
    flexDirection: 'row',
  },
  imageFieldTitle: {
    flex: 1,
    paddingVertical: 8,
  },
  dropzone: {
    flex: 2,
    flexDirection: 'row',
  },
  messagePhoto: {
    marginLeft: 12,
  },
  buttonProduct: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
});
