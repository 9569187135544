import * as React from 'react';
import {
  DataValue,
  Mutation,
  MutationFn,
  MutationFunc,
  compose,
  graphql,
} from 'react-apollo';
import { Image, StyleSheet, View } from 'react-native';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  ColumnType,
  Query,
  ReactTable,
  SearchField,
} from '../../../components';
import {
  BLACK,
  DARK_GRAY,
  GRAY,
  PRIMARY,
  RED,
} from '../../../constants/colors';
import { Button, Icon, Modal, Separator, Text } from '../../../core-ui';
import { SearchState } from '../../../graphql/localState';
import {
  DELETE_HEADER_HIGHLIGHT,
  UPDATE_HEADER_HIGHLIGHT,
} from '../../../graphql/mutations';
import {
  AccessProps,
  GET_SEARCH_STATE,
  UPDATE_SEARCH_STATE,
} from '../../../graphql/queries';
import {
  GET_HEADER_HIGHLIGHTS,
  GetHeaderHighlights,
  HeaderHighlight,
} from '../../../graphql/queries/headerQuery';
import { refetchItems, shiftIndexDown, shiftIndexUp } from '../../../helpers';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import HeaderHighlightDetailModal from './HeaderHighlightDetailModal';

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

type HeaderHighlightSceneProps = SearchStateProps &
  UpdateSearchData &
  AccessProps &
  RouteComponentProps &
  ToastContextProps;

function HeaderHighlightSceneComponent({
  searchStateQuery: { searchState },
  updateSearch,
  access: {
    create: createAccess,
    delete: deleteAccess,
    update: updateAccess,
    read: readAccess,
  },
  history,
  openToast,
}: HeaderHighlightSceneProps) {
  const [isDetailVisible, setIsDetailVisible] = React.useState(false);
  const [actionDataID, setActionDataID] = React.useState<Nullable<string>>(
    null,
  );
  const [actionData, setActionData] = React.useState<
    Nullable<{
      id: string;
      title: string;
    }>
  >(null);
  const [isDeleteVisible, setDeleteVisible] = React.useState(false);

  const columns = React.useCallback<
    (
      data: HeaderHighlight[],
      updateHeader: MutationFn<
        null,
        {
          id: string;
          data: {
            order: number;
          };
        }
      >,
    ) => ColumnType<HeaderHighlight>[]
  >(
    (headers, updateHeader) => [
      {
        accessorKey: 'title',
        header: 'Nama Header',
        size: 300,
      },
      {
        accessorKey: 'description',
        header: 'Deskripsi',
        size: 300,
      },
      {
        accessorKey: 'headerImage',
        header: 'Gambar Header',
        size: 300,
        cell: ({ getValue }) => (
          <Image
            resizeMode="cover"
            source={{
              uri: getValue<string>(),
            }}
            style={styles.headerImage}
          />
        ),
      },
      {
        id: 'order',
        header: 'Urutan',
        cell: ({ row }) => {
          return (
            <View style={styles.orderWrapper}>
              <Text
                size="small"
                style={{
                  marginRight: 2,
                }}
              >
                {row.index + 1}
              </Text>
              {updateAccess && (
                <View>
                  <Icon
                    size="xsmall"
                    name="keyboard_arrow_up"
                    disabled={row.index === 0}
                    color={row.index === 0 ? GRAY : BLACK}
                    data-testid="shift-up-button"
                    onPress={() => {
                      const newHeaderData = shiftIndexUp(
                        headers || [],
                        row.index,
                      );
                      const newIndex =
                        newHeaderData.findIndex(
                          (data) => data.id === row.original.id,
                        ) || 0;

                      updateHeader({
                        variables: {
                          id: row.original.id,
                          data: {
                            order: newIndex + 1,
                          },
                        },
                      });
                    }}
                  />
                  <Icon
                    size="xsmall"
                    name="keyboard_arrow_down"
                    disabled={row.index === (headers || []).length - 1}
                    color={
                      row.index === (headers || []).length - 1 ? GRAY : BLACK
                    }
                    data-testid="shift-down-button"
                    onPress={() => {
                      const newHeaderData = shiftIndexDown(
                        headers || [],
                        row.index,
                      );
                      const newIndex =
                        newHeaderData.findIndex(
                          (data) => data.id === row.original.id,
                        ) || 0;

                      updateHeader({
                        variables: {
                          id: row.original.id,
                          data: {
                            order: newIndex + 1,
                          },
                        },
                      });
                    }}
                  />
                </View>
              )}
            </View>
          );
        },
      },
      {
        id: 'action',
        cell: ({ row }) => {
          return (
            <View style={styles.actionWrapper}>
              {readAccess && (
                <Icon
                  size="small"
                  name="description"
                  color={GRAY}
                  hoverColor={PRIMARY}
                  onPress={() => {
                    setIsDetailVisible(true);
                    setActionDataID(row.original.id);
                  }}
                  data-testid="detail-button"
                />
              )}
              {updateAccess && (
                <Link
                  to={{
                    pathname: `header-highlights/${row.original.id}`,
                    state: { programData: row.original },
                  }}
                  title="Edit Header Highlight"
                  data-testid="edit-button"
                >
                  <Icon
                    size="small"
                    name="edit"
                    color={GRAY}
                    hoverColor={PRIMARY}
                  />
                </Link>
              )}
              {deleteAccess && (
                <Icon
                  size="small"
                  name="delete"
                  color={GRAY}
                  hoverColor={RED}
                  onPress={() => {
                    setActionData({
                      id: row.original.id,
                      title: row.original.title,
                    });
                    setDeleteVisible(true);
                  }}
                  title="Hapus Header Highlight"
                  data-testid="delete-button"
                />
              )}
            </View>
          );
        },
      },
    ],
    [deleteAccess, readAccess, updateAccess],
  );

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text size="xlarge" data-testid="header-title">
          Header Highlight
        </Text>
        <View style={styles.flexRow}>
          <SearchField
            value={searchState ? searchState.searchedString : ''}
            onChangeText={(text) =>
              updateSearch({ variables: { searchedString: text } })
            }
          />
          {createAccess && (
            <Button
              icon="add"
              iconPosition="left"
              text="Tambah Header Highlight"
              onPress={() => history.push('/header-highlights/new')}
              data-testid="add-button"
            />
          )}
        </View>
      </View>
      <Separator style={styles.separator} />
      <Query<GetHeaderHighlights>
        query={GET_HEADER_HIGHLIGHTS}
        keyData="headers"
        fetchPolicy="network-only"
        variables={{
          skip: 0,
          where: {
            title_contains: searchState ? searchState.searchedString : '',
          },
          order: 'order_ASC',
        }}
        notifyOnNetworkStatusChange
        disableLoading
      >
        {({ data, fetchMore, loading, refetch }) => (
          <Mutation<null, { id: string }>
            mutation={DELETE_HEADER_HIGHLIGHT}
            onCompleted={() => {
              openToast('success', 'Berhasil menghapus header highlight');
              refetchItems<GetHeaderHighlights>(fetchMore, {
                query: GET_HEADER_HIGHLIGHTS,
                variables: {
                  where: {
                    title_contains: searchState
                      ? searchState.searchedString
                      : '',
                  },
                  order: 'order_ASC',
                },
                dataKey: 'headers',
              });
              setDeleteVisible(false);
              setActionData(null);
            }}
          >
            {(deleteHeader, { loading: deleteLoading }) => (
              <Mutation<null, { id: string; data: { order: number } }>
                mutation={UPDATE_HEADER_HIGHLIGHT}
                onCompleted={() => {
                  openToast(
                    'success',
                    'Berhasil mengubah urutan header highlight',
                  );
                  refetch();
                }}
              >
                {(updateHeader, { loading: updateLoading }) => (
                  <>
                    <ReactTable
                      data={data?.headers ?? []}
                      columns={columns(data?.headers ?? [], updateHeader)}
                      isLoading={loading || updateLoading || deleteLoading}
                    />
                    <HeaderHighlightDetailModal
                      data={data?.headers ?? []}
                      isVisible={isDetailVisible}
                      dataID={actionDataID}
                      onClose={() => setIsDetailVisible(false)}
                    />
                    <Modal
                      hideHeaderClose
                      isVisible={isDeleteVisible}
                      closeButtonText="Tidak"
                      title="Hapus Header Highlight"
                      buttonText="Hapus"
                      submitLoading={deleteLoading}
                      description={
                        <Text size="small" color={DARK_GRAY}>
                          Apakah Anda yakin ingin menghapus{' '}
                          <Text size="small" weight="bold" color={BLACK}>
                            {actionData && actionData.title}
                          </Text>{' '}
                          dari daftar header?
                        </Text>
                      }
                      onClose={() => {
                        setDeleteVisible(false);
                        setActionData(null);
                      }}
                      onSubmit={() => {
                        if (actionData) {
                          deleteHeader({ variables: { id: actionData.id } });
                        }
                      }}
                    />
                  </>
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </Query>
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  separator: {
    marginTop: 20,
    marginBottom: 10,
  },
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerImage: {
    width: '100%',
    height: 150,
  },
  orderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export const HeaderHighlightScene = compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, {}, UpdateSearchData>(UPDATE_SEARCH_STATE, {
    name: 'updateSearch',
  }),
  withToast,
)(HeaderHighlightSceneComponent);
