import { useMemo } from 'react';
import { calculateBudget } from '../components/Form/helpers';
import { useFormContext } from '../contexts';

export function useCalculatedTotalPriceBudget() {
  const { values } = useFormContext();
  const {
    programType,
    claimLimit,
    claimType,
    selectedBundleProducts,
    selectedCustomers,
    selectedFlashSaleTerms,
    selectedLoyaltyTerms,
    selectedTerms,
  } = values;

  const totalPriceCalculatedBudget = useMemo(() => {
    return calculateBudget({
      programType,
      claimLimit,
      claimType,
      selectedBundleProducts,
      selectedCustomers,
      selectedFlashSaleTerms,
      selectedLoyaltyTerms,
      selectedTerms,
    });
  }, [
    programType,
    claimLimit,
    claimType,
    selectedBundleProducts,
    selectedCustomers,
    selectedFlashSaleTerms,
    selectedLoyaltyTerms,
    selectedTerms,
  ]);

  return totalPriceCalculatedBudget;
}
