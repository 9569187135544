import { FormState } from '../NewProgramFormScene';

export function isFormValid(props: FormState) {
  const {
    programType,
    rewardType,
    title,
    description,
    startDate,
    endDate,
    selectedDepots,
    selectedSegments,
    selectedSubSegments,
    giftType,
    giftName,
    giftPrice,
    claimType,
    claimLimit,
    selectedProducts,
    selectedCustomers,
    selectedTerms,
    selectedLoyaltyTerms,
    selectedBundleProducts,
    selectedFlashSaleTerms,
    titleNotification,
    bodyNotification,
    maximalQuantity,
  } = props;
  const defaultFormCheck =
    programType !== null &&
    title !== '' &&
    description !== '' &&
    startDate !== null &&
    endDate !== null &&
    selectedDepots.length > 0 &&
    selectedSegments.length > 0 &&
    selectedSubSegments.length > 0 &&
    selectedCustomers.length > 0 &&
    titleNotification !== '' &&
    bodyNotification !== '' &&
    maximalQuantity > 0;

  if (programType === 'TRADE_PROMO') {
    return (
      defaultFormCheck &&
      rewardType !== null &&
      claimType !== null &&
      claimLimit !== '' &&
      selectedProducts.length > 0 &&
      selectedTerms.length > 0
    );
  }
  if (programType === 'LOYALTY') {
    if (rewardType === 'CASHBACK') {
      return (
        defaultFormCheck &&
        rewardType !== null &&
        selectedProducts.length > 0 &&
        selectedLoyaltyTerms.length > 0 &&
        selectedCustomers.length > 0 &&
        selectedLoyaltyTerms.length === selectedCustomers.length
      );
    }
    if (rewardType === 'PRESENT') {
      return (
        defaultFormCheck &&
        rewardType !== null &&
        selectedProducts.length > 0 &&
        selectedLoyaltyTerms.length > 0 &&
        (giftType === 'ANOTHER_PRODUCT'
          ? giftName !== '' && giftPrice > 0
          : true)
      );
    }
    if (rewardType === 'TVIP_PRODUCT') {
      return (
        defaultFormCheck &&
        rewardType !== null &&
        selectedProducts.length > 0 &&
        selectedLoyaltyTerms.length > 0
      );
    }
  } else if (programType === 'BUNDLE') {
    return (
      defaultFormCheck && claimLimit !== '' && selectedBundleProducts.length > 0
    );
  } else if (programType === 'FLASH_SALE') {
    return (
      defaultFormCheck &&
      claimLimit !== '' &&
      selectedProducts.length > 0 &&
      selectedFlashSaleTerms.size > 0
    );
  }
  return false;
}
