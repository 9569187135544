import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import TablePaginationActions from 'components/Table/TablePagination';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, {
  Dispatch,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  BACKDROP_TRANSPARENT,
  GRAY,
  GRAY80,
  WHITE,
} from '../../constants/colors';
import { Text } from '../../core-ui';
import { formatNumberDate, formatThousandSeparator } from '../../helpers';
import { TableLoader } from './TableLoader';

dayjs.extend(utc);

export const ROWS_PER_PAGE_OPTIONS = [5, 10, 25, 50];

export type ColumnType<TData extends { id: string }> = ColumnDef<TData> & {
  format?: 'date' | 'datetime' | 'datetime-tz' | 'range-date' | 'currency';
};
export type ReactTableProps<TData extends { id: string } = any> = {
  data: TData[];
  columns: ColumnType<TData>[];
  total?: number;
  isLoading?: boolean;
  pagination?: PaginationState;
  setPagination?: Dispatch<SetStateAction<PaginationState>>;
  rowSelection?: Record<string, boolean>;
  setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>;
  onSelectionChange?: (ids: string[], selectedRows: TData[]) => void;
  getRowId?: (row: TData) => string;
};

const useStyles = makeStyles({
  root: {
    backgroundColor: 'transparent',
  },
  table: {
    position: 'relative',
  },
  bodyRoot: {
    backgroundColor: 'transparent',
    paddingRight: 1,
    overflowX: 'auto',
  },
  headerRow: {
    color: BACKDROP_TRANSPARENT,
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
  },
  body: {
    border: `1px solid ${GRAY}`,
    backgroundColor: WHITE,
    fontSize: 15,
  },
  row: {
    padding: '0 10px',
    minHeight: 60,
    backgroundColor: WHITE,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${GRAY}`,
    },
  },
  cell: {
    display: 'inline-block',
    fontFamily: 'Rubik-Regular',
    fontSize: 15,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    padding: 10,
    borderBottom: 'none',
  },
  cellHeader: {
    fontFamily: 'Rubik-Medium',
    display: 'inline-block',
    fontSize: 13,
    textTransform: 'uppercase',
    color: GRAY80,
    padding: 10,
    borderBottom: 'none',
  },
  cellNoData: {
    textAlign: 'center',
    borderBottom: 'none',
    position: 'fixed',
  },
  tableCaption: {
    borderStyle: 'none',
    fontSize: 15,
    color: BACKDROP_TRANSPARENT,
    fontFamily: 'Rubik-Regular',
  },
});

export function ReactTableComponent<TData extends { id: string }>({
  data,
  columns,
  total,
  isLoading,
  pagination,
  setPagination,
  rowSelection = {},
  setRowSelection,
  onSelectionChange,
  getRowId = (row) => row.id,
}: ReactTableProps<TData>) {
  const classes = useStyles();

  const [selectedRows, setSelectedRows] = useState<TData[]>([]);

  const numberedColumns: ColumnType<TData>[] =
    rowSelection && setRowSelection
      ? []
      : [
          {
            accessorKey: 'rowNumber',
            header: () => 'No',
            cell: (context) => context.cell.row.index + 1,
            size: 25,
            minSize: 25,
          },
        ];

  const checkboxColumn: ColumnType<TData>[] =
    !rowSelection || !setRowSelection
      ? []
      : [
          {
            accessorKey: 'checkbox',
            header: ({ table }) => (
              <Checkbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={() => table.toggleAllRowsSelected()}
                color="primary"
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                checked={row.getIsSelected()}
                onChange={() => row.toggleSelected()}
                color="primary"
              />
            ),
            size: 20,
            minSize: 20,
          },
        ];

  const formattedColumns: ColumnType<TData>[] = columns.map((column) => {
    switch (column.format) {
      case 'date':
        return {
          cell: ({ getValue }) =>
            dayjs(getValue<Date>()).isValid()
              ? dayjs(getValue<Date>()).format('DD/MM/YYYY')
              : getValue(),
          size: 200,
          ...column,
        };
      case 'datetime':
        return {
          cell: ({ getValue }) =>
            dayjs(getValue<Date>()).isValid()
              ? dayjs(getValue<Date>())
                  .utc()
                  .format('DD/MM/YYYY HH:mm')
              : getValue(),
          size: 200,
          ...column,
        };
      case 'datetime-tz':
        return {
          cell: ({ getValue }) =>
            dayjs(getValue<Date>()).isValid()
              ? dayjs(getValue<Date>()).format('DD/MM/YYYY HH:mm')
              : getValue(),
          size: 200,
          ...column,
        };
      case 'range-date':
        return {
          cell: ({ getValue }) => {
            const [startDate, endDate] = getValue<Date[]>();

            if (
              !dayjs(startDate).isValid() ||
              !dayjs(endDate).isValid() ||
              !startDate ||
              !endDate
            ) {
              return getValue();
            }

            return `${formatNumberDate(
              startDate,
              false,
              false,
            )} - ${formatNumberDate(endDate, false, false)}`;
          },
          size: 300,
          ...column,
        };
      case 'currency':
        return {
          cell: ({ getValue }) =>
            `Rp. ${formatThousandSeparator(getValue<number>())}`,
          ...column,
        };
      default:
        return column;
    }
  });

  const mergedColumns: ColumnType<TData>[] = [
    ...numberedColumns,
    ...checkboxColumn,
    ...formattedColumns,
  ];

  const table = useReactTable<TData>({
    data,
    columns: mergedColumns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: !!rowSelection && !!setRowSelection,
    state: {
      rowSelection,
      pagination,
    },
    onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    getRowId,
  });

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number,
  ) => {
    table.setPageIndex(page);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    table.setPageSize(Number(event.target.value));
  };

  const handleSelectedRows = useCallback(
    (selectedRows: TData[], selectedIds: string[]) => {
      if (selectedRows.length === 0 && selectedIds.length > 0) {
        setSelectedRows((prevSelected) =>
          prevSelected.filter((prev) => selectedIds.includes(prev.id)),
        );
        return;
      }

      if (selectedRows.length > selectedIds.length) {
        setSelectedRows(
          selectedRows.filter((row) => selectedIds.includes(row.id)),
        );
        return;
      }

      setSelectedRows(selectedRows);
    },
    [],
  );

  const rowsSelected = table
    .getSelectedRowModel()
    .flatRows.map((row) => row.original);
  const rowsSelectedIds = Object.keys(rowSelection);

  useEffect(() => {
    handleSelectedRows(rowsSelected, rowsSelectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected.length, rowsSelectedIds.length, handleSelectedRows]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(rowsSelectedIds, selectedRows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows.length, rowsSelectedIds.length, onSelectionChange]);

  return (
    <Paper className={classes.root} elevation={0}>
      <TableContainer
        component={Paper}
        elevation={0}
        className={classes.bodyRoot}
      >
        <Table className={classes.table}>
          <TableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className={classes.headerRow}>
                {headerGroup.headers.map((header) => (
                  <TableCell
                    key={header.id}
                    width={header.column.getSize()}
                    className={classes.cellHeader}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody className={classes.body}>
            {isLoading ? (
              <TableLoader
                columns={table.getAllColumns()}
                rowsLength={table.getState().pagination?.pageSize || 10}
              />
            ) : data.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <TableRow className={classes.row} key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      className={classes.cell}
                      key={cell.id}
                      width={cell.column.getSize()}
                      data-testid={`cell-${cell.column.id}`}
                    >
                      {!['undefined', 'function'].includes(
                        typeof cell.getValue(),
                      )
                        ? flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )
                        : flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow className={classes.row}>
                <TableCell
                  className={classes.cellNoData}
                  colSpan={table.getAllColumns().length}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && setPagination && total ? (
        <TablePagination
          component="div"
          count={total}
          page={table.getState().pagination.pageIndex}
          onPageChange={handleChangePage}
          rowsPerPage={table.getState().pagination.pageSize}
          ActionsComponent={TablePaginationActions}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Baris per halaman"
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          labelDisplayedRows={({ to, count }) => (
            <Text size="small">
              {to} dari {count}
            </Text>
          )}
          classes={{
            caption: classes.tableCaption,
          }}
        />
      ) : null}
    </Paper>
  );
}

export const ReactTable = memo(ReactTableComponent, (prevProps, nextProps) => {
  const prevSelection = Object.keys(prevProps.rowSelection || {});
  const nextSelection = Object.keys(nextProps.rowSelection || {});

  return (
    prevProps.data === nextProps.data &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.total === nextProps.total &&
    prevProps.pagination === nextProps.pagination &&
    prevProps.onSelectionChange === nextProps.onSelectionChange &&
    prevSelection.length === nextSelection.length
  );
}) as typeof ReactTableComponent;
