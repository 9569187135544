import { FormState } from '../../../NewProgramFormScene';

export function calculateBudget({
  programType,
  claimType,
  claimLimit,
  selectedTerms,
  selectedLoyaltyTerms,
  selectedFlashSaleTerms,
  selectedBundleProducts,
  selectedCustomers,
}: Pick<
  FormState,
  | 'programType'
  | 'claimType'
  | 'claimLimit'
  | 'selectedTerms'
  | 'selectedLoyaltyTerms'
  | 'selectedFlashSaleTerms'
  | 'selectedBundleProducts'
  | 'selectedCustomers'
>) {
  let totalPrice = 0;

  if (
    selectedTerms.length ||
    selectedLoyaltyTerms.length ||
    selectedFlashSaleTerms.size ||
    selectedBundleProducts.length
  ) {
    if (programType === 'LOYALTY') {
      // NOTE: for Loyalty, the terms are different for each customer so we only need to multiply the terms and sum them
      for (const { minimalPurchase, rewardQty } of selectedLoyaltyTerms) {
        totalPrice += rewardQty * minimalPurchase;
      }
    } else if (claimLimit) {
      let maxPrice = 0;
      let maxQty = 1; // NOTE: at the very least, the promo can be bought as 1 quantity

      if (programType === 'TRADE_PROMO') {
        // NOTE: for Trade Promo, we get the upperbound as in maximum discount the user can get and the minimum purchase quantity
        //       or maximum purchase quantity which is only possible when the last term has 0 reward value
        //
        //       e.g. terms are: minimalPurchase = 11, rewardQty = 1000, minimalPurchase = 21 = 1500
        //       the result is 21 * 1500 * claimLimit
        //
        //       e.g. terms are: minimalPurchase = 11, rewardQty = 1000, minimalPurchase = 21 = 0
        //       the result is 20 * 1000 * claimLimit
        for (const { minimalPurchase, rewardQty } of selectedTerms) {
          maxPrice = Math.max(rewardQty, maxPrice);
          maxQty = Math.max(
            maxQty,
            rewardQty === 0 ? minimalPurchase - 1 : minimalPurchase,
          );
        }
      } else if (programType === 'FLASH_SALE') {
        // NOTE: for Flash Sale, it is the same as Trade Promo. The only difference is in the selected terms data type
        for (const terms of Array.from(selectedFlashSaleTerms.values())) {
          for (const { minimalPurchase, rewardQty } of terms) {
            maxPrice = Math.max(rewardQty, maxPrice);
            maxQty = Math.max(
              maxQty,
              rewardQty === 0 ? minimalPurchase - 1 : minimalPurchase,
            );
          }
        }
      } else {
        // NOTE: for Bundle, we simply sum the discount * minimum purchase of each products in it
        for (const { minimalPurchase, rewardQty } of selectedBundleProducts) {
          // NOTE: use maxPrice to track total discount of the bundling, which will be multiplied by claimLimit
          maxPrice += rewardQty * minimalPurchase;
        }
      }

      // NOTE: this is where we multiply the resulting maxPrice and maxQty with the how many times this promo can be claimed
      totalPrice = Number(claimLimit) * maxPrice * maxQty;

      // NOTE: for Trade Promo, claimType also matters. Per Store means that each store (customer) can claim this program
      //       N (claimLimit) times. So the resulting totalPrice needs to be multiplied by the selected customer count
      totalPrice =
        programType === 'TRADE_PROMO' && claimType === 'PER_STORE'
          ? totalPrice * selectedCustomers.length
          : totalPrice;
    }
  }
  return totalPrice;
}
