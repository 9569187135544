import { ResponsivePie } from '@nivo/pie';
import React from 'react';

interface ChartDataType {
  label: string;
  id: string;
  value: number;
}

interface MarginPieChartTypes {
  top?: number;
  bottom?: number;
  right?: number;
  left?: number;
}

export interface PieChartProps {
  chartData: ChartDataType[];
  marginChart?: MarginPieChartTypes;
}

export function PieChart({ chartData, marginChart }: PieChartProps) {
  return (
    <ResponsivePie
      data={chartData}
      margin={marginChart}
      padAngle={1}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]],
      }}
      enableArcLinkLabels={false}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLabelsRadiusOffset={0.55}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [['darker', 2]],
      }}
      legends={[
        {
          anchor: 'right',
          direction: 'column',
          justify: false,
          translateX: 4,
          translateY: 7,
          itemsSpacing: 0,
          itemWidth: 133,
          itemHeight: 53,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 33,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000',
              },
            },
          ],
        },
      ]}
    />
  );
}

export default PieChart;
