import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType, useMemo, useState } from 'react';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { CustomField } from 'scenes/program/components';
import { LoyaltyTermsModal, TermsModal } from 'scenes/program/modals';

function TermSelectionComponent({ openToast }: ToastContextProps) {
  const [isTermsVisible, setIsTermsVisible] = useState(false);
  const [isLoyaltyTermsVisible, setIsLoyaltyTermsVisible] = useState(false);

  const { values, onValuesChange } = useFormContext();
  const {
    programType,
    selectedTerms,
    rewardType,
    giftType,
    selectedCustomers,
    selectedLoyaltyTerms,
  } = values;

  const selectedTermText = useMemo(() => {
    if (programType === 'LOYALTY') {
      return selectedLoyaltyTerms.length > 0
        ? `${selectedLoyaltyTerms.length} Syarat Terpilih`
        : null;
    }
    return selectedTerms.length > 0
      ? `${selectedTerms.length} Syarat Terpilih`
      : null;
  }, [programType, selectedTerms, selectedLoyaltyTerms]);

  return (
    <>
      <CustomField
        headerText="Tentukan Syarat"
        bodyText="Tentukan syarat-syarat yang berlaku untuk mendapatkan program ini."
        buttonText="Tentukan Syarat"
        selectedText={selectedTermText}
        onPress={() => {
          if (programType === 'TRADE_PROMO') {
            setIsTermsVisible(true);
          } else if (programType === 'LOYALTY') {
            if (selectedCustomers.length === 0) {
              openToast &&
                openToast('fail', 'Pilih pelanggan terlebih dahulu.');
            } else if (rewardType === 'CASHBACK' || giftType) {
              setIsLoyaltyTermsVisible(true);
            }
          }
        }}
      />

      <TermsModal
        onClose={() => setIsTermsVisible(false)}
        isVisible={isTermsVisible}
        selectedTerms={selectedTerms}
        onChangeSelected={(selectedArray) =>
          onValuesChange({
            selectedTerms: selectedArray,
          })
        }
        rewardType={rewardType}
      />

      {isLoyaltyTermsVisible &&
        selectedCustomers.length > 0 &&
        rewardType &&
        rewardType !== 'DISCOUNT' && (
          <LoyaltyTermsModal
            onClose={() => setIsLoyaltyTermsVisible(false)}
            loyaltyType={rewardType === 'PRESENT' ? giftType : rewardType}
            selectedTerms={selectedLoyaltyTerms}
            selectedCustomers={selectedCustomers}
            onChangeSelected={(selectedArray) =>
              onValuesChange({
                selectedLoyaltyTerms: selectedArray,
              })
            }
          />
        )}
    </>
  );
}

export const TermSelection = withToast(TermSelectionComponent) as ComponentType;
