import React, { useCallback, useMemo, useState } from 'react';
import { Mutation, MutationFn, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import { FormPage, Query } from '../../../components';
import { BUTTON, GRAY70 } from '../../../constants/colors';
import { Card, Dropzone, Text, TextField } from '../../../core-ui';
import ButtonField from '../../../core-ui/ButtonField';
import {
  CREATE_HEADER_HIGHLIGHT,
  CreateHeaderVars,
  UPDATE_HEADER_HIGHLIGHT,
  UPLOAD_PHOTO,
  UpdateHeaderVars,
  UploadPhotoMutationFn,
  UploadPhotoVariables,
} from '../../../graphql/mutations';
import { AccessProps } from '../../../graphql/queries';
import {
  GET_HEADER_HIGHLIGHT,
  GetHeaderHighlight,
  HeaderHighlight,
} from '../../../graphql/queries/headerQuery';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import NotFoundPage from '../../NotFoundPage';
import { SegmentSelectionModal } from './SegmentSelectionModal';

type UploadPhotoProps = { uploadPhoto: UploadPhotoMutationFn };

type HeaderHighlightFormSceneProps = Pick<
  RouteComponentProps<{ id: string }>,
  'history' | 'match'
> &
  ToastContextProps &
  UploadPhotoProps &
  AccessProps;

type HeaderHighlightSchema = Omit<
  HeaderHighlight,
  'active' | 'segments' | 'subSegments'
> & {
  segments: string[];
  subSegments: string[];
};

function HeaderHighlightFormSceneComponent({
  history,
  match,
  openToast,
  uploadPhoto,
  access,
}: HeaderHighlightFormSceneProps) {
  const [initialValue, setInitialValue] = useState<
    Omit<HeaderHighlightSchema, 'id'>
  >({
    title: '',
    description: '',
    order: 0,
    headerImage: '',
    segments: [],
    subSegments: [],
  });
  const [formState, setFormState] = useState<Omit<HeaderHighlightSchema, 'id'>>(
    initialValue,
  );
  const [isDirty, setIsDirty] = useState(false);
  const [photoPreview, setPhotoPreview] = useState('');
  const [photoFile, setPhotoFile] = useState<File>();
  const [isSegmentationModalVisible, setIsSegmentationModalVisible] = useState(
    false,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const isEdit = !!match.params.id && match.params.id !== 'new';
  const headerId = match.params.id;

  const isValid = useMemo(() => {
    return (
      formState.title !== '' &&
      formState.description !== '' &&
      !!photoPreview &&
      formState.segments.length !== 0
    );
  }, [
    formState.title,
    formState.description,
    photoPreview,
    formState.segments,
  ]);

  const onSubmit = (
    createHeader: MutationFn<null, CreateHeaderVars>,
    updateHeader: MutationFn<null, UpdateHeaderVars>,
  ) => async () => {
    setIsSubmitting(true);

    try {
      const photoURL = photoFile
        ? await uploadPhoto({
            variables: {
              file: photoFile,
              uploadType: 'HEADER',
            },
          })
        : {
            data: {
              uploadPhoto: {
                url: formState.headerImage || '',
              },
            },
          };

      const normalizedData: CreateHeaderVars['data'] = {
        ...formState,
        headerImage: photoURL?.data ? photoURL.data.uploadPhoto.url : '',
        segments: {
          connect: formState.segments.map((segment) => ({
            id: segment,
          })),
        },
        subSegments: {
          connect: formState.subSegments.map((subSegment) => ({
            id: subSegment,
          })),
        },
      };

      if (isEdit) {
        const removedSegment = initialValue.segments.filter(
          (segment) => !formState.segments.includes(segment),
        );
        const removedSubSegment = initialValue.subSegments.filter(
          (subSegment) => !formState.subSegments.includes(subSegment),
        );

        const segments = formState.segments.filter(
          (segment) => !removedSegment.includes(segment),
        );
        const subSegments = formState.subSegments.filter(
          (subSegment) => !removedSubSegment.includes(subSegment),
        );

        await updateHeader({
          variables: {
            id: headerId,
            data: {
              ...normalizedData,
              segments: {
                connect: segments.map((id) => ({ id })),
                disconnect: removedSegment.map((id) => ({ id })),
              },
              subSegments: {
                connect: subSegments.map((id) => ({ id })),
                disconnect: removedSubSegment.map((id) => ({ id })),
              },
            },
          },
        });

        return;
      }

      await createHeader({
        variables: {
          data: normalizedData,
        },
      });
    } catch (error) {
      openToast('fail', `Gagal membuat Header Highlight: ${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onDiscard = () => {
    setFormState(initialValue);
    setIsDirty(false);
  };

  const onSegmentationSelect = useCallback(
    (segments: string[], subSegments: string[]) => {
      setFormState((prevState) => ({
        ...prevState,
        segments,
        subSegments,
      }));
      setIsDirty(true);
    },
    [],
  );

  if ((!isEdit && !access.create) || (isEdit && !access.update)) {
    return <NotFoundPage />;
  }

  return (
    <Query<GetHeaderHighlight>
      query={GET_HEADER_HIGHLIGHT}
      variables={{ id: headerId }}
      skip={!headerId || !isEdit}
      fetchPolicy="network-only"
      onCompleted={({ header }) => {
        if (!isEdit) return;

        const newInitialValue: Omit<HeaderHighlightSchema, 'id'> = {
          title: header.title,
          description: header.description,
          order: header.order,
          headerImage: header.headerImage,
          segments: header.segments.map((segment) => segment.id),
          subSegments: header.subSegments.map((subSegment) => subSegment.id),
        };

        setInitialValue(newInitialValue);
        setPhotoPreview(header.headerImage);
        setFormState(newInitialValue);
      }}
    >
      {() => (
        <Mutation<null, CreateHeaderVars>
          mutation={CREATE_HEADER_HIGHLIGHT}
          onCompleted={() => {
            openToast('success', 'Header Highlight berhasil dibuat!');
            history.push('/header-highlights');
          }}
        >
          {(createHeader) => (
            <Mutation<null, UpdateHeaderVars>
              mutation={UPDATE_HEADER_HIGHLIGHT}
              onCompleted={() => {
                openToast('success', 'Header Highlight berhasil diubah!');
                history.push('/header-highlights');
              }}
            >
              {(updateHeader) => (
                <FormPage
                  showBackButton
                  isValid={isValid}
                  isDirty={isDirty}
                  handleSubmit={onSubmit(createHeader, updateHeader)}
                  handleDiscard={onDiscard}
                  handleGoBack={history.goBack}
                  submitTitle={isEdit ? 'Simpan' : 'Tambah'}
                  isSubmitLoading={isSubmitting}
                >
                  <Card
                    title={
                      isEdit
                        ? 'Ubah Informasi Header Highlight'
                        : 'Tambah Header Highlight'
                    }
                  >
                    <TextField
                      stretch
                      labelHorizontal
                      label="Nama Header"
                      placeholder="Nama Header"
                      value={formState.title}
                      onChangeText={(text) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          title: text,
                        }));
                        setIsDirty(true);
                      }}
                      data-testid="title-input"
                    />
                    <TextField
                      stretch
                      labelHorizontal
                      multiline
                      label="Deskripsi"
                      placeholder="Deskripsi"
                      value={formState.description}
                      onChangeText={(text) => {
                        setFormState((prevState) => ({
                          ...prevState,
                          description: text,
                        }));
                        setIsDirty(true);
                      }}
                      data-testid="description-input"
                    />
                    <View style={styles.imageField}>
                      <Text
                        size="small"
                        color={GRAY70}
                        style={styles.imageFieldTitle}
                      >
                        Upload Gambar Header
                      </Text>
                      <View style={styles.dropzone}>
                        <Dropzone
                          multiple={false}
                          getPreview={(previews) => {
                            setPhotoPreview(previews[0].preview);
                            setPhotoFile(previews[0].file);
                            setIsDirty(true);
                          }}
                          source={
                            photoPreview ? { uri: photoPreview } : undefined
                          }
                          inputProps={{
                            'aria-label': 'Unggah Gambar Header',
                          }}
                        />
                        <Text
                          size="small"
                          color={GRAY70}
                          style={styles.messagePhoto}
                        >
                          Ratio 12:5 (Contoh 600px x 250px)
                        </Text>
                      </View>
                    </View>
                    <ButtonField
                      buttonText="Pilih Segmentasi"
                      label="Segmentasi"
                      labelHorizontal
                      inverted
                      onPress={() => setIsSegmentationModalVisible(true)}
                      trailingText={`${formState.subSegments.length} sub segmentasi terpilih`}
                      trailingTextStyle={{
                        color: BUTTON.PRIMARY,
                      }}
                    />
                  </Card>
                  <SegmentSelectionModal
                    isVisible={isSegmentationModalVisible}
                    onClose={() => {
                      setIsSegmentationModalVisible(false);
                    }}
                    onSelect={onSegmentationSelect}
                    currentSegments={formState.segments}
                    currentSubSegments={formState.subSegments}
                  />
                </FormPage>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </Query>
  );
}

const styles = StyleSheet.create({
  imageField: {
    flexDirection: 'row',
  },
  imageFieldTitle: {
    flex: 1,
    paddingVertical: 8,
  },
  dropzone: {
    flex: 2,
    flexDirection: 'row',
    marginBottom: 32,
  },
  messagePhoto: {
    marginLeft: 12,
  },
});

export const HeaderHighlightFormScene = compose(
  withToast,
  graphql<RouteProps, UploadPhotoProps, UploadPhotoVariables, UploadPhotoProps>(
    UPLOAD_PHOTO,
    {
      name: 'uploadPhoto',
    },
  ),
)(HeaderHighlightFormSceneComponent);
