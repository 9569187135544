import { Box } from '@material-ui/core';
import { BLUE_SOFT, GRAY } from 'constants/colors';
import { Text } from 'core-ui';
import React from 'react';

export type LinearProgressbarProps = {
  value: number;
  color?: string;
  withText?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export function LinearProgressbar({
  value,
  color = BLUE_SOFT,
  withText,
  size = 'medium',
}: LinearProgressbarProps) {
  const sizeMaps = {
    small: '14px',
    medium: '24px',
    large: '30px',
  };

  return (
    <Box
      bgcolor={GRAY}
      borderRadius={9999}
      height={sizeMaps[size]}
      overflow="hidden"
      display="flex"
      justifyContent="center"
      flexDirection="column"
    >
      <Box
        bgcolor={color}
        borderRadius={9999}
        height={sizeMaps[size]}
        width={`${value}%`}
      />
      <Text
        size="xsmall"
        style={{
          display: withText ? 'flex' : 'none',
          position: 'absolute',
          alignSelf: 'center',
        }}
      >
        {`${value}%`}
      </Text>
    </Box>
  );
}
