import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { RouteComponentProps } from 'react-router-dom';

import { NotFoundPage } from '../..';

import { Separator } from '../../../core-ui';

import { AccessProps } from '../../../graphql/queries';
import { Content } from './Content';
import { Header } from './Header';

export function CouponListScene({
  access,
  history,
}: AccessProps & RouteComponentProps) {
  const [searchKey, setSearchKey] = useState('');

  if (!access.read) {
    return <NotFoundPage />;
  }

  return (
    <View style={styles.root}>
      <Header access={access} history={history} onSearch={setSearchKey} />
      <Separator />
      <Content access={access} searchKey={searchKey} />
    </View>
  );
}

const styles = StyleSheet.create({
  root: { flex: 1, paddingVertical: 40, paddingHorizontal: 80 },
});
