import { CurrencyField, Dropdown, Option, TextField } from 'core-ui';
import { GiftType } from 'graphql/queries';
import React from 'react';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { GIFT_OPTS } from 'scenes/program/helpers/programOptions';

export function LoyaltyGift() {
  const { values, onValuesChange } = useFormContext();

  const { giftType, giftName, giftPrice } = values;

  // #region Fields update handler
  const onGiftTypeChange = (option: Nullable<Option<string>>) => {
    if (option) {
      onValuesChange({
        giftType: option.value as GiftType,
      });
    }
  };

  return (
    <>
      <Dropdown
        labelHorizontal
        label="Jenis Hadiah"
        placeholder="Jenis Hadiah"
        selectedOption={giftType}
        options={GIFT_OPTS}
        onChange={onGiftTypeChange}
        style={{ zIndex: 7 }}
      />
      {giftType === 'ANOTHER_PRODUCT' && (
        <>
          <TextField
            stretch
            labelHorizontal
            label="Produk Hadiah"
            placeholder="Produk Hadiah"
            value={giftName}
            onChangeText={(value: string) =>
              onValuesChange({ giftName: value })
            }
          />
          <CurrencyField
            stretch
            labelHorizontal
            label="Harga Hadiah"
            placeholder="Harga Hadiah"
            currencyValue={giftPrice}
            onCurrencyChange={(value) => onValuesChange({ giftPrice: value })}
          />
        </>
      )}
    </>
  );
}
