import React, { PropsWithChildren, createContext, useContext } from 'react';

export const SearchContext = createContext<string>('');

export function SearchProvider({
  value,
  children,
}: PropsWithChildren<{ value: string }>) {
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}

export function useSearch() {
  const context = useContext(SearchContext);

  if (context === undefined) {
    throw new Error('useSearch must be used within SearchProvider');
  }

  return context;
}
