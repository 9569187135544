import { Box } from '@material-ui/core';
import { DARK_GRAY, WHITE } from 'constants/colors';
import { ORDER_SYNC } from 'graphql/mutations';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Mutation } from 'react-apollo';
import { RouteComponentProps } from 'react-router-dom';
import { SearchField } from '../../../components';
import { Button, Modal, Tab, Tabs, Text } from '../../../core-ui';
import { AccessProps, PageAccess } from '../../../graphql/queries';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import { BottlePriceSettingModal } from './components';
import { SearchProvider } from './contexts';
import {
  CanceledOrderList,
  DeliveredOrderList,
  InProgressOrderList,
  NewOrderList,
} from './tabs';
import { GallonGuaranteeList } from './tabs/GallonGuaranteeList';

export type OrderSceneProps = AccessProps & {
  bottleAccess: PageAccess;
} & RouteComponentProps &
  ToastContextProps;

const TAB_HASH_URI = [
  '#permintaan-baru',
  '#pesanan-proses',
  '#pesanan-sampai',
  '#pesanan-dibatalkan',
  '#jaminan-botol',
];

function OrderSceneComponent({
  bottleAccess,
  access,
  location,
  history,
  openToast,
}: OrderSceneProps) {
  const [search, setSearch] = useState('');
  const [isUpdateConfirmVisible, setIsUpdateConfirmVisible] = useState(false);
  const [
    isSettingBottlePriceVisible,
    setIsSettingBottlePriceVisible,
  ] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const onSyncError = useCallback(() => {
    openToast('fail', 'Gagal memperbarui data pemesanan.');
  }, [openToast]);

  const renderSyncModal = useCallback(
    (refetchFn: () => void) => {
      return (
        <>
          <Mutation<any>
            mutation={ORDER_SYNC}
            onCompleted={() => {
              openToast &&
                openToast('success', 'Pemesanan berhasil diperbarui.');
              refetchFn();
              setIsUpdateConfirmVisible(false);
            }}
            onError={onSyncError}
          >
            {(updateDatabase, { loading }) => (
              <Modal
                isVisible={isUpdateConfirmVisible}
                title="Perbarui Data Pemesanan"
                submitLoading={loading}
                description={
                  <Text size="small" color={DARK_GRAY}>
                    Proses ini akan membutuhkan waktu beberapa saat. Apakah anda
                    yakin ingin meng-update/memperbarui data?{' '}
                  </Text>
                }
                children={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      gap: 4,
                    }}
                  >
                    <Button
                      style={{ marginRight: 15 }}
                      inverted
                      color="secondary"
                      text="Batal"
                      onPress={() => setIsUpdateConfirmVisible(false)}
                      loadingColor={WHITE}
                    />
                    <Button
                      color="primary"
                      text="Ya"
                      onPress={() => updateDatabase()}
                      loadingColor={WHITE}
                      isLoading={loading}
                    />
                  </Box>
                }
                onClose={() => setIsUpdateConfirmVisible(false)}
              />
            )}
          </Mutation>
        </>
      );
    },
    [onSyncError, openToast, isUpdateConfirmVisible],
  );

  const tabs = useMemo(() => {
    const tabs = [];

    if (access.read) {
      tabs.push(
        <Tab label="Pesanan Baru" key="new-order">
          <NewOrderList access={access} renderSyncModal={renderSyncModal} />
        </Tab>,
      );
      tabs.push(
        <Tab label="Pesanan Proses" key="processed-order">
          <InProgressOrderList
            access={access}
            renderSyncModal={renderSyncModal}
          />
        </Tab>,
      );
      tabs.push(
        <Tab label="Pesanan Sampai" key="delivered-order">
          <DeliveredOrderList
            access={access}
            renderSyncModal={renderSyncModal}
          />
        </Tab>,
      );
      tabs.push(
        <Tab label="Pesanan Dibatalkan" key="canceled-order">
          <CanceledOrderList
            access={access}
            renderSyncModal={renderSyncModal}
          />
        </Tab>,
      );
    }

    if (bottleAccess.read) {
      tabs.push(
        <Tab label="Jaminan Botol" key="bottle-guarantee">
          <GallonGuaranteeList access={access} />
        </Tab>,
      );
    }

    return tabs;
  }, [access, bottleAccess.read, renderSyncModal]);

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onTabChange = (_: ChangeEvent<{}>, newIndex: number) => {
    if (selectedTab !== newIndex) {
      setSearch('');
      setSelectedTab(newIndex);
      history.push(`${location.pathname}${TAB_HASH_URI[newIndex]}`);
    }
  };

  const updateSelectedTab = useCallback(() => {
    let newSelectedTab = selectedTab;
    switch (location.hash) {
      case TAB_HASH_URI[1]: {
        newSelectedTab = access.read ? 1 : 0;
        break;
      }
      case TAB_HASH_URI[2]: {
        newSelectedTab = access.read ? 2 : 0;
        break;
      }
      case TAB_HASH_URI[3]: {
        newSelectedTab = access.read ? 3 : 0;
        break;
      }
      case TAB_HASH_URI[4]: {
        newSelectedTab = bottleAccess.read && access.read ? 4 : 0;
        break;
      }
      default: {
        newSelectedTab = 0;
      }
    }

    if (selectedTab !== newSelectedTab) {
      setSelectedTab(newSelectedTab);
    }
  }, [access.read, bottleAccess.read, location.hash, selectedTab]);

  useEffect(() => {
    updateSelectedTab();
  }, [updateSelectedTab]);

  return (
    <SearchProvider value={search}>
      <Box style={{ flex: 1, padding: '40px 80px' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb="20px"
        >
          <Text
            size="xlarge"
            style={{
              flex: 1,
            }}
          >
            Pemesanan
          </Text>
          <SearchField value={search} onChangeText={onSearch} />
          {location.hash !== TAB_HASH_URI[4] ? (
            <Button onPress={() => setIsUpdateConfirmVisible(true)}>
              Update Data
            </Button>
          ) : null}
          {bottleAccess.update && (
            <Button
              onPress={() => setIsSettingBottlePriceVisible(true)}
              text="Pengaturan Harga Botol"
              icon="settings"
              style={{
                marginLeft: 16,
                display: location.hash === TAB_HASH_URI[4] ? 'flex' : 'none',
              }}
            />
          )}
          <BottlePriceSettingModal
            isVisible={isSettingBottlePriceVisible}
            onClose={() => setIsSettingBottlePriceVisible(false)}
          />
        </Box>
        <Tabs selectedIndex={selectedTab} onChange={onTabChange}>
          {tabs}
        </Tabs>
      </Box>
    </SearchProvider>
  );
}

export const OrderScene = withToast(OrderSceneComponent);
