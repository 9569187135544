import { TableCell, TableRow, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { Column } from '@tanstack/react-table';
import { GRAY, WHITE } from 'constants/colors';
import React from 'react';

export type TableLoaderProps<TData> = {
  columns: Column<TData>[];
  rowsLength?: number;
};

const useStyles = makeStyles({
  row: {
    height: 60,
    backgroundColor: WHITE,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      borderBottom: `1px solid ${GRAY}`,
    },
  },
  cell: {
    display: 'inline-block',
    fontFamily: 'Rubik-Regular',
    fontSize: 15,
    letterSpacing: 1.5,
    textTransform: 'uppercase',
    padding: 10,
    borderBottom: 'none',
  },
});

export function TableLoader<TData>({
  columns,
  rowsLength = 10,
}: TableLoaderProps<TData>) {
  const classes = useStyles();
  const rows = Array.from({ length: rowsLength }).map(() => ({
    id: crypto.randomUUID(),
  }));

  return (
    <>
      {rows.map(({ id }) => (
        <TableRow style={{ height: 60 }} key={id} className={classes.row}>
          {columns.map(({ id, getSize }) => (
            <TableCell key={id} className={classes.cell} width={getSize()}>
              <Skeleton variant="text" animation="wave" />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
}
