import { ApolloError } from 'apollo-client';
import { BLACK, DARK_GRAY } from 'constants/colors';
import { Modal, Text } from 'core-ui';
import { ACCEPT_ORDER, AcceptOrderMutationResult } from 'graphql/mutations';
import { Order } from 'graphql/queries';
import { graphqlErrorRemover } from 'helpers';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType } from 'react';
import { Mutation } from 'react-apollo';

type ApproveModalOwnProps = {
  refetchFn: () => void;
  data: Order | null;
  onClose: () => void;
  isVisible: boolean;
};
export type ApproveModalProps = ApproveModalOwnProps & ToastContextProps;

function ApproveModalComponent({
  refetchFn,
  data,
  onClose,
  openToast,
  isVisible,
}: ApproveModalProps) {
  return (
    <Mutation
      mutation={ACCEPT_ORDER}
      onCompleted={(result: AcceptOrderMutationResult) => {
        if (result && result.acceptOrder && result.acceptOrder.id) {
          onClose();
          refetchFn();

          openToast && openToast('success', 'Order telah diterima.');
        }
      }}
      onError={(error: ApolloError) => {
        openToast('fail', graphqlErrorRemover(String(error)));
      }}
    >
      {(acceptOrder, { loading }) => (
        <Modal
          hideHeaderClose
          title="Terima Pesanan"
          closeButtonText="Tidak"
          buttonText="Terima"
          submitLoading={loading}
          onSubmit={() => {
            acceptOrder({ variables: { id: data && data.id } });
          }}
          isVisible={isVisible}
          onClose={onClose}
        >
          <Text size="small" color={DARK_GRAY} style={{ marginBottom: 40 }}>
            Apakah Anda yakin ingin menerima pesanan dari{' '}
            <Text size="small" weight="bold" color={BLACK}>
              {data && data.user.name.toUpperCase()}
            </Text>{' '}
            ?
          </Text>
        </Modal>
      )}
    </Mutation>
  );
}

export const ApproveModal = withToast(ApproveModalComponent) as ComponentType<
  ApproveModalOwnProps
>;
