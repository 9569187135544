import { Box, Tab, Tabs, withStyles } from '@material-ui/core';
import { TABS } from 'constants/colors';
import { FONT_SIZE } from 'constants/textPresets';
import { Text } from 'core-ui';
import { AccessProps } from 'graphql/queries';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { CustomerData, SalesTrend, TeamPerformance } from './tabs';

const MTab = withStyles({
  root: {
    fontFamily: 'Rubik-Regular',
    fontSize: FONT_SIZE.small,
    fontWeight: 600,
    textTransform: 'none',
  },
})(Tab);

const MTabs = withStyles({
  root: {
    borderBottom: `1px solid ${TABS.SEPARATOR}`,
  },
})(Tabs);

export function BusinessTrendScene({ access }: AccessProps) {
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = useMemo(() => {
    return !access.read
      ? []
      : [<SalesTrend />, <CustomerData />, <TeamPerformance />];
  }, [access.read]);

  const onTabChange = (_: ChangeEvent<{}>, value: number) => {
    setSelectedTab(value);
  };

  return (
    <Box flex={1} padding="40px 80px" height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
      >
        <Text
          size="xlarge"
          style={{
            flex: 1,
          }}
        >
          Trend Bisnis
        </Text>
      </Box>
      <MTabs
        value={selectedTab}
        indicatorColor="primary"
        onChange={onTabChange}
      >
        <MTab label="Trend Penjualan" key="sales-trend" />
        <MTab label="Data Pelanggan" key="customer-data" />
        <MTab label="Performance Team" key="performance-team" />
      </MTabs>
      {tabs[selectedTab]}
    </Box>
  );
}
