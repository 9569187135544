import { User } from 'graphql/queries';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType, useCallback, useState } from 'react';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { CustomField } from 'scenes/program/components';
import { CustomerSelectionModal } from 'scenes/program/modals';

function CustomerSelectionComponent({ openToast }: ToastContextProps) {
  const [isCustomerSelectionVisible, setIsCustomerSelectionVisible] = useState(
    false,
  );

  const { values, onValuesChange } = useFormContext();
  const {
    selectedCustomers,
    selectedSegments,
    selectedSubSegments,
    selectedDepots,
    selectedLoyaltyTerms,
    programType,
  } = values;


  const onCustomerChange = useCallback(
    (selectedArray: User[]) => {
      let newSelectedLoyaltyTerms = selectedLoyaltyTerms;
      if (programType === 'LOYALTY') {
        // Reset the Terms Array
        // Loyalty Terms are based on the selectedCustomers
        newSelectedLoyaltyTerms = selectedLoyaltyTerms.filter(
          (loyaltyTerm) =>
            selectedArray.filter(
              (customer) => customer.id === loyaltyTerm.customer.id,
            ).length !== 0,
        );
      }

      const selectedCustomers = selectedArray.map((customer) => ({
        user: customer,
      }));

      onValuesChange({
        selectedCustomers,
        selectedLoyaltyTerms: newSelectedLoyaltyTerms,
      });
    },
    [
      onValuesChange,
      selectedLoyaltyTerms,
      programType,
    ],
  );

  return (
    <>
      <CustomField
        headerText="Pilih Pelanggan"
        bodyText="Tentukan pelanggan yang dapat menggunakan jenis program ini."
        buttonText="Pilih Pelanggan"
        selectedText={
          selectedCustomers.length > 0
            ? `${selectedCustomers.length} pelanggan terpilih`
            : null
        }
        onPress={() => {
          if (selectedSegments.length < 1 && selectedSubSegments.length < 1) {
            openToast('fail', 'Pilih segmentasi dan sub segmentasi dahulu.');
          } else if (selectedDepots.length < 1) {
            openToast('fail', 'Pilih depo dahulu.');
          } else {
            setIsCustomerSelectionVisible(true);
          }
        }}
      />

      <CustomerSelectionModal
        onClose={() => setIsCustomerSelectionVisible(false)}
        selectedDepots={selectedDepots}
        selectedSubsegments={selectedSubSegments.map((sub) => {
          return sub.subSegment.id;
        })}
        selectedCustomers={selectedCustomers.map((customer) => {
          return customer.user;
        })}
        onChangeSelected={onCustomerChange}
        isVisible={
          isCustomerSelectionVisible &&
          selectedSegments.length > 0 &&
          selectedSubSegments.length > 0
        }
      />
    </>
  );
}

export const CustomerSelection = withToast(
  CustomerSelectionComponent,
) as ComponentType;
