import { StyleSheet, View } from 'react-native';
import {
  Button,
  Icon,
  IncrementField,
  Modal,
  Separator,
  Text,
  TextField,
} from '../../../core-ui';
import React, { FC, useEffect, useState } from 'react';
import {
  DARKER_GRAY,
  DARK_GRAY,
  GRAY,
  PRIMARY,
} from '../../../constants/colors';
import { SearchField } from '../components';
import { Query } from '../../../components';
import {
  CouponProduct,
  Product,
  GET_PRODUCT_LIST,
  GET_MODAL_SELECTED_STATE,
  GetProductListResult,
  GetProductListVariables,
  UPDATE_MODAL_SELECTED,
} from '../../../graphql/queries';
import Table from '../components/Table';
import { fetchMoreItems } from '../../../helpers';
import InputField from '../../coupon/components/InputField';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { ModalState } from '../../../graphql/localState';

export type OtherProduct = {
  productName: string;
  qty: number;
};
type SelectedStateProps = {
  selectedStateQuery: DataValue<ModalState<CouponProduct>>;
};
type UpdateSelectedVariables = { selectedArray: Array<CouponProduct> };
type UpdateSelectedData = {
  updateMultiTable: MutationFunc<null, UpdateSelectedVariables>;
};
type OwnProps = {
  isVisible: boolean;
  onClose: () => void;
  onSelect: ({
    products,
    otherProducts,
  }: {
    products: {
      product: CouponProduct;
      quantity: number
    }[];
    otherProducts: OtherProduct[];
  }) => void;
  selectedProducts: CouponProduct[];
  selectedOtherProducts: OtherProduct[];
};

export type ProductSelectionModalProps = OwnProps &
  SelectedStateProps &
  UpdateSelectedData;

function ProductSelectionModalComponent({
  isVisible,
  onClose,
  onSelect,
  updateMultiTable,
  selectedProducts,
  selectedStateQuery,
  selectedOtherProducts,
}: ProductSelectionModalProps) {
  const [searchKey, setSearchKey] = useState('');
  const [resetPage, setResetPage] = useState(false);
  const [otherProducts, setOtherProducts] = useState<OtherProduct[]>([]);
  const [selectedItem, setSelectedItem] = useState(selectedProducts)

  const onSearch = (search: string) => {
    setResetPage(true);
    setSearchKey(search);
  };

  useEffect(() => {
    updateMultiTable({
      variables: {
        selectedArray:
          selectedProducts.map(({ product, quantity }) => {
            return {
              id: product.id,
              product: product,
              quantity: quantity,
            };
          }) || [],
      },
    });

    setOtherProducts(selectedOtherProducts);

    return () => {
      // Remove selected state
      updateMultiTable({
        variables: {
          selectedArray: [],
        },
      });
    };
  }, []);

  useEffect(()=> {
    if(selectedProducts.length === 0){
      updateMultiTable({
        variables: {
          selectedArray: [],
        },
      });
    }
    else{
      updateMultiTable({
        variables: {
          selectedArray: selectedProducts.map((val) => {
            return {
              id: val.product.id,
              product: val.product,
              quantity: val.quantity,
            };
          }) || [],
        },
      });
    }
  }, [selectedProducts, updateMultiTable])

  const onChangeOtherProductValue = ({
    index,
    field,
    value,
  }: {
    index: number;
    field: 'qty' | 'productName';
    value: string | number;
  }) => {
    setOtherProducts((products) => {
      return products.map((product, productIndex) => {
        if (productIndex === index) {
          return {
            ...product,
            [field]: field === 'qty' ? Number(value) : value,
          };
        }

        return product;
      });
    });
  };

  const removeProduct = (index: number) => {
    setOtherProducts((products) => {
      return products.filter((_, productIndex) => productIndex !== index);
    });
  };

  const updateSelectedProducts = (product: Product, qty: number, id: string)=> {
    if(selectedItem.length === 0){
      setSelectedItem([{id: id, product: product, quantity: qty}])
    }else{
      const selected = selectedItem.find(val => val.id === id)
      if(selected){
        selected.quantity = qty
      }else{
        setSelectedItem([...selectedItem, {product: product, id: id, quantity: qty}])
      }
    }
  }

  const findSelectedProduct = (id: string)=> {
    const selected = selectedItem.find(val => val.id === id)
    return selected ? selected.quantity : 1
  }
  
  const onSubmit = ()=> {
    const selected = selectedStateQuery?.modalState?.selectedArray
    const newSelectedProduct = selected?.map(val => {
      const prod = selectedItem.find(res => res.id === val.id)
      if(prod){
        return {
          id: val.id,
          product: val,
          quantity: prod.quantity
        }
      }
      return {
        id: val.id,
        product: val,
        quantity: 1
      }
    })

    if(!selected){
      onSelect({
        products: [],
        otherProducts
      })
      onClose()
      return
    }
    onSelect({
      products: newSelectedProduct || [],
      otherProducts
    })
    onClose();
  }

  return (
    <Modal
      maxHeight
      maxWidth="xl"
      isVisible={isVisible}
      onClose={onClose}
      title="Pilih Produk"
      buttonText="Pilih"
      onSubmit={onSubmit}
    >
      <View style={styles.modalWrapper}>
        <Text color={DARK_GRAY} style={styles.modalTextHeader}>
          Tentukan produk yang dapat dibeli dengan menggunakan jenis program ini
        </Text>
        <SearchField
          placeholder="Cari produk..."
          value={searchKey}
          onChangeText={onSearch}
        />
        <Separator style={styles.separator} />
        <Query<GetProductListResult, GetProductListVariables>
          query={GET_PRODUCT_LIST}
          variables={{
            where: { searchKeyword: searchKey.toLowerCase() },
            first: 20,
            skip: 0,
          }}
          keyData="products"
          fetchPolicy="network-only"
          notifyOnNetworkStatusChange
        >
          {({ data, loading, fetchMore }) =>
            data && (
              <Table
                resetPage={resetPage}
                setResetPage={(isReset) => setResetPage(isReset)}
                isLoading={loading}
                showCheckboxes
                rowPerPage={20}
                searchKey={searchKey}
                data={data.products}
                dataCount={data.count}
                structure={{
                  title: {
                    headerTitle: 'Nama Produk',
                  },
                  qty: {
                    headerTitle: 'Qty',
                    render: (data, _, selected) => {
                      const defaultValue = findSelectedProduct(data.id) ?? 1;
                      return (
                        <InputField
                          initialValue={defaultValue || 0}
                          selected={selected}
                          type="increment"
                          onUpdate={(val)=> {
                            updateSelectedProducts((data as Product), val, data.id)
                          }}
                        />
                      );
                    },
                  },
                }}
                loadMore={({ first, skip, searchInput }) => {
                  fetchMoreItems<GetProductListResult, GetProductListVariables>(
                    fetchMore,
                    {
                      // FIXME: should be use string type
                      query: GET_PRODUCT_LIST,
                      variables: {
                        where: {
                          searchKeyword: searchInput
                            ? searchInput.toLowerCase()
                            : '',
                        },
                        first,
                        skip,
                      },
                      dataKey: 'products',
                    },
                  );
                }}
              />
            )
          }
        </Query>
        <Separator style={styles.separator} />
        <View style={styles.otherProductsFieldsWrapper}>
          <Text color={DARKER_GRAY} style={styles.otherProductsTextTitle}>
            {'Produk lain diluar SKU (Optional)'}
          </Text>
          {otherProducts.map((val, index) => {
            return (
              <View style={styles.otherProductsFields}>
                <View style={styles.otherProductsTextInput}>
                  <TextField
                    stretch
                    placeholder="Nama Produk"
                    value={val.productName}
                    onChangeText={(value) =>
                      onChangeOtherProductValue({
                        index,
                        field: 'productName',
                        value,
                      })
                    }
                  />
                </View>
                <View style={styles.otherProductsNumberInput}>
                  <IncrementField
                    value={val.qty}
                    onChangeValue={(value) => {
                      onChangeOtherProductValue({
                        index,
                        field: 'qty',
                        value,
                      });
                    }}
                    onButtonPress={(value) => {
                      onChangeOtherProductValue({
                        index,
                        field: 'qty',
                        value,
                      });
                    }}
                  />
                </View>
                <View style={styles.otherProductsDeleteButton}>
                  <Icon
                    size="small"
                    name="delete"
                    color={GRAY}
                    hoverColor={PRIMARY}
                    onPress={() => {
                      removeProduct(index);
                    }}
                  />
                </View>
              </View>
            );
          })}
          <View style={styles.otherProductsAddButton}>
            <Button
              small
              stretch
              onPress={() => {
                setOtherProducts((products) => [
                  ...products,
                  { productName: '', qty: 0 },
                ]);
              }}
            >
              + Tambah Jenis
            </Button>
          </View>
        </View>
      </View>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modalWrapper: {
    width: 500,
  },
  modalTextHeader: { paddingBottom: 20 },
  otherProductsTextTitle: { paddingBottom: 20, fontSize: 14 },
  otherProductsFieldsWrapper: {
    alignItems: 'flex-start',
    paddingHorizontal: 10,
  },
  otherProductsFields: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    width: '100%',
  },
  otherProductsTextInput: {
    display: 'flex',
    flex: 6,
    marginRight: 10,
  },
  otherProductsNumberInput: {
    flex: 6,
  },
  otherProductsDeleteButton: {
    flex: 1,
  },
  otherProductsAddButton: {
    marginBottom: 20,
    width: '100%',
  },
  separator: { marginVertical: 30 },
});

export const ProductSelectionModal = compose(
  graphql<OwnProps, ModalState, Record<string, unknown>, SelectedStateProps>(
    GET_MODAL_SELECTED_STATE,
    { name: 'selectedStateQuery' },
  ),
  graphql<
    OwnProps,
    UpdateSelectedData,
    Record<string, unknown>,
    OwnProps & UpdateSelectedData
  >(UPDATE_MODAL_SELECTED, { name: 'updateMultiTable' }),
)(ProductSelectionModalComponent) as FC<OwnProps>;
