import gql from 'graphql-tag';

// #region Monitor Program Types
export type MonitorProgram = {
  id: string;
  title: string;
  programType: string;
  maxQty: number;
  gap: number;
  programClaimCount: number;
  segment: string;
  sku: string;
  timeGone: string;
  achievement: number;
  startDate: string;
  endDate: string;
};

export type GetMonitorProgramListResult = {
  monitorProgramsList: Array<MonitorProgram>;
  count: number;
};
// #endregion

// #region Monitor Program Per Depo Types
export type MonitorProgramPerDepo = {
  id: string;
  maxQty: number;
  programClaimCount: number;
  gap: number;
  title: string;
  achievement: number;
  startDate: string;
  endDate: string;
  depotID: string;
  sku: string;
  programTitle: string;
};

export type TotalProgramDetailDepo = {
  totalMaxQty: number;
  totalProgramClaimCount: number;
  gap: number;
  achievement: number;
};

export type GetMonitorProgramsPerDepoResult = {
  monitorProgramsDetailDepo: {
    programDetailDepot: Array<MonitorProgramPerDepo>;
    total: TotalProgramDetailDepo;
  };
  count: number;
};

export type GetMonitorProgramsPerDepoParams = {
  programID: string;
  first?: number;
  skip?: number;
};

export type GetMonitorProgramListVariables = {
  first?: number;
  skip?: number;
  segment?: string;
  title?: string;
};

export type MonitorProgramDetailUser = {
  id: string;
  userName: string;
  subSegment: string;
  outletMaxQty: number;
  programClaimCount: number;
  achievement: number;
  gap: number;
  userCode: string;
  startDate: string;
  endDate: string;
  depotName: string;
  sku: string;
  programTitle: string;
};

export type TotalProgramDetailUser = {
  totalOutletMaxQty: number;
  totalProgramClaimCount: number;
  gap: number;
  achievement: number;
};

export type GetMonitorProgramDetailUserResult = {
  monitorProgramDetailUser: {
    total: TotalProgramDetailUser;
    programDetailUser: Array<MonitorProgramDetailUser>;
  };
  count: number;
};

export type GetMonitorProgramDetailUserListVariables = {
  programID: string;
  depotID: string;
  first?: number;
  skip?: number;
  subSegmentID?: string;
  userName?: string;
};

export const GET_MONITOR_PROGRAMS = gql`
  query getMonitorPrograms(
    $title: String
    $segment: String
    $first: Int
    $skip: Int
  ) {
    monitorProgramsList(
      first: $first
      skip: $skip
      segment: $segment
      title: $title
    ) {
      id
      title
      programType
      maxQty
      gap
      programClaimCount
      segment
      sku
      timeGone
      achievement
      startDate
      endDate
    }
    count: dataCount(
      node: MONITOR_PROGRAM
      monitorProgramWhere: { segment: $segment, title: $title }
    )
  }
`;

export const GET_MONITOR_PROGRAMS_DETAIL_USER = gql`
  query getMonitorProgramDetailUser(
    $first: Int
    $skip: Int
    $programID: String!
    $depotID: String!
    $subSegmentID: String
    $userName: String
  ) {
    monitorProgramDetailUser(
      first: $first
      skip: $skip
      programID: $programID
      depotID: $depotID
      subSegmentID: $subSegmentID
      userName: $userName
    ) {
      programDetailUser {
        userName
        userCode
        subSegment
        outletMaxQty
        programClaimCount
        achievement
        gap
        startDate
        endDate
        depotName
        sku
        programTitle
      }
      total {
        totalOutletMaxQty
        totalProgramClaimCount
        gap
        achievement
      }
    }
    count: dataCount(
      node: MONITOR_PROGRAM_DETAIL_USER
      monitorProgramDetailUserWhere: {
        programID: $programID
        depotID: $depotID
        subSegmentID: $subSegmentID
        userName: $userName
      }
    )
  }
`;

export const GET_MONITOR_PROGRAMS_PER_DEPO = gql`
  query getMonitorProgramsPerDepo(
    $programID: String!
    $first: Int
    $skip: Int
  ) {
    monitorProgramsDetailDepo(
      programID: $programID
      first: $first
      skip: $skip
    ) {
      programDetailDepot {
        maxQty
        programClaimCount
        gap
        title
        achievement
        startDate
        endDate
        depotID
        sku
        programTitle
      }
      total {
        totalMaxQty
        totalProgramClaimCount
        gap
        achievement
      }
    }
    count: dataCount(
      node: MONITOR_PROGRAM_DETAIL_DEPO
      monitorProgramDetailDepoWhere: { programID: $programID }
    )
  }
`;
