import { PaginationState } from '@tanstack/react-table';
import * as React from 'react';
import { DataValue, MutationFunc, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';
import { RouteComponentProps } from 'react-router-dom';
import { ResetFilterButton, SearchField } from '../../../components';
import { Separator, Text } from '../../../core-ui';
import { SearchState } from '../../../graphql/localState';
import {
  GET_SEARCH_STATE,
  UPDATE_SEARCH_STATE,
} from '../../../graphql/queries';
import { AccessProps } from '../../user/UserScene';
import SegmentDropdown from '../components/SegmentDropdown';
import MonitorProgramList from './MonitorProgramList';

type SearchStateProps = { searchStateQuery: DataValue<SearchState, {}> };
type UpdateSearchVariables = {
  searchedString: string;
};
type UpdateSearchData = {
  updateSearch: MutationFunc<{}, UpdateSearchVariables>;
};

export type MonitoringProgramProps = SearchStateProps &
  UpdateSearchData &
  AccessProps &
  RouteComponentProps;

function MonitoringProgramSceneComponent({
  searchStateQuery: { searchState },
  updateSearch,
}: MonitoringProgramProps) {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [search, setSearch] = React.useState('');
  const [segment, setSegment] = React.useState('');

  const clearFilter = () => {
    setSegment('');
  };

  return (
    <View style={styles.root}>
      <View style={styles.header}>
        <Text size="xlarge">Monitoring Program</Text>
        <View style={styles.flexRow}>
          <SearchField
            value={searchState ? searchState.searchedString : ''}
            onChangeText={(text) => {
              updateSearch({ variables: { searchedString: text } });
              setSearch(text);
              setPagination((prevPagination) => ({
                ...prevPagination,
                pageIndex: 0,
              }));
            }}
          />
        </View>
      </View>
      <Separator style={styles.separator} />
      <View style={styles.filterContainer}>
        <SegmentDropdown
          selectedSegment={segment}
          onSegmentChange={(selected) => {
            if (selected != null) {
              setSegment(selected.value);
            }
            setPagination((prevPagination) => ({
              ...prevPagination,
              pageIndex: 0,
            }));
          }}
          containerStyle={styles.filter}
        />
        <View style={{paddingVertical: 35}}>
          <ResetFilterButton onPress={clearFilter} />
        </View>
      </View>
      <MonitorProgramList
        pagination={pagination}
        setPagination={setPagination}
        search={search}
        segment={segment}
      />
    </View>
  );
}

export const MonitoringProgramScene = compose(
  graphql<{}, SearchState, {}, SearchStateProps>(GET_SEARCH_STATE, {
    name: 'searchStateQuery',
  }),
  graphql<{}, UpdateSearchData, {}, UpdateSearchData>(UPDATE_SEARCH_STATE, {
    name: 'updateSearch',
  }),
)(MonitoringProgramSceneComponent);

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  flexRow: {
    flexDirection: 'row',
  },
  separator: {
    marginTop: 20,
    marginBottom: 10,
  },
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  headerImage: {
    width: 300,
    height: 150,
  },
  orderWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterContainer: {
    zIndex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filter: {
    paddingBottom: 20,
    zIndex: 4,
  },
});
