import { Box } from '@material-ui/core';
import { RowSelectionState } from '@tanstack/react-table';
import { ColumnType, Query, ReactTable } from 'components';
import { Button, Modal, Text } from 'core-ui';
import {
  GET_PRODUCT_LIST,
  GetProductListResult,
  GetProductListVariables,
  Product,
} from 'graphql/queries';
import { useToggle } from 'hooks';
import React, { useCallback, useMemo, useState } from 'react';
import { useFilterContext } from '../context';

export function ProductSelection() {
  const { setValues, values } = useFilterContext();

  const [isModalVisible, toggleModal] = useToggle();
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(() =>
    [...(values.products || [])].reduce(
      (acc, id) => ({ ...acc, [id]: true }),
      {} as RowSelectionState,
    ),
  );
  const [productIds, setProductIds] = useState<string[]>([]);

  const columns = useMemo<ColumnType<Product>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Nama Produk',
        size: 300,
      },
    ],
    [],
  );

  const onRowSelectionChange = useCallback((ids: string[], rows: Product[]) => {
    const newSelection = ids.reduce(
      (acc, id) => ({ ...acc, [id]: true }),
      {} as RowSelectionState,
    );

    setRowSelection(newSelection);
    setProductIds(rows.map((row) => row.productID));
  }, []);

  const onSubmit = () => {
    setValues({
      products: Object.keys(rowSelection),
      productIds,
    });

    toggleModal();
  };

  const onClose = () => {
    toggleModal();

    const selectedProducts = [...(values.products || [])].reduce(
      (acc, id) => ({ ...acc, [id]: true }),
      {} as RowSelectionState,
    );
    const selectedProductIds = [...(values.productIds || [])];

    setRowSelection(selectedProducts);
    setProductIds(selectedProductIds);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        style={{
          gap: '6px',
        }}
      >
        <Button color="primary" onPress={toggleModal}>
          Pilih Produk
        </Button>
        <Text>{`${[...(values.products || [])].length} Produk Terpilih`}</Text>
      </Box>
      <Modal
        isVisible={isModalVisible}
        onClose={onClose}
        title="Pilih Produk"
        onSubmit={onSubmit}
        buttonText="Pilih"
        closeButtonText="Batal"
        maxHeight
      >
        <Query<GetProductListResult, GetProductListVariables>
          query={GET_PRODUCT_LIST}
          variables={{
            first: 100,
            skip: 0,
          }}
          fetchPolicy="no-cache"
        >
          {({ data, loading }) => (
            <ReactTable
              columns={columns}
              data={data?.products ?? []}
              isLoading={loading}
              total={data?.count ?? 0}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              onSelectionChange={onRowSelectionChange}
            />
          )}
        </Query>
      </Modal>
    </>
  );
}
