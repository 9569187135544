import { ApolloError } from 'apollo-client';
import { BLACK, DARK_GRAY } from 'constants/colors';
import { Modal, Text, TextField } from 'core-ui';
import { REJECT_ORDER, RejectOrderMutationResult } from 'graphql/mutations';
import { Order } from 'graphql/queries';
import { graphqlErrorRemover } from 'helpers';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType, useState } from 'react';
import { Mutation } from 'react-apollo';
import { StyleSheet, View } from 'react-native';

type RejectModalOwnProps = {
  refetchFn: () => void;
  isVisible: boolean;
  onClose: () => void;
  data: Order | null;
};

export type RejectModalProps = RejectModalOwnProps & ToastContextProps;

function RejectModalComponent({
  onClose,
  openToast,
  refetchFn,
  isVisible,
  data,
}: RejectModalProps) {
  const [message, setMessage] = useState('');

  const onRejectCompleted = (result: RejectOrderMutationResult) => {
    if (result && result.rejectOrder && result.rejectOrder.id) {
      onClose();

      refetchFn();
      openToast && openToast('success', 'Order telah ditolak.');
    }
  };

  const onRejectError = (error: ApolloError) => {
    openToast('fail', graphqlErrorRemover(String(error)));
  };

  const onChangeText = (text: string) => {
    setMessage(text);
  };

  return (
    <Mutation<RejectOrderMutationResult>
      mutation={REJECT_ORDER}
      onCompleted={onRejectCompleted}
      onError={onRejectError}
    >
      {(rejectOrder, { loading }) => (
        <Modal
          hideHeaderClose
          title="Tolak Pesanan"
          closeButtonText="Tidak"
          buttonText="Tolak"
          submitLoading={loading}
          onSubmit={() => {
            rejectOrder({
              variables: {
                id: data && data.id,
                role: 'admin',
                cancelReason: message,
              },
            });
          }}
          submitDisabled={!message}
          isVisible={isVisible}
          onClose={onClose}
        >
          <View style={styles.rejectModalTextfield}>
            <Text
              size="small"
              color={DARK_GRAY}
              style={styles.rejectDescription}
            >
              Apakah Anda yakin ingin menolak pesanan dari{' '}
              <Text size="small" weight="bold" color={BLACK}>
                INVOICE {data && data.invoiceNumber}
              </Text>{' '}
              ?
            </Text>
            <TextField
              stretch
              multiline
              numberOfLines={5}
              label="Alasan Penolakan"
              placeholder="Alasan Penolakan"
              value={message}
              onChangeText={onChangeText}
              style={styles.bottomMargin}
            />
          </View>
        </Modal>
      )}
    </Mutation>
  );
}

const styles = StyleSheet.create({
  bottomMargin: {
    marginBottom: 8,
  },
  rejectModalTextfield: {
    flex: 1,
    paddingBottom: 30,
  },
  rejectDescription: {
    marginBottom: 15,
  },
  modalFooter: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingTop: 30,
  },
  infoWrapper: { height: 40, justifyContent: 'flex-end' },
  infoText: { height: 22 },
  infoSeparator: {
    paddingLeft: 30,
    paddingRight: 40,
  },
  infoValue: { flex: 1 },
});

export const RejectModal = withToast(RejectModalComponent) as ComponentType<
  RejectModalOwnProps
>;
