import * as React from 'react';
import { compose, Mutation, graphql } from 'react-apollo';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import { Form } from '../components';
import {
  CreateCouponVars,
  CREATE_COUPON,
  UpdateCouponVars,
  UPDATE_COUPON,
  UploadPhotoMutationFn,
  UploadPhotoVariables,
  UPLOAD_PHOTO,
} from '../../../graphql/mutations';
import { AccessProps } from '../../../graphql/queries';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import NotFoundPage from '../../NotFoundPage';

type UploadPhotoProps = { uploadPhoto: UploadPhotoMutationFn };

export type CouponFormProps = AccessProps &
  UploadPhotoProps &
  ToastContextProps &
  RouteComponentProps<{ id: string }>;

function CouponFormComponent({
  access,
  history,
  match,
  openToast,
  uploadPhoto,
}: CouponFormProps) {
  const isEdit = !!match.params.id && match.params.id !== 'new';
  const [isLoading, setIsLoading] = React.useState(false);

  if ((!access.create && !isEdit) || (!access.update && isEdit)) {
    return <NotFoundPage />;
  }

  if (isEdit) {
    return (
      <Mutation<null, UpdateCouponVars>
        mutation={UPDATE_COUPON}
        onCompleted={() => {
          openToast('success', 'Informasi kupon berhasil diubah.');
          history.goBack();
        }}
        onError={() => openToast('fail', 'Informasi kupon gagal diubah.')}
      >
        {(updateCoupon, { loading }) => (
          <Form
            isEdit
            history={history}
            couponID={match.params.id}
            submitLoading={loading || isLoading}
            onSubmit={async (data) => {
              setIsLoading(true);

              const photoURL = data.couponImage
                ? await uploadPhoto({
                    variables: {
                      file: data.couponImage,
                      uploadType: 'COUPON',
                    },
                  })
                : {
                    data: {
                      uploadPhoto: {
                        url: data.couponImageUrl || '',
                      },
                    },
                  };

              const createProducts = () => {
                const prevSelectedId = data.initialProducts?.map(
                  (prev) => prev.product.id,
                );
                const newSelected = [...(data.products ?? [])];

                return newSelected?.filter(
                  (newProduct) =>
                    !prevSelectedId?.includes(newProduct.product.id),
                );
              };

              const createAdditionalProducts = () => {
                const prevSelectedId = data.initialAdditionalProducts?.map(
                  (prev) => prev.id,
                );
                const newSelected = [...(data.additionalProducts ?? [])];

                return newSelected?.filter(
                  (newProduct) => !prevSelectedId?.includes(newProduct.id),
                );
              };

              const deleteProducts = () => {
                const prevSelected = [...(data.initialProducts ?? [])];
                const newSelectedId = data.products?.map(
                  (newSelected) => newSelected.product.id,
                );

                return prevSelected.filter(
                  (prev) => !newSelectedId?.includes(prev.product.id),
                );
              };

              const deleteAdditionalProducts = () => {
                const prevSelected = [
                  ...(data.initialAdditionalProducts ?? []),
                ];
                const newSelectedId = data.additionalProducts?.map(
                  (newSelected) => newSelected.id,
                );

                return prevSelected.filter(
                  (prev) => !newSelectedId?.includes(prev.id),
                );
              };

              const updateProducts = () => {
                const prevSelected = [...(data.initialProducts ?? [])];
                const newSelected = data.products;

                if (prevSelected) {
                  for (let i = 0; i < prevSelected!.length; i++) {
                    prevSelected![i].quantity =
                      newSelected?.find(
                        (newProduct) =>
                          newProduct.product.id === prevSelected![i].product.id,
                      )?.quantity ?? 0;
                  }
                }

                return prevSelected;
              };

              const updateAdditionalProducts = () => {
                const prevSelected = [
                  ...(data.initialAdditionalProducts ?? []),
                ];
                const newSelected = data.additionalProducts;

                if (prevSelected) {
                  for (let i = 0; i < prevSelected!.length; i++) {
                    prevSelected![i].quantity =
                      newSelected?.find(
                        (newProduct) => newProduct.id === prevSelected![i].id,
                      )?.quantity ?? 0;
                  }
                }

                return prevSelected;
              };

              const products = data.products
                ? {
                    create: createProducts().map(
                      ({ product: { id }, quantity }) => {
                        return {
                          product: {
                            connect: {
                              id: id,
                            },
                          },
                          quantity: quantity,
                        };
                      },
                    ),
                    updateMany: updateProducts().map(
                      ({ product: { id }, quantity }) => {
                        return {
                          where: {
                            id: id,
                          },
                          data: {
                            quantity: quantity,
                          },
                        };
                      },
                    ),
                    deleteMany: {
                      id_in: deleteProducts().map(({ id }) => id!),
                    },
                  }
                : null;
              const additionalProducts = data.products
                ? {
                    create: createAdditionalProducts().map(
                      ({ name, quantity }) => {
                        return {
                          name: name,
                          quantity: quantity,
                        };
                      },
                    ),
                    updateMany: updateAdditionalProducts().map(
                      ({ id, name, quantity }) => {
                        return {
                          where: {
                            id: id,
                          },
                          data: {
                            name: name,
                            quantity: quantity,
                          },
                        };
                      },
                    ),
                    deleteMany: {
                      id_in: deleteAdditionalProducts().map(({ id }) => id),
                    },
                  }
                : null;

              updateCoupon({
                variables: {
                  id: match.params.id,
                  data: {
                    title: data.title.toLowerCase(),
                    couponType: data.couponType,
                    couponRewardValue: Number(data.couponRewardValue),
                    couponLimit: Number(data.couponLimit),
                    couponPrice: Number(data.couponPrice),
                    startDate: data.startDate
                      ? data.startDate.toISOString()
                      : null,
                    endDate: data.endDate ? data.endDate.toISOString() : null,
                    active: !!data.status,
                    description: data.description,
                    couponImage: photoURL?.data
                      ? photoURL.data.uploadPhoto.url
                      : '',
                    products: products,
                    additionalProducts: additionalProducts,
                  },
                },
              });

              setIsLoading(false);
            }}
          />
        )}
      </Mutation>
    );
  }

  return (
    <Mutation<null, CreateCouponVars>
      mutation={CREATE_COUPON}
      onCompleted={() => {
        openToast('success', 'Kupon berhasil dibuat.');
        history.goBack();
      }}
      onError={() => openToast('fail', 'Kupon gagal dibuat.')}
    >
      {(createCoupon, { loading }) => (
        <Form
          isEdit={false}
          history={history}
          submitLoading={loading || isLoading}
          onSubmit={async (data) => {
            setIsLoading(true);

            const photoURL = data.couponImage
              ? await uploadPhoto({
                  variables: {
                    file: data.couponImage,
                    uploadType: 'COUPON',
                  },
                })
              : undefined;

            const products = data.products
              ? {
                  create: data.products.map(({ product, quantity }) => {
                    return {
                      product: {
                        connect: {
                          id: product.id,
                        },
                      },
                      quantity: quantity,
                    };
                  }),
                }
              : undefined;

            const additionalProducts = data.additionalProducts
              ? {
                  create: data.additionalProducts.map(({ name, quantity }) => {
                    return {
                      name: name,
                      quantity: quantity,
                    };
                  }),
                }
              : undefined;

            createCoupon({
              variables: {
                data: {
                  title: data.title.toLowerCase(),
                  couponType: data.couponType || 'CASHBACK',
                  couponRewardValue: Number(data.couponRewardValue),
                  couponLimit: Number(data.couponLimit),
                  couponPrice: Number(data.couponPrice),
                  startDate: data.startDate
                    ? data.startDate.toISOString()
                    : undefined,
                  endDate: data.endDate
                    ? data.endDate.toISOString()
                    : undefined,
                  active: !!data.status,
                  description: data.description,
                  couponImage: photoURL?.data
                    ? photoURL.data.uploadPhoto.url
                    : '',
                  products: products,
                  additionalProducts: additionalProducts,
                },
              },
            });

            setIsLoading(false);
          }}
        />
      )}
    </Mutation>
  );
}

export const CouponForm = compose(
  withToast,
  graphql<RouteProps, UploadPhotoProps, UploadPhotoVariables, UploadPhotoProps>(
    UPLOAD_PHOTO,
    {
      name: 'uploadPhoto',
    },
  ),
)(CouponFormComponent);
