import gql from 'graphql-tag';

export type MonitoringProgramPerDepo = {
  id: string;
  maxQty: number;
  programClaimCount: number;
  gap: number;
  title: string;
  achievement: number;
  userCode: string;
};

export type GetMonitoringProgramsPerDepoResult = {
  monitorProgramsDetailDepo: MonitoringProgramPerDepo[];
};

export type GetMonitoringProgramsPerDepoParams = {
  programID: string;
  first?: number;
  skip?: number;
};

export const GET_MONITORING_PROGRAMS_PER_DEPO = gql`
  query getMonitoringProgramsPerDepo(
    $programID: String!
    $first: Int
    $skip: Int
  ) {
    monitorProgramsDetailDepo(
      programID: $programID
      first: $first
      skip: $skip
    ) {
      id
      maxQty
      programClaimCount
      gap
      title
      achievement
      userCode
    }
  }
`;
