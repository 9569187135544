import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Query } from 'components';
import { Depot, DepotList, GET_DEPOT_LISTS } from 'graphql/queries';
import React from 'react';
import { useFilterContext } from '../context';

const ALL_DEPOT = {
  id: 'all',
  title: 'SEMUA DEPO',
  coverage: {} as Depot['coverage'],
};

export function DepotSelection() {
  const { values, setValues } = useFilterContext();

  const onChange = (_: React.ChangeEvent<{}>, value: Nullable<Depot>) => {
    setValues({
      depot: value?.id,
    });
  };

  const depotValue = (depots: Depot[]) => {
    if (!values.depot) return undefined;

    return values.depot === 'all'
      ? ALL_DEPOT
      : depots.find((d) => d.id === values.depot);
  };

  return (
    <Query<DepotList> query={GET_DEPOT_LISTS} disableLoading>
      {({ data, loading }) => (
        <Autocomplete<Depot, false, true>
          onChange={onChange}
          getOptionLabel={(option) => option.title}
          defaultValue={ALL_DEPOT}
          renderInput={({ InputLabelProps, ...props }) => (
            <TextField variant="outlined" {...props} />
          )}
          options={[ALL_DEPOT, ...(data?.depots || [])]}
          loading={loading}
          value={depotValue(data?.depots || [])}
          disableClearable
        />
      )}
    </Query>
  );
}
