import { LineSvgProps, ResponsiveLine } from '@nivo/line';
import { WHITE } from 'constants/colors';
import React from 'react';

export type LineChartProps = Omit<LineSvgProps, 'height' | 'width'>;

export function LineChart(props: LineChartProps) {
  return (
    <ResponsiveLine
      useMesh
      colors={{
        datum: 'color',
      }}
      lineWidth={3}
      pointSize={10}
      pointBorderWidth={2}
      pointColor={WHITE}
      pointBorderColor={{
        from: 'serieColor',
      }}
      {...props}
    />
  );
}
