import React, { PropsWithChildren, createContext, useContext } from 'react';
import { DEFAULT_FILTER_STATE } from '../components';

export type PeriodTime = 'daily' | 'weekly' | 'monthly';

export type FilterValues = {
  periodTime: PeriodTime;
  startDate: Nullable<Date>;
  endDate: Nullable<Date>;
  products?: string[];
  productIds?: string[];
  depot: Nullable<string>;
  segmentation?: Nullable<string>;
};

export type FilterContextValues = {
  values: FilterValues;
  setValues: (values: Partial<FilterValues>) => void;
};

export const FilterContext = createContext<FilterContextValues>({
  values: DEFAULT_FILTER_STATE,
  setValues: () => {},
});

export function FilterProvider({
  children,
  values,
  setValues,
}: PropsWithChildren<FilterContextValues>) {
  return (
    <FilterContext.Provider
      value={{
        values,
        setValues,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

export function useFilterContext() {
  const context = useContext(FilterContext);

  if (context === undefined) {
    throw new Error('useFilterContext must be used within FilterProvider');
  }

  return context;
}
