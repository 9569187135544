import { Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PaginationState } from '@tanstack/react-table';
import {
  ColumnType,
  DepotPicker,
  ProgramPicker,
  Query,
  ReactTable,
  ResetFilterButton,
  SearchField,
} from 'components';
import { GRAY, PRIMARY, RED } from 'constants/colors';
import { Button, DatePickerField, Icon, Separator, Text } from 'core-ui';
import {
  AccessProps,
  GET_PROGRAMS,
  GetProgramsParams,
  GetProgramsResult,
  Program,
} from 'graphql/queries';
import {
  asyncStorage,
  convertDepoToPostgresqlID,
  formatShortMonth,
  formatTime,
} from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ProgramDetailModal } from '../components';
import { getProgramTypeString } from '../helpers/getProgramString';
import { DeleteConfirmModal } from './components';

type NewProgramListSceneProps = AccessProps & RouteComponentProps;

const useStyles = makeStyles({
  root: {
    flex: 1,
    padding: '40px 80px',
  },
});

export function NewProgramListScene({
  access,
  history,
}: NewProgramListSceneProps) {
  const classes = useStyles();

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [idPostresqlDepo, setIdPostgresqlDepo] = useState('');
  const [search, setSearch] = useState('');
  const [selectedProgram, setSelectedProgram] = useState<Nullable<string>>(
    null,
  );
  const [selectedDepo, setSelectedDepo] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState<Nullable<string>>(
    null,
  );
  const [selectedEndDate, setSelectedEndDate] = useState<Nullable<string>>(
    null,
  );
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [actionData, setActionData] = useState<Program | null>(null);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);

  const filterQuery = useMemo(() => {
    return {
      ...(selectedProgram ? { programType_in: selectedProgram } : {}),
      ...(selectedDepo || idPostresqlDepo
        ? { depot: { id: selectedDepo || idPostresqlDepo } }
        : {}),
      ...(selectedStartDate ? { startDate_gte: selectedStartDate } : {}),
      ...(selectedEndDate ? { endDate_lte: selectedEndDate } : {}),
      title_contains: search ? search.toLowerCase() : '',
    };
  }, [
    selectedProgram,
    selectedDepo,
    selectedStartDate,
    selectedEndDate,
    search,
    idPostresqlDepo,
  ]);

  const columns = useMemo<ColumnType<Program>[]>(() => {
    return [
      {
        accessorKey: 'title',
        header: 'Nama Program',
        size: 300,
      },
      {
        id: 'validDate',
        header: 'Tanggal Berlaku',
        size: 300,
        cell: ({ row }) => {
          const { startDate, endDate } = row.original;

          return `${formatShortMonth(startDate)} - ${formatShortMonth(
            endDate,
          )}`;
        },
      },
      {
        id: 'programType',
        header: 'Program',
        cell: ({ row }) => {
          const { programType } = row.original;

          return getProgramTypeString(programType);
        },
      },
      {
        id: 'action',
        cell: ({ row }) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Icon
                size="small"
                name="description"
                color={GRAY}
                hoverColor={PRIMARY}
                onPress={() => {
                  setIsDetailVisible(true);
                  setActionData(row.original);
                }}
              />
              {access.update && (
                <Link
                  to={{
                    pathname: `programs/${row.original.id}`,
                    state: { programData: row.original },
                  }}
                >
                  <Icon
                    size="small"
                    name="edit"
                    color={GRAY}
                    hoverColor={PRIMARY}
                  />
                </Link>
              )}
              {access.create && (
                <Link
                  to={{
                    pathname: 'programs/duplicate',
                    state: { programData: row.original },
                  }}
                >
                  <Icon
                    size="small"
                    name="content_copy"
                    color={GRAY}
                    hoverColor={PRIMARY}
                  />
                </Link>
              )}
              {access.delete && (
                <Icon
                  size="small"
                  name="delete"
                  color={GRAY}
                  hoverColor={RED}
                  onPress={() => {
                    setIsDeleteVisible(true);
                    setActionData(row.original);
                  }}
                />
              )}
            </Box>
          );
        },
      },
    ];
  }, [access.create, access.update, access.delete]);

  const onSearch = (text: string) => {
    setSearch(text);
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: 0,
    }));
  };

  const resetPagination = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: 0,
    }));
  };

  const resetFilter = () => {
    setSelectedProgram(null);
    setSelectedDepo('');
    setSelectedStartDate(null);
    setSelectedEndDate(null);
    resetPagination();
  };

  useEffect(() => {
    (async () => {
      const nameUserDepo = (await asyncStorage.getName()) || '';

      try {
        const idPostresqlDepo = convertDepoToPostgresqlID(nameUserDepo);

        setIdPostgresqlDepo(idPostresqlDepo);
      } catch (error) {
        setIdPostgresqlDepo('');
      }
    })();
  }, []);

  return (
    <Box className={classes.root}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pb="20px"
      >
        <Text
          size="xlarge"
          style={{
            flex: 1,
          }}
        >
          Daftar Program
        </Text>
        <SearchField value={search} onChangeText={onSearch} />
        {access.create && (
          <Button
            onPress={() => history.push('/programs/new')}
            text="Tambah Program"
            icon="add"
          />
        )}
      </Box>
      <Separator
        style={{
          marginTop: 20,
          marginBottom: 10,
        }}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="20px 0"
      >
        <Grid container spacing={2} alignItems="center" style={{ zIndex: 2 }}>
          <Grid item>
            <ProgramPicker
              isFilter
              selectedOption={selectedProgram}
              onChange={(selected) => {
                setSelectedProgram(selected ? selected.value : null);
                resetPagination();
              }}
            />
          </Grid>
          <Grid item>
            <DepotPicker
              isFilter
              selectedOption={selectedDepo}
              onChange={(selected) => {
                setSelectedDepo(selected ? selected.value : '');
                resetPagination();
              }}
              hidden={access.role !== 'SUPER_ADMIN'}
            />
          </Grid>
          <Grid item>
            <DatePickerField
              isFilter
              label="Tanggal Berlaku Sejak"
              placeholder="Tanggal Berlaku Sejak"
              selectedDate={
                selectedStartDate ? new Date(selectedStartDate) : null
              }
              onChange={(value) => {
                setSelectedStartDate(value.toISOString());

                if (
                  selectedEndDate &&
                  new Date(selectedEndDate).valueOf() < value.valueOf()
                ) {
                  setSelectedEndDate(value.toISOString());
                }
              }}
            />
          </Grid>
          <Grid item>
            <DatePickerField
              isFilter
              label="Tanggal Berlaku Sampai"
              placeholder="Tanggal Berlaku Sampai"
              selectedDate={selectedEndDate ? new Date(selectedEndDate) : null}
              onChange={(value) => {
                value.setHours(23);
                value.setMinutes(59);
                value.setSeconds(59);
                setSelectedEndDate(value.toISOString());
              }}
              {...(selectedStartDate
                ? { minDate: new Date(selectedStartDate) }
                : {})}
            />
          </Grid>
        </Grid>
        <ResetFilterButton onPress={resetFilter} />
      </Box>
      <Query<GetProgramsResult, GetProgramsParams>
        query={GET_PROGRAMS}
        variables={{
          where: filterQuery,
          first: pagination.pageSize,
          skip: pagination.pageSize * pagination.pageIndex,
        }}
        fetchPolicy="network-only"
        keyData="programsMultiple"
        notifyOnNetworkStatusChange
        disableLoading
      >
        {({ data, loading, refetch }) => (
          <>
            <ReactTable
              columns={columns}
              data={data?.programsMultiple ?? []}
              isLoading={loading}
              pagination={pagination}
              setPagination={setPagination}
              total={data?.count ?? 0}
            />
            <DeleteConfirmModal
              data={actionData}
              isVisible={isDeleteVisible}
              onClose={() => setIsDeleteVisible(false)}
              refetch={refetch}
            />
            <ProgramDetailModal
              data={
                actionData
                  ? {
                      ...{
                        ...actionData,
                        startPeriode: `${formatShortMonth(
                          actionData.startDate,
                        )} - ${formatTime(actionData.startDate)}`,
                        endPeriode: `${formatShortMonth(
                          actionData.endDate,
                        )} - ${formatTime(actionData.endDate)}`,
                      },
                    }
                  : null
              }
              isVisible={isDetailVisible}
              onClose={() => setIsDetailVisible(false)}
            />
          </>
        )}
      </Query>
    </Box>
  );
}
