import React from 'react';
import { StyleSheet, View } from 'react-native';
import {
  ClusterPicker,
  DepotPicker,
  DistrictPicker,
  ResetFilterButton,
  SegmentPicker,
  SubDistrictPicker,
} from '../../../components';
import { Option } from '../../../core-ui';

type FilterProps = {
  selectedDepo: Nullable<string>;
  selectedSegment: Nullable<string>;
  selectedSubSegment: Nullable<string>;
  selectedCluster: Nullable<string>;
  selectedDistrict: Nullable<string>;
  selectedSubDistrict: Nullable<string>;
  clearFilter: () => void;
  onDepotChange: (selected: Nullable<Option<string>>) => void;
  onSegmentChange: (selected: Nullable<Option<string>>) => void;
  onSubSegmentChange: (selected: Nullable<Option<string>>) => void;
  onClusterChange: (selected: Nullable<Option<string>>) => void;
  onDistrictChange: (selected: Nullable<Option<string>>) => void;
  onSubDistrictChange: (selected: Nullable<Option<string>>) => void;
  isAdmin: boolean;
};

export function Filter({
  selectedDepo,
  selectedSegment,
  selectedSubSegment,
  selectedCluster,
  selectedDistrict,
  selectedSubDistrict,
  clearFilter,
  onDepotChange,
  onSegmentChange,
  onSubSegmentChange,
  onClusterChange,
  onDistrictChange,
  onSubDistrictChange,
  isAdmin,
}: FilterProps) {
  return (
    <View style={styles.filters}>
      <View style={styles.filterContainer}>
        <View style={[styles.filterRow, { zIndex: 2 }]}>
          <DepotPicker
            isFilter
            containerStyle={{ zIndex: 5 }}
            style={styles.filterMargin}
            selectedOption={selectedDepo}
            onChange={onDepotChange}
            hidden={!isAdmin}
          />
          <SegmentPicker
            isFilter
            containerStyle={{ zIndex: 4, flexDirection: 'row' }}
            style={styles.filterMargin}
            selectedSegment={selectedSegment}
            selectedSubSegment={selectedSubSegment}
            onSegmentChange={onSegmentChange}
            onSubSegmentChange={onSubSegmentChange}
          />
          <ClusterPicker
            isFilter
            style={[styles.filterMargin, { zIndex: 3 }]}
            selectedOption={selectedCluster}
            onChange={onClusterChange}
          />
        </View>
        <View style={styles.filterRow}>
          <DistrictPicker
            isFilter
            style={[styles.filterMargin, { zIndex: 2 }]}
            selectedOption={selectedDistrict}
            onChange={onDistrictChange}
          />
          <SubDistrictPicker
            isFilter
            style={{ zIndex: 1 }}
            district={selectedDistrict}
            selectedOption={selectedSubDistrict}
            onChange={onSubDistrictChange}
          />
        </View>
      </View>
      <ResetFilterButton onPress={clearFilter} />
    </View>
  );
}

const styles = StyleSheet.create({
  filters: {
    alignItems: 'flex-start',
    zIndex: 3,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: 20,
  },
  filterRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  filterContainer: {},
  filterMargin: { marginRight: 15 },
});
