import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { PaperBox, Query } from 'components';
import {
  BLUE_BONNET,
  CORAL_RED,
  CRYSTAL_BLUE,
  FRENCH_FUCHSIA,
  GRAY20,
  GREEN_FOREST,
  MAIZE_YELLOW,
  MALACHITE_GREEN,
  ORCHID_PINK,
  ROSE_GOLD,
} from 'constants/colors';
import { Icon, Text } from 'core-ui';
import {
  GET_PERFORMANCE_TEAM,
  GetPerformanceTeamParams,
  GetPerformanceTeamResult,
} from 'graphql/queries';
import React, { useCallback, useState } from 'react';
import {
  DEFAULT_FILTER_STATE,
  Filter,
  PieChart,
  Rating,
  SentimentLinearBar,
  Widget,
} from '../components';
import { FilterValues } from '../context';

export function TeamPerformance() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between(810, 1200));

  const [filter, setFilter] = useState<FilterValues>(DEFAULT_FILTER_STATE);
  const onFilter = useCallback((values: Partial<FilterValues>) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...values,
    }));
  }, []);

  const secondsToDays = useCallback((seconds: number) => {
    const aday = 24 * 60 * 60;
    const ahour = 60 * 60;
    const aminute = 60;

    const days = Math.floor(seconds / aday);
    const hours = Math.floor((seconds % aday) / ahour);
    const minutes = Math.floor(((seconds % aday) % ahour) / aminute);

    return {
      days,
      hours,
      minutes,
    };
  }, []);

  const ratingPercentage = useCallback(
    (rating: number, totalRating: number) => {
      return (rating / totalRating) * 100;
    },
    [],
  );

  return (
    <Box
      height={'100%'}
      width={'100%'}
      display="flex"
      flexDirection="column"
      style={{ gap: '2rem' }}
    >
      <Filter dailyOnly onFilter={onFilter} />
      {filter.startDate && filter.endDate && (
        <>
          <Query<GetPerformanceTeamResult, GetPerformanceTeamParams>
            query={GET_PERFORMANCE_TEAM}
            fetchPolicy="cache-and-network"
            variables={
              filter.depot === null
                ? {
                    where: {
                      deliveredDate_gte: new Date(
                        filter.startDate,
                      ).toLocaleString(),
                      deliveredDate_lte: new Date(
                        filter.endDate,
                      ).toLocaleString(),
                    },
                  }
                : {
                    where: {
                      depot: {
                        id: filter.depot,
                      },
                      deliveredDate_gte: new Date(
                        filter.startDate,
                      ).toLocaleString(),
                      deliveredDate_lte: new Date(
                        filter.endDate,
                      ).toLocaleString(),
                    },
                  }
            }
          >
            {({ data }) => {
              if (!data) {
                return null;
              }
              const {
                averageApprovalTime,
                averageDeliveryTime,
                deliverySuccessRate,
                userSatisfactionRate,
                sameDayDeliveryCount,
                oneDayDeliveryCount,
                twoDayDeliveryCount,
                threeDayDeliveryCount,
                fourDayDeliveryCount,
                oneStarRatingCount,
                twoStarRatingCount,
                threeStarRatingCount,
                fourStarRatingCount,
              } = data!.getPerformanceTeam;

              const avgApproveTime = secondsToDays(averageApprovalTime);
              const avgDeliveryTime = secondsToDays(averageDeliveryTime);
              const deliveryCount = [
                {
                  id: 'Others',
                  label: 'Others',
                  value: fourDayDeliveryCount,
                  color: MAIZE_YELLOW,
                },
                {
                  id: 'H+3',
                  label: 'H+3',
                  value: threeDayDeliveryCount,
                  color: ORCHID_PINK,
                },
                {
                  id: 'H+2',
                  label: 'H+2',
                  value: twoDayDeliveryCount,
                  color: MALACHITE_GREEN,
                },
                {
                  id: 'H+1',
                  label: 'H+1',
                  value: oneDayDeliveryCount,
                  color: BLUE_BONNET,
                },
                {
                  id: 'Same Day',
                  label: 'Same Day',
                  value: sameDayDeliveryCount,
                  color: CORAL_RED,
                },
              ];

              const isEmptyDeliveryCount = deliveryCount.every(
                (item) => item.value === 0,
              );

              const totalRating =
                oneStarRatingCount +
                twoStarRatingCount +
                threeStarRatingCount +
                fourStarRatingCount;

              return (
                <>
                  <Box display="flex">
                    <Widget
                      title="Avg Waktu Approve"
                      content={`${avgApproveTime.days} Hari ${avgApproveTime.hours} Jam ${avgApproveTime.minutes} Menit`}
                      widgetColor={GREEN_FOREST}
                      icon="taskAlt"
                      mr={4}
                    />
                    <Widget
                      title="Tingkat Keberhasilan Pengiriman"
                      content={`${(deliverySuccessRate * 100).toFixed(1)}%`}
                      widgetColor={ROSE_GOLD}
                      icon="percent"
                    />
                  </Box>
                  <Box display="flex">
                    <Widget
                      title="Avg Waktu Pengiriman Pesanan"
                      content={`${avgDeliveryTime.days} Hari ${avgDeliveryTime.hours} Jam ${avgDeliveryTime.minutes} Menit`}
                      widgetColor={CRYSTAL_BLUE}
                      icon="timer"
                      mr={4}
                    />
                    <Widget
                      title="Tingkat Kepuasan Pelanggan"
                      content={`${userSatisfactionRate.toFixed(1)} / 4`}
                      widgetColor={FRENCH_FUCHSIA}
                      icon="sentimentSatisfied"
                    />
                  </Box>
                  <PaperBox padding={4} elevation={0}>
                    <Box
                      display="flex"
                      flexDirection={matches ? 'column' : 'row'}
                      style={{
                        gap: '32px',
                      }}
                    >
                      <Box width="100%" flex={1}>
                        <Text weight="bold">
                          Rata-rata Waktu Pengiriman Pesanan
                        </Text>
                        <Box
                          mt={4}
                          height={300}
                          display={'flex'}
                          flexDirection={'column'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          {isEmptyDeliveryCount ? (
                            <>
                              <Icon
                                isCustomSVG
                                name="emptyData"
                                size="xxlarge"
                              />
                              <Text size="small">
                                Tidak ada data untuk ditampilkan
                              </Text>
                            </>
                          ) : (
                            <PieChart
                              data={deliveryCount}
                              margin={{
                                right: 100,
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        border={`1px solid ${GRAY20}`}
                        borderRadius={8}
                      >
                        <Box
                          padding="24px"
                          borderBottom={`1px solid ${GRAY20}`}
                        >
                          <Text weight="bold"> Total Pesanan</Text>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          padding="24px"
                        >
                          <Box display="flex" justifyContent="space-between">
                            <Box width="100px">
                              <Text>Legend</Text>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="center"
                              width="100px"
                            >
                              <Text>Title</Text>
                            </Box>
                            <Box
                              display="flex"
                              justifyContent="end"
                              width="100px"
                            >
                              <Text>Jumlah</Text>
                            </Box>
                          </Box>
                          {[...deliveryCount].reverse().map((item) => {
                            return (
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                mt={2}
                              >
                                <Box width="100px">
                                  <Box
                                    height="24px"
                                    width="24px"
                                    borderRadius="6px"
                                    style={{ backgroundColor: item.color }}
                                  ></Box>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  width="100px"
                                >
                                  <Text>{item.label}</Text>
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                  width="100px"
                                >
                                  <Text>{item.value} Order</Text>
                                </Box>
                              </Box>
                            );
                          })}
                        </Box>
                      </Box>
                    </Box>
                  </PaperBox>

                  <PaperBox padding={4} elevation={0}>
                    <Box>
                      <Text weight="bold">Tingkat Kepuasan</Text>
                    </Box>
                    <Box display="flex" mt={4}>
                      <Box
                        flex={1}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Text weight="bold" size="xlarge">
                          {userSatisfactionRate.toFixed(1)} / 4
                        </Text>
                        <Rating
                          rating={Number(userSatisfactionRate.toFixed(1))}
                        />
                      </Box>
                      <Box flex={3}>
                        <SentimentLinearBar
                          veryHappy={{
                            value: ratingPercentage(
                              fourStarRatingCount,
                              totalRating,
                            ),
                            orders: fourStarRatingCount,
                          }}
                          happy={{
                            value: ratingPercentage(
                              threeStarRatingCount,
                              totalRating,
                            ),
                            orders: threeStarRatingCount,
                          }}
                          neutral={{
                            value: ratingPercentage(
                              twoStarRatingCount,
                              totalRating,
                            ),
                            orders: twoStarRatingCount,
                          }}
                          sad={{
                            value: ratingPercentage(
                              oneStarRatingCount,
                              totalRating,
                            ),
                            orders: oneStarRatingCount,
                          }}
                        />
                      </Box>
                    </Box>
                  </PaperBox>
                </>
              );
            }}
          </Query>
        </>
      )}
    </Box>
  );
}
