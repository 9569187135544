import { FormPage } from 'components';
import UploadCsv from 'components/UploadCsv';
import {
  Card,
  CurrencyField,
  DatePickerField,
  Dropdown,
  Option,
  RadioButton,
  Separator,
  Text,
  TextField,
  TimePicker,
} from 'core-ui';
import {
  DepotComplete,
  GET_DEPOT_LIST,
  GET_USERS,
  MaximalQuantityProgramType,
  ProgramType,
  ProgramUser,
  RewardType,
  User,
} from 'graphql/queries';
import { convertPascalCase, formatThousandSeparator } from 'helpers';
import { parseCsv } from 'helpers/csv';
import { sanitizeNumericInput } from 'helpers/formatNumber';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { useState } from 'react';
import { DataValue, compose, graphql } from 'react-apollo';
import { StyleSheet, View } from 'react-native';
import { AdvanceField } from 'scenes/program/components';
import {
  PROGRAM_TYPE_OPTS,
  REWARD_TYPE_OPTS,
} from 'scenes/program/helpers/programOptions';
import { useFormContext } from '../../contexts';
import { useCalculatedTotalPriceBudget } from '../../hooks';
import {
  ClaimAmount,
  CustomerSelection,
  DepotSelection,
  LoyaltyGift,
  ProductSelection,
  SegmentationSelection,
  TVIPProductSelection,
  TermSelection,
} from './fields';

type CsvUploadStatus = 'disabled' | 'idle' | 'success' | 'fail' | 'uploading';
type DepotData = { depots: Array<DepotComplete> };
type DepotVariables = { where: { title_contains: string } };
type DepotQueryProps = { depotQuery: DataValue<DepotData, DepotVariables> };
type UserData = { users: Array<User> };
type UserVariables = {
  where: { szID_in: Array<string>; depot: { id: string } };
};
type UserQueryProps = { userQuery: DataValue<UserData, UserVariables> };

export type FormOwnProps = {
  isLoading: boolean;
  isDirty: boolean;
  isValid: boolean;
  isEdit?: boolean;
  onDiscard: () => void;
  onSubmit: () => void;
  goBack: () => void;
};

export type FormProps = ToastContextProps &
  FormOwnProps &
  DepotQueryProps &
  UserQueryProps;

function TextHeader({ title }: { title: string }) {
  return (
    <Text weight="bold" style={styles.header}>
      {title}
    </Text>
  );
}

function FormComponent({
  isLoading,
  isDirty,
  isValid,
  isEdit,
  onDiscard,
  onSubmit,
  goBack,
  openToast,
  depotQuery,
  userQuery,
}: FormProps) {
  const { values, onValuesChange } = useFormContext();

  const {
    programType,
    claimLimit,
    rewardType,
    title,
    description,
    maximalQuantityProgram,
    maximalQuantity,
    startDate,
    startDateTime,
    endDate,
    endDateTime,
    titleNotification,
    bodyNotification,
  } = values;

  const [csvUploadStatus, setCsvUploadStatus] = useState<CsvUploadStatus>(
    'disabled',
  );

  const totalPriceCalculatedBudget = useCalculatedTotalPriceBudget();

  const onProcessCsv = async (csvData: ObjectKey[]) => {
    const depotResult: { data?: DepotData } = await depotQuery.refetch({
      where: { title_contains: csvData[0].depoName },
    });
    if (depotResult.data && depotResult.data.depots.length === 1) {
      const { id: depotID } = depotResult.data.depots[0];
      const customerIDs = csvData.map(({ customerID }) => customerID);
      const userResult = await userQuery.refetch({
        where: { szID_in: customerIDs, depot: { id: depotID } },
      });
      let users: Array<User> = [];
      let segment = null;
      let subSegment = null;
      if (userResult.data && userResult.data.users.length > 0) {
        users = userResult.data.users;
        const { segment: userSegment, subSegment: userSubSegment } = users[0];
        segment = { value: userSegment.id, label: userSegment.title };
        subSegment = { value: userSubSegment.id, label: userSubSegment.title };
      }
      setCsvUploadStatus('success');
      onValuesChange({
        selectedCustomers: (users as unknown) as ProgramUser[],
      });
      openToast('success', 'Data berhasil diupload dan diproses.');
    } else {
      openToast(
        'fail',
        'Gagal memproses data yang diupload. Periksa kembali isi data yang Anda upload.',
      );
    }
  };

  const onProcessCsvError = () => {
    setCsvUploadStatus('fail');

    openToast(
      'fail',
      'Gagal memproses data yang diupload. Periksa kembali format data yang Anda upload.',
    );
  };

  const onUploadCsvFail = () => {
    openToast('fail', 'Gagal mengupload file. Periksa jaringan Anda.');
  };

  const onUploadCsv = (files: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      const fileAsBinaryString = (reader.result as unknown) as string;
      parseCsv(fileAsBinaryString, 'programs', onProcessCsv, onProcessCsvError);
    };
    reader.onabort = onUploadCsvFail;
    reader.onerror = onUploadCsvFail;
    reader.readAsText(files[0]);
  };

  // #region Fields update handler
  const onProgramTypeChange = (option: Nullable<Option<string>>) => {
    if (option) {
      const value = option.value as ProgramType;

      setCsvUploadStatus(
        value === 'TRADE_PROMO' || value === 'FLASH_SALE' || value === 'BUNDLE'
          ? 'idle'
          : 'disabled',
      );

      onValuesChange({
        programType: value,
        rewardType:
          value === 'TRADE_PROMO' || value === 'FLASH_SALE' ? 'DISCOUNT' : null,
        claimType: value === 'BUNDLE' ? 'PER_STORE' : 'WHOLE',
      });
    }
  };

  const onRewardTypeChange = (option: Nullable<Option<string>>) => {
    if (option) {
      const value = option.value as RewardType;

      setCsvUploadStatus('idle');

      onValuesChange({
        rewardType: value,
      });
    }
  };

  const onClaimLimitChange = (value: string) => {
    onValuesChange({
      claimLimit: sanitizeNumericInput(value),
    });
  };

  const onMaximalQuantityChange = (value: string) => {
    const valueNumber =
      value === '' ? 0 : parseInt(sanitizeNumericInput(value));

    onValuesChange({
      maximalQuantity: valueNumber,
    });
  };

  const onStartDateChange = (value: Date) => {
    onValuesChange({
      startDate: value.toISOString(),
    });

    if (new Date(endDate).valueOf() < value.valueOf()) {
      onValuesChange({
        endDate: value.toISOString(),
      });
    }
  };
  // #endregion

  return (
    <FormPage
      isSubmitLoading={isLoading}
      isDirty={isDirty}
      isValid={isValid}
      handleDiscard={onDiscard}
      handleSubmit={onSubmit}
      handleGoBack={goBack}
      showBackButton
    >
      <Card
        title={isEdit ? 'Ubah Program' : 'Tambah Program'}
        titleRightComponent={
          <UploadCsv
            onAccept={onUploadCsv}
            disabled={csvUploadStatus === 'disabled'}
          />
        }
      >
        <Dropdown
          labelHorizontal
          label="Jenis Program"
          placeholder="Jenis Program"
          selectedOption={programType}
          options={PROGRAM_TYPE_OPTS}
          onChange={onProgramTypeChange}
          style={{ zIndex: 10 }}
        />
        {programType && programType !== 'BUNDLE' && (
          <Dropdown
            labelHorizontal
            label="Jenis Reward"
            placeholder="Jenis Reward"
            selectedOption={rewardType}
            options={REWARD_TYPE_OPTS[programType]}
            onChange={onRewardTypeChange}
            style={{ zIndex: 9 }}
          />
        )}
        {(rewardType || programType === 'BUNDLE') && (
          <>
            {programType === 'LOYALTY' && rewardType === 'PRESENT' && (
              <LoyaltyGift />
            )}
            <TextField
              stretch
              labelHorizontal
              label="Nama Program"
              placeholder="Nama Program"
              value={convertPascalCase(title)}
              onChangeText={(value: string) => onValuesChange({ title: value })}
            />
            <TextField
              stretch
              labelHorizontal
              label="Deskripsi Program"
              placeholder="Deskripsi Program"
              value={description}
              multiline
              onChangeText={(value: string) =>
                onValuesChange({ description: value })
              }
            />
            {programType === 'TRADE_PROMO' && <ClaimAmount />}
            {(programType === 'BUNDLE' || programType === 'FLASH_SALE') && (
              <TextField
                stretch
                labelHorizontal
                label="Jumlah Klaim"
                placeholder="Jumlah Klaim"
                value={
                  claimLimit ? formatThousandSeparator(Number(claimLimit)) : ''
                }
                onChangeText={onClaimLimitChange}
              />
            )}
            <AdvanceField label="Kuantitas Maksimal Program (per Outlet)">
              <View style={styles.flex}>
                <RadioButton<string>
                  label="Limited"
                  value="LIMITED"
                  selected={maximalQuantityProgram === 'LIMITED'}
                  onSelect={(value) =>
                    onValuesChange({
                      maximalQuantityProgram: value as MaximalQuantityProgramType,
                    })
                  }
                />
              </View>
              <View style={styles.flex}>
                <RadioButton<string>
                  label="Unlimited"
                  value="UNLIMITED"
                  selected={maximalQuantityProgram === 'UNLIMITED'}
                  onSelect={(value) =>
                    onValuesChange({
                      maximalQuantityProgram: value as MaximalQuantityProgramType,
                    })
                  }
                />
              </View>
            </AdvanceField>
            <TextField
              stretch
              labelHorizontal
              label=" "
              style={{ height: 30 }}
              placeholder="Kuantitas"
              value={formatThousandSeparator(maximalQuantity)}
              onChangeText={onMaximalQuantityChange}
            />
            <DatePickerField
              labelHorizontal
              label="Periode awal"
              placeholder="Periode Awal"
              selectedDate={new Date(startDate)}
              onChange={onStartDateChange}
              style={{ zIndex: 11 }}
            />
            <AdvanceField label="Periode Awal - waktu" style={{ zIndex: 10 }}>
              <TimePicker
                value={startDateTime}
                onChange={(value: string) =>
                  onValuesChange({
                    startDateTime: value,
                  })
                }
                style={styles.flex}
              />
            </AdvanceField>
            <DatePickerField
              labelHorizontal
              label="Periode Akhir"
              placeholder="Periode Akhir"
              selectedDate={new Date(endDate)}
              minDate={new Date(startDate)}
              onChange={(value: Date) =>
                onValuesChange({
                  endDate: value.toISOString(),
                })
              }
              style={{ zIndex: 9 }}
            />
            <AdvanceField label="Periode Akhir - waktu" style={{ zIndex: 8 }}>
              <TimePicker
                value={endDateTime}
                onChange={(value: string) =>
                  onValuesChange({
                    endDateTime: value,
                  })
                }
                style={styles.flex}
                showTime={true}
              />
            </AdvanceField>
            <Separator secondary />
            <SegmentationSelection />
            {programType === 'LOYALTY' && rewardType === 'TVIP_PRODUCT' && (
              <TVIPProductSelection />
            )}
            <Separator secondary />
            <ProductSelection />
            <Separator secondary />
            <DepotSelection />
            <Separator secondary />
            <CustomerSelection />
            {programType !== 'BUNDLE' && programType !== 'FLASH_SALE' && (
              <>
                <Separator secondary />
                <TermSelection />
              </>
            )}
            <Separator secondary />
            <TextHeader title="Budget Promo" />
            <CurrencyField
              stretch
              disabled
              labelHorizontal
              label="Budget Promo"
              currencyValue={totalPriceCalculatedBudget}
            />
            <Separator secondary />
            <TextHeader title="Notifikasi" />
            <TextField
              stretch
              labelHorizontal
              label="Title"
              placeholder="Title"
              value={titleNotification}
              onChangeText={(value: string) =>
                onValuesChange({
                  titleNotification: value,
                })
              }
            />
            <TextField
              stretch
              labelHorizontal
              label="Message"
              placeholder="Message"
              value={bodyNotification}
              multiline
              onChangeText={(value: string) =>
                onValuesChange({
                  bodyNotification: value,
                })
              }
            />
          </>
        )}
      </Card>
    </FormPage>
  );
}

const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  header: { marginVertical: 20 },
});

export const Form = compose(
  graphql<UserData, UserVariables, UserQueryProps>(GET_USERS, {
    name: 'userQuery',
  }),
  graphql<DepotData, DepotVariables, DepotQueryProps>(GET_DEPOT_LIST, {
    name: 'depotQuery',
  }),
  withToast,
)(FormComponent);
