import gql from 'graphql-tag';

export type CreateHeaderVars = {
  data: {
    title: string;
    description: string;
    headerImage: string;
    segments: { connect: Array<{ id: string }> };
    subSegments: { connect: Array<{ id: string }> };
  };
};

export type UpdateHeaderVars = {
  id: string;
  data: {
    title: string;
    description: string;
    headerImage: string;
    segments: {
      connect: Array<{ id: string }>;
      disconnect?: Array<{ id: string }>;
    };
    subSegments: {
      connect: Array<{ id: string }>;
      disconnect?: Array<{ id: string }>;
    };
  };
};

export const CREATE_HEADER_HIGHLIGHT = gql`
  mutation createHeader($data: HeaderCreateInput!) {
    createHeader(data: $data) {
      id
    }
  }
`;

export const DELETE_HEADER_HIGHLIGHT = gql`
  mutation deleteMutation($id: ID!) {
    deleteHeader(where: { id: $id }) {
      id
    }
  }
`;

export const UPDATE_HEADER_HIGHLIGHT = gql`
  mutation updateHeader($id: ID!, $data: HeaderUpdateInput!) {
    updateHeader(where: { id: $id }, data: $data) {
      id
      title
      order
    }
  }
`;
