import React, { PropsWithChildren, createContext, useContext } from 'react';
import { DEFAULT_FORM_STATE, FormState } from '../NewProgramFormScene';

export type FormContextValues = {
  values: FormState;
  onValuesChange: (values: Partial<FormState>) => void;
};

export const FormContext = createContext<FormContextValues>({
  values: DEFAULT_FORM_STATE,
  onValuesChange: () => {},
});

export type FormProviderProps = PropsWithChildren<{
  formState: FormState;
  onValuesChange: (values: Partial<FormState>) => void;
}>;

export function FormProvider({
  children,
  formState,
  onValuesChange,
}: FormProviderProps) {
  return (
    <FormContext.Provider
      value={{
        values: formState,
        onValuesChange,
      }}
    >
      {children}
    </FormContext.Provider>
  );
}

export function useFormContext() {
  const context = useContext(FormContext);

  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormProvider');
  }

  return context;
}
