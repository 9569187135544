import { Box, BoxProps, PaperProps } from '@material-ui/core';
import { PaperBox } from 'components';
import { Icon, Text } from 'core-ui';
import React from 'react';

type WidgetProps = {
  widgetColor: string;
  title: string;
  content: string;
  icon: IconKey;
};

export function Widget(props: WidgetProps & BoxProps & PaperProps) {
  const { widgetColor, title, content, icon } = props;

  return (
    <PaperBox
      elevation={0}
      border={`1px solid ${widgetColor}`}
      borderRadius={8}
      padding={3}
      width="100%"
      {...props}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Box
            width="26px"
            height="26px"
            borderRadius={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ backgroundColor: widgetColor }}
          >
            <Icon isCustomSVG name={icon} color="white" size="xsmall" />
          </Box>
          <Box ml={1}>
            <Text>{title}</Text>
          </Box>
        </Box>
        <Box alignSelf="end" mt={1}>
          <Text weight="bold" size="medium">
            {content}
          </Text>
        </Box>
      </Box>
    </PaperBox>
  );
}
