import { Box } from '@material-ui/core';
import React from 'react';
import { Text } from 'core-ui';

export type BreadcrumbProps = {
  labels: string[];
};

export default function Breadcrumb({ labels }: BreadcrumbProps) {
  return (
    <Box marginBottom={3}>
      <Box>
        {labels.map((label, index) => {
          const isLast = index === labels.length - 1;
          const fontWeight = isLast ? 'bold' : 'light';
          return (
            <Text size="xsmall" weight={fontWeight}>
              {label + (isLast ? '' : ' > ')}
            </Text>
          );
        })}
      </Box>
    </Box>
  );
}
