import { Box, BoxProps, Paper, PaperProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export type PaperBoxProps = PaperProps &
  BoxProps & {
    isLoading?: boolean;
  };

export function PaperBox({ isLoading, ...props }: PaperBoxProps) {
  return <Box component={isLoading ? Skeleton : Paper} {...props} />;
}
