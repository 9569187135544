import { PaginationState } from '@tanstack/react-table';
import { GRAY, GREEN, PRIMARY, RED } from 'constants/colors';
import { Icon } from 'core-ui';
import React, { useEffect, useMemo, useState } from 'react';
import { DataValue, Mutation, compose, graphql } from 'react-apollo';
import { View } from 'react-native';
import { Link } from 'react-router-dom';
import { ColumnType, Query, ReactTable } from '../../../components';
import { SearchState } from '../../../graphql/localState';
import {
  AccessProps,
  GET_SEARCH_STATE,
  GET_USERS,
  GetUsersData,
  GetUsersParams,
  SUSPEND_USER,
  SuspendUserParams,
  SuspendUserResult,
  UNSUSPEND_USER,
  User,
} from '../../../graphql/queries';
import { asyncStorage, convertDepoToPostgresqlID } from '../../../helpers';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import UserDetailModal from '../UserDetailModal';
import { Filter } from './Filter';
import { SuspendModal } from './SuspendModal';

type SearchStateProps = {
  searchStateQuery: DataValue<SearchState, Record<string, unknown>>;
};

type UserListProps = ToastContextProps & SearchStateProps & AccessProps;

function UserListComponent({
  searchStateQuery,
  access,
  openToast,
}: UserListProps) {
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [isSuspendVisible, setIsSuspendVisible] = useState(false);
  const [actionDataID, setActionDataID] = useState<Nullable<string>>(null);
  const [searchText, setSearchText] = useState('');
  const [selectedDepo, setSelectedDepo] = useState<Nullable<string>>(null);
  const [selectedSegment, setSelectedSegment] = useState<Nullable<string>>(
    null,
  );
  const [selectedSubSegment, setSelectedSubSegment] = useState<
    Nullable<string>
  >(null);
  const [selectedCluster, setSelectedCluster] = useState<Nullable<string>>(
    null,
  );
  const [selectedDistrict, setSelectedDistrict] = useState<Nullable<string>>(
    null,
  );
  const [selectedSubDistrict, setSelectedSubDistrict] = useState<
    Nullable<string>
  >(null);
  const [idPostresqlDepo, setIdPostresqlDepo] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const filterWhere = useMemo(() => {
    return {
      ...(selectedDepo || idPostresqlDepo
        ? { depot: { id: selectedDepo || idPostresqlDepo } }
        : {}),
      ...(selectedSegment ? { segment: { id: selectedSegment } } : {}),
      ...(selectedSubSegment ? { subSegment: { id: selectedSubSegment } } : {}),
      ...(selectedCluster
        ? { cluster: { currentCluster: selectedCluster } }
        : {}),
      location: {
        ...(selectedDistrict ? { district: { id: selectedDistrict } } : {}),
        ...(selectedSubDistrict
          ? { subDistrict: { id: selectedSubDistrict } }
          : {}),
      },
    };
  }, [
    selectedDepo,
    idPostresqlDepo,
    selectedSegment,
    selectedSubSegment,
    selectedCluster,
    selectedDistrict,
    selectedSubDistrict,
  ]);

  const lowerSearchText = searchText.toLowerCase();
  const whereUsers = {
    approvalKtp: 'APPROVED',
    OR: [
      { id_contains: lowerSearchText },
      { name_contains: lowerSearchText },
      { depot: { title_contains: lowerSearchText } },
      { subSegment: { title_contains: lowerSearchText } },
    ],
    ...filterWhere,
  };

  const closeSuspend = () => {
    setIsSuspendVisible(false);
  };

  const resetPagination = () => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: 0,
    }));
  };

  const clearFilter = () => {
    setSelectedDepo(null);
    setSelectedSegment(null);
    setSelectedSubSegment(null);
    setSelectedCluster(null);
    setSelectedDistrict(null);
    setSelectedSubDistrict(null);
    resetPagination();
  };

  const tableColumns = useMemo<ColumnType<User>[]>(() => {
    return [
      {
        accessorKey: 'szID',
        header: 'Kode Pelanggan',
      },
      {
        accessorKey: 'storeName',
        header: 'Nama Toko',
      },
      {
        accessorKey: 'depot.title',
        header: 'Depo',
      },
      {
        accessorKey: 'subSegment.title',
      },
      {
        accessorKey: 'cluster.currentCluster',
        header: 'Cluster',
      },
      {
        accessorKey: 'Progress Cluster',
        cell: ({ row }) =>
          `${row.original.clusterProgress.quantity}/${row.original.clusterProgress.target}`,
      },
      {
        accessorKey: 'tvipCredit',
        header: 'TVIP Credit',
      },
      {
        accessorKey: 'tvipPoint',
        header: 'TVIP Point',
      },
      {
        id: 'suspended',
        cell: ({ row }) => (
          <Icon
            name={!row.original.suspended ? 'check' : 'close'}
            color={!row.original.suspended ? GREEN : RED}
          />
        ),
      },
      {
        id: 'action',
        cell: ({ row }) => {
          return (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <Icon
                size="small"
                name="description"
                color={GRAY}
                hoverColor={PRIMARY}
                onPress={() => {
                  setIsDetailVisible(true);
                  setActionDataID(row.original.id);
                }}
              />
              {access.update && (
                <Link to={{ pathname: `users/${row.original.id}`, state: {} }}>
                  <Icon
                    size="small"
                    name="edit"
                    color={GRAY}
                    hoverColor={PRIMARY}
                  />
                </Link>
              )}
              {access.delete && (
                <Icon
                  size="small"
                  name="report"
                  color={GRAY}
                  hoverColor={!row.original.suspended ? RED : GREEN}
                  onPress={() => {
                    setIsSuspendVisible(true);
                    setActionDataID(row.original.id);
                  }}
                />
              )}
            </View>
          );
        },
      },
    ];
  }, [access.delete, access.update]);

  useEffect(() => {
    const fetchNameUserDepo = async () => {
      const nameDepo = (await asyncStorage.getName()) || '';

      try {
        setIdPostresqlDepo(convertDepoToPostgresqlID(nameDepo));
      } catch (error) {
        setIdPostresqlDepo('');
      }
    };

    fetchNameUserDepo();
  }, []);

  useEffect(() => {
    const currString = searchStateQuery.searchState?.searchedString;
    setSearchText(currString || '');
    resetPagination();
  }, [searchStateQuery]);

  return (
    <>
      <Filter
        clearFilter={clearFilter}
        onDepotChange={(selected) => {
          setSelectedDepo(selected ? selected.value : selected);
          resetPagination();
        }}
        onSegmentChange={(selected) => {
          const newSelected = selected ? selected.value : selected;
          setSelectedSegment(newSelected);
          setSelectedSubSegment(
            selectedSegment !== newSelected ? null : selectedSubSegment,
          );
          resetPagination();
        }}
        selectedCluster={selectedCluster}
        selectedDepo={selectedDepo}
        selectedDistrict={selectedDistrict}
        selectedSegment={selectedSegment}
        selectedSubDistrict={selectedSubDistrict}
        selectedSubSegment={selectedSubSegment}
        onSubSegmentChange={(selected) => {
          setSelectedSubSegment(selected ? selected.value : selected);
          resetPagination();
        }}
        onClusterChange={(selected) => {
          setSelectedCluster(selected ? selected.value : selected);
          resetPagination();
        }}
        onDistrictChange={(selected) => {
          const newSelected = selected ? selected.value : selected;
          setSelectedDistrict(newSelected);
          setSelectedSubDistrict(
            selectedDistrict !== newSelected ? null : selectedSubDistrict,
          );
          resetPagination();
        }}
        onSubDistrictChange={(selected) => {
          setSelectedSubDistrict(selected ? selected.value : selected);
          resetPagination();
        }}
        isAdmin={access.role === 'SUPER_ADMIN'}
      />
      <Query<GetUsersData, GetUsersParams>
        fetchPolicy="network-only"
        query={GET_USERS}
        variables={{
          where: whereUsers,
          first: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
        }}
        keyData="users"
        notifyOnNetworkStatusChange
      >
        {({ data, loading, refetch }) => {
          const { users, count } = data || {};

          return (
            <Mutation<SuspendUserResult, SuspendUserParams>
              mutation={SUSPEND_USER}
              onCompleted={() => {
                refetch();
                closeSuspend();
                openToast('success', 'Pelanggan telah di-suspend.');
              }}
              onError={() => openToast('fail', 'Pelanggan gagal di-suspend.')}
            >
              {(suspendUser, { loading: suspendLoading }) => (
                <Mutation<SuspendUserResult, SuspendUserParams>
                  mutation={UNSUSPEND_USER}
                  onCompleted={() => {
                    refetch();
                    closeSuspend();
                    openToast('success', 'Pelanggan telah di-unsuspend.');
                  }}
                  onError={() =>
                    openToast('fail', 'Pelanggan gagal di-unsuspend.')
                  }
                >
                  {(unsuspendUser, { loading: unsuspendLoading }) => {
                    const suspend = (
                      user: User,
                      suspendReason: string,
                    ) => () => {
                      suspendUser({
                        variables: { id: user.id, suspendReason },
                      });
                    };

                    const unsuspend = (user: User) => () =>
                      unsuspendUser({ variables: { id: user.id } });

                    return (
                      <>
                        <ReactTable
                          data={users ?? []}
                          columns={tableColumns}
                          pagination={pagination}
                          setPagination={setPagination}
                          total={count}
                          isLoading={loading}
                        />
                        {/* <Table
                          access={access}
                          count={count || 0}
                          data={[...(users || [])]}
                          loading={loading}
                          fetchMore={fetchMoreFn}
                          onChangePage={(nextPage) => setPage(nextPage)}
                          onChangeRowsPerPage={(rowsPage) =>
                            setRowsPerPage(rowsPage)
                          }
                          onDetailClick={(id) => {
                            setIsDetailVisible(true);
                            setActionDataID(id);
                          }}
                          onResetPage={(reset) => {
                            setResetPage(reset);
                            setPage(0);
                          }}
                          onSuspendClick={(id) => {
                            setIsSuspendVisible(true);
                            setActionDataID(id);
                          }}
                          page={page}
                          resetPage={resetPage}
                          rowsPerPage={rowsPerPage}
                        /> */}
                        <UserDetailModal
                          data={users ?? []}
                          isVisible={isDetailVisible}
                          dataID={actionDataID}
                          onClose={() => setIsDetailVisible(false)}
                        />
                        <SuspendModal
                          actionDataID={actionDataID}
                          closeSuspend={closeSuspend}
                          data={users ?? []}
                          isSuspendVisible={isSuspendVisible}
                          loading={suspendLoading || unsuspendLoading}
                          suspend={suspend}
                          unsuspend={unsuspend}
                        />
                      </>
                    );
                  }}
                </Mutation>
              )}
            </Mutation>
          );
        }}
      </Query>
    </>
  );
}

export const UserList = compose(
  withToast,
  graphql<{}, SearchState, Record<string, unknown>, SearchStateProps>(
    GET_SEARCH_STATE,
    {
      name: 'searchStateQuery',
    },
  ),
)(UserListComponent);
