import { Query } from 'components';
import { CurrencyField, Modal } from 'core-ui';
import {
  UPDATE_GALLON_PRICE,
  UpdateGallonPriceResult,
  UpdateGallonPriceVars,
} from 'graphql/mutations';
import {
  GET_GALLON_PRICE_LIST,
  GallonPriceListResult,
  GallonType,
} from 'graphql/queries';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType, useState } from 'react';
import { Mutation } from 'react-apollo';

type BottlePriceSettingModalComponentOwnProps = {
  isVisible: boolean;
  onClose: () => void;
};
export type BottlePriceSettingModalComponentProps = ToastContextProps &
  BottlePriceSettingModalComponentOwnProps;

function BottlePriceSettingModalComponent({
  isVisible,
  onClose,
  openToast,
}: BottlePriceSettingModalComponentProps) {
  const [gallonPrice, setGallonPrice] = useState<
    {
      [key in GallonType]: number;
    }
  >({
    AQUA: 0,
    VIT: 0,
  });

  const onGallonPriceFetched = (data: GallonPriceListResult) => {
    const prices = data.gallonPrices.reduce((acc, { gallonType, price }) => {
      acc[gallonType] = price;
      return acc;
    }, {} as { [key in GallonType]: number });

    setGallonPrice(prices);
  };

  const onFailUpdateGallon = () => {
    openToast('fail', 'Gagal mengubah harga galon.');
  };

  const onPriceChange = (gallonType: GallonType) => (price: number) => {
    setGallonPrice((prevGallonPrice) => ({
      ...prevGallonPrice,
      [gallonType]: price,
    }));
  };

  return (
    <Query<GallonPriceListResult>
      query={GET_GALLON_PRICE_LIST}
      onCompleted={onGallonPriceFetched}
      fetchPolicy="network-only"
      disableLoading
    >
      {({ data, refetch }) => (
        <Mutation<UpdateGallonPriceResult, UpdateGallonPriceVars>
          mutation={UPDATE_GALLON_PRICE}
          onCompleted={refetch}
          onError={onFailUpdateGallon}
        >
          {(updateGallon, { loading }) => (
            <Modal
              hideHeaderClose
              title="Pengaturan Harga Botol"
              closeButtonText="Batal"
              buttonText="Simpan"
              submitLoading={loading}
              onSubmit={async () => {
                if (data) {
                  const result = await Promise.all(
                    data.gallonPrices.map(({ id, gallonType }) => {
                      const price = gallonPrice[gallonType];
                      return updateGallon({
                        variables: { id, data: { price } },
                      });
                    }),
                  );
                  if (result[0] && result[1]) {
                    openToast('success', 'Harga galon telah diubah.');
                    onClose();
                  }
                }
              }}
              description="Masukkan harga botol sesuai dengan merk yang tertera."
              isVisible={isVisible}
              onClose={onClose}
            >
              <>
                <CurrencyField
                  stretch
                  labelHorizontal
                  label="Aqua"
                  currencyValue={gallonPrice.AQUA}
                  onCurrencyChange={onPriceChange('AQUA')}
                />
                <CurrencyField
                  stretch
                  labelHorizontal
                  label="Vit"
                  currencyValue={gallonPrice.VIT}
                  onCurrencyChange={onPriceChange('VIT')}
                />
              </>
            </Modal>
          )}
        </Mutation>
      )}
    </Query>
  );
}

export const BottlePriceSettingModal = withToast(
  BottlePriceSettingModalComponent,
) as ComponentType<BottlePriceSettingModalComponentOwnProps>;
