import React, { useEffect } from 'react';
import { compose, graphql, MutationFunc } from 'react-apollo';
import { StyleSheet, View } from 'react-native';
import { RouteComponentProps } from 'react-router-dom';
import { SearchField } from '../../../components';
import { Button, Text } from '../../../core-ui';
import { SearchState } from '../../../graphql/localState';
import {
  AccessProps,
  GET_SEARCH_STATE,
  UpdateSearchVariables,
  UPDATE_SEARCH_STATE,
} from '../../../graphql/queries';

type HeaderProps = AccessProps &
  Pick<RouteComponentProps, 'history'> & { onSearch: (text: string) => void };

type UpdateSearchData = {
  updateSearch: MutationFunc<Record<string, unknown>, UpdateSearchVariables>;
};

function HeaderComponent({
  access,
  history,
  onSearch,
  searchStateQuery,
  updateSearch,
}: HeaderProps & { searchStateQuery: SearchState } & UpdateSearchData) {
  useEffect(
    () => () => {
      updateSearch({ variables: { searchedString: '' } });
    },
    [updateSearch],
  );

  return (
    <View style={styles.header}>
      <Text size="xlarge" style={styles.flex}>
        Daftar Kupon
      </Text>
      <SearchField
        value={searchStateQuery.searchState.searchedString}
        onChangeText={(text) => {
          onSearch(text);
          updateSearch({ variables: { searchedString: text } });
        }}
      />
      {access.create && (
        <Button
          icon="add"
          text="Tambah Kupon"
          onPress={() => history.push('/coupons/new')}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  header: { alignItems: 'center', flexDirection: 'row', paddingBottom: 20 },
});

export const Header = compose(
  graphql<HeaderProps, SearchState, Record<string, unknown>, SearchState>(
    GET_SEARCH_STATE,
    {
      name: 'searchStateQuery',
    },
  ),
  graphql<
    HeaderProps,
    UpdateSearchData,
    UpdateSearchVariables,
    UpdateSearchData
  >(UPDATE_SEARCH_STATE, { name: 'updateSearch' }),
)(HeaderComponent);
