import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Paper,
  Select,
  withStyles,
} from '@material-ui/core';
import { BLUE, SEPARATOR } from 'constants/colors';
import { Icon, Modal } from 'core-ui';
import { endOfWeek, endOfYear, format, startOfWeek } from 'date-fns';
import id from 'date-fns/locale/id';
import React, { MouseEvent } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { PeriodTime, useFilterContext } from '../context';
import { MONTHS, YEARS } from '../helpers';
import './DatePicker.css';
import { MAP_PERIOD_TIME } from './Filter';

registerLocale('id', id);

export type DatePickerModalProps = {
  dailyOnly?: boolean;
  isOpen: boolean;
  onClose: () => void;
};

const ButtonPeriod = withStyles({
  root: {
    border: 'none',
    borderBottom: `1px solid ${SEPARATOR}`,
    borderRadius: 0,
    justifyContent: 'flex-start',
    '&.active': {
      backgroundColor: BLUE,
      color: 'white',
    },
  },
  label: {
    fontFamily: 'Rubik-Regular',
  },
})(Button);

const MonthYearSelect = withStyles({
  icon: {
    display: 'none',
  },
  root: {
    padding: '6px',
  },
})(Select);

export function DatePickerModal({
  isOpen,
  onClose,
  dailyOnly,
}: DatePickerModalProps) {
  const { values, setValues } = useFilterContext();

  const onChange = (dates: [Nullable<Date>, Nullable<Date>]) => {
    const [start, end] = dates;

    if (values.periodTime === 'weekly' && start && end) {
      setValues({
        startDate: startOfWeek(start, { weekStartsOn: 1 }),
        endDate: endOfWeek(end, {
          weekStartsOn: 1,
        }),
      });
    } else {
      setValues({
        startDate: start,
        endDate: end,
      });
    }
  };

  const onChangePeriodTime = (periodTime: PeriodTime) => (
    _: MouseEvent<HTMLButtonElement>,
  ) => {
    setValues({
      periodTime,
      startDate: null,
      endDate: null,
    });
  };

  return (
    <Modal
      isVisible={isOpen}
      onClose={onClose}
      title="Periode Waktu"
      maxWidth="sm"
    >
      <Box
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gridGap="2rem"
        justifyContent="space-between"
        component={(props) => <Paper elevation={0} {...props} />}
        p={2}
      >
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            {Object.entries(MAP_PERIOD_TIME).map(([key, value]) => (
              <ButtonPeriod
                disabled={dailyOnly && key !== 'daily'}
                style={{ flex: 1 }}
                onClick={onChangePeriodTime(key as PeriodTime)}
                className={
                  values.periodTime === (key as PeriodTime)
                    ? 'active'
                    : undefined
                }
              >
                {value}
              </ButtonPeriod>
            ))}
          </Box>
        </Box>
        <Box>
          <DatePicker
            selected={values.startDate}
            startDate={values.startDate}
            endDate={values.endDate}
            onChange={onChange}
            selectsRange
            inline
            locale="id"
            dateFormat={
              values.periodTime === 'monthly' ? 'MM/yyyy' : 'dd/MM/yyyy'
            }
            showMonthYearPicker={values.periodTime === 'monthly'}
            disabledKeyboardNavigation
            maxDate={endOfYear(new Date())}
            minDate={new Date(`${YEARS[YEARS.length - 1]}`)}
            renderCustomHeader={({
              increaseMonth,
              decreaseMonth,
              increaseYear,
              decreaseYear,
              nextYearButtonDisabled,
              prevYearButtonDisabled,
              date,
              nextMonthButtonDisabled,
              prevMonthButtonDisabled,
              changeYear,
              changeMonth,
            }) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box display="flex" alignItems="center">
                  {values.periodTime !== 'monthly' && (
                    <MonthYearSelect
                      value={date.getMonth()}
                      onChange={(e) => changeMonth(Number(e.target.value))}
                      disableUnderline
                    >
                      {MONTHS.map((month, index) => (
                        <MenuItem key={month} value={index}>
                          {month}
                        </MenuItem>
                      ))}
                    </MonthYearSelect>
                  )}
                  <MonthYearSelect
                    value={format(date, 'yyyy')}
                    onChange={(e) => changeYear(Number(e.target.value))}
                    disableUnderline
                  >
                    {YEARS.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </MonthYearSelect>
                </Box>
                <Box display="flex" alignItems="center" style={{ gap: 4 }}>
                  <IconButton
                    size="small"
                    disabled={
                      values.periodTime === 'monthly'
                        ? prevYearButtonDisabled
                        : prevMonthButtonDisabled
                    }
                    onClick={
                      values.periodTime === 'monthly'
                        ? decreaseYear
                        : decreaseMonth
                    }
                  >
                    <Icon name="keyboard_arrow_left" size="small" />
                  </IconButton>
                  <IconButton
                    size="small"
                    disabled={
                      values.periodTime === 'monthly'
                        ? nextYearButtonDisabled
                        : nextMonthButtonDisabled
                    }
                    onClick={
                      values.periodTime === 'monthly'
                        ? increaseYear
                        : increaseMonth
                    }
                  >
                    <Icon name="keyboard_arrow_right" size="small" />
                  </IconButton>
                </Box>
              </Box>
            )}
          />
        </Box>
      </Box>
    </Modal>
  );
}
