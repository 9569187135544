import React, { useCallback, useState } from 'react';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { CustomField, SegmentSelectionModal } from 'scenes/program/components';

export function SegmentationSelection() {
  const [isSegmentVisible, setIsSegmentVisible] = useState(false);

  const { values, onValuesChange } = useFormContext();

  const { selectedSubSegments, selectedSegments } = values;

  const onSegmentationSelect = useCallback(
    (segment: string[], subSegment: string[], isCancel?: boolean) => {
      const programSegments = segment.map((seg) => ({
        segment: {
          id: seg,
        },
      }));

      const programSubSegments = subSegment.map((seg) => ({
        subSegment: {
          id: seg,
        },
      }));

      const otherFields = isCancel
        ? {}
        : {
            selectedCustomers: [],
          };

      onValuesChange({
        selectedSegments: programSegments,
        selectedSubSegments: programSubSegments,
        ...otherFields,
      });
    },
    [onValuesChange],
  );

  return (
    <>
      <CustomField
        headerText="Segmentasi"
        bodyText="Tentukan segmentasi yang akan mendapatkan program ini."
        buttonText="Pilih Segmentasi"
        selectedText={`${selectedSubSegments.length} sub segmentasi terpilih`}
        onPress={() => setIsSegmentVisible(true)}
      />

      {isSegmentVisible && (
        <SegmentSelectionModal
          onClose={() => setIsSegmentVisible(false)}
          onSelect={onSegmentationSelect}
          currentSegments={selectedSegments.map((segment) => {
            return segment.segment.id;
          })}
          currentSubSegments={selectedSubSegments.map((subsegment) => {
            return subsegment.subSegment.id;
          })}
        />
      )}
    </>
  );
}
