import { ProductSelectionModal } from 'components/Modal';
import { RETAIL } from 'constants/priceSegment';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType, useMemo, useState } from 'react';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { CustomField } from 'scenes/program/components';
import {
  BundlingProductSelectionModal,
  FlashSaleModal,
} from 'scenes/program/modals';

function ProductSelectionComponent({ openToast }: ToastContextProps) {
  const [isProductSelectionVisible, setIsProductSelectionVisible] = useState(
    false,
  );
  const [isBundlingProductVisible, setIsBundlingProductVisible] = useState(
    false,
  );
  const [isFlashSaleVisible, setIsFlashSaleVisible] = useState(false);

  const { values, onValuesChange } = useFormContext();
  const {
    selectedSegments,
    selectedSubSegments,
    programType,
    selectedBundleProducts,
    selectedProducts,
    selectedFlashSaleTerms,
  } = values;

  const selectedText = useMemo(() => {
    if (programType === 'BUNDLE') {
      return selectedBundleProducts.length > 0
        ? `${selectedBundleProducts.length} produk terpilih`
        : null;
    }
    return selectedProducts.length > 0
      ? `${selectedProducts.length} Produk Terpilih`
      : null;
  }, [selectedBundleProducts.length, selectedProducts.length, programType]);

  const isSegmentationSelected =
    selectedSegments.length > 0 && selectedSubSegments.length > 0;

  const priceSegmentCode = RETAIL;

  return (
    <>
      <CustomField
        headerText="Pilih Produk"
        bodyText="Tentukan produk yang dapat di beli dengan menggunakan jenis program ini."
        buttonText="Pilih Produk"
        selectedText={selectedText}
        onPress={() => {
          if (selectedSegments.length > 0 && selectedSubSegments.length > 0) {
            if (programType === 'FLASH_SALE') {
              setIsFlashSaleVisible(true);
            } else if (programType === 'BUNDLE') {
              setIsBundlingProductVisible(true);
            } else {
              setIsProductSelectionVisible(true);
            }
          } else {
            openToast('fail', 'Pilih segmentasi dan sub segmentasi dahulu.');
          }
        }}
      />

      {(() => {
        if (isProductSelectionVisible && isSegmentationSelected) {
          return (
            <ProductSelectionModal
              isVisible
              priceSegmentCode={priceSegmentCode}
              onClose={() => setIsProductSelectionVisible(false)}
              selectedProducts={selectedProducts}
              onChangeSelected={(selectedArray) =>
                onValuesChange({
                  selectedProducts: selectedArray,
                })
              }
              bodyText="Tentukan produk yang dapat dibeli menggunakan jenis program ini."
              withPrice
            />
          );
        }

        if (isBundlingProductVisible && isSegmentationSelected) {
          return (
            <BundlingProductSelectionModal
              isVisible
              onClose={() => setIsBundlingProductVisible(false)}
              selectedProducts={selectedBundleProducts}
              onChangeSelected={(selectedArray) =>
                onValuesChange({
                  selectedBundleProducts: selectedArray,
                })
              }
            />
          );
        }

        if (isFlashSaleVisible && isSegmentationSelected) {
          return (
            <FlashSaleModal
              isVisible
              priceSegmentCode={priceSegmentCode}
              onClose={() => setIsFlashSaleVisible(false)}
              selectedProducts={selectedProducts}
              selectedTerms={selectedFlashSaleTerms}
              onChangeSelected={(newSelectedProducts, newSelectedTerms) =>
                onValuesChange({
                  selectedProducts: newSelectedProducts,
                  selectedFlashSaleTerms: newSelectedTerms,
                })
              }
            />
          );
        }

        return null;
      })()}
    </>
  );
}

export const ProductSelection = withToast(
  ProductSelectionComponent,
) as ComponentType;
