import React from 'react';
import { Mutation } from 'react-apollo';
import { StyleSheet, View } from 'react-native';
import { RowsPerPage, Table } from '../../../components';
import { DARK_GRAY, GRAY, PRIMARY } from '../../../constants/colors';
import { Icon, Modal, Text } from '../../../core-ui';
import {
  CREATE_CLOSING,
  GET_CLOSINGS,
  GET_COMPLETED_ORDER_LIST,
  Order,
} from '../../../graphql/queries';
import {
  fetchMoreItems,
  formatNumberDate,
  formatTime,
  graphqlErrorRemover,
} from '../../../helpers';
import withToast, { ToastContextProps } from '../../../helpers/withToast';
import {
  getOrderStatusString,
  getPaymentStatusString,
} from '../../order/helpers';
import { ClosingFilter } from './ClosingDayScene';

export type ClosingDayTableProps = Partial<ToastContextProps> & {
  data: Nullable<Array<Order>>;
  fetchMore: (params: any) => void;
  refetch: () => void;
  loading: boolean;
  dataKey: 'orders' | 'closings';
  count: number;
  isModalOpen: boolean;
  onModalClose: () => void;
  onDetail: (order: Order) => void;
  rowsPerPage: RowsPerPage;
  onRowsPerPageChange: (rowsPerPage: RowsPerPage) => void;
  filter: ClosingFilter;
  page: number;
  onPageChange: (nextPage: number) => void;
  variables: Record<string, any>;
};

function ClosingDayTableComponent({
  data,
  fetchMore,
  refetch,
  loading,
  dataKey,
  count,
  onModalClose,
  isModalOpen,
  openToast,
  onDetail,
  rowsPerPage,
  onRowsPerPageChange,
  filter,
  page,
  onPageChange,
  variables,
}: ClosingDayTableProps) {
  return (
    <View style={styles.bodyWrapper}>
      <Mutation<
        unknown,
        {
          depotID: string;
        }
      >
        mutation={CREATE_CLOSING}
        onCompleted={() => {
          onModalClose();
          refetch();

          openToast?.('success', 'Penjualan hari ini berhasil ditutup!');
        }}
        onError={(error) => {
          openToast?.('fail', graphqlErrorRemover(error.message));
        }}
        refetchQueries={[{ query: GET_CLOSINGS }]}
      >
        {(createClosing, { loading }) => (
          <Modal
            hideHeaderClose
            title="Tutup Pembukuan"
            description="Tutup pembukuan hari ini?"
            closeButtonText="Tidak"
            buttonText="Konfirmasi"
            submitLoading={loading}
            onSubmit={() => {
              if (count === 0) {
                onModalClose();
                openToast?.('fail', 'Belum ada pesanan selesai hari ini');
              } else {
                createClosing({
                  variables: {
                    depotID: filter.depot || '',
                  },
                });
              }
            }}
            isVisible={isModalOpen}
            onClose={onModalClose}
          />
        )}
      </Mutation>
      <Table
        data={data || []}
        dataCount={count || 0}
        page={page}
        onChangePage={onPageChange}
        isLoading={loading}
        rowsPerPage={rowsPerPage}
        structure={{
          poNumber: { headerTitle: 'Nomor PO' },
          doNumber: { headerTitle: 'Nomor DO' },
          invoiceNumber: { headerTitle: 'Invoice' },
          status: {
            headerTitle: 'Status',
            render: ({ status, createdAt }) => (
              <View style={{ paddingVertical: 8 }}>
                <Text size="small" weight="reg" style={styles.firstRowText}>
                  {getOrderStatusString(status)}
                </Text>
                <Text
                  size="small"
                  weight="reg"
                  numberOfLines={1}
                  style={{ letterSpacing: 1.5 }}
                  color={DARK_GRAY}
                >
                  {formatNumberDate(new Date(createdAt), true)}
                </Text>
              </View>
            ),
          },
          createdAt: {
            headerTitle: 'Tanggal Diterima',
            render: ({ createdAt }) => (
              <View style={styles.column}>
                <Text size="small" weight="reg" style={styles.firstRowText}>
                  {formatNumberDate(new Date(createdAt), false, false)}
                </Text>
                <Text
                  size="small"
                  weight="reg"
                  style={{ letterSpacing: 1.5 }}
                  color={DARK_GRAY}
                >
                  {formatTime(new Date(createdAt))}
                </Text>
              </View>
            ),
          },
          eta: {
            headerTitle: 'Estimasi',
            render: ({ createdAt }) => {
              const eta = new Date(createdAt);
              eta.setDate(eta.getDate() + 2);
              return (
                <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
                  {formatNumberDate(eta, false, false)}
                </Text>
              );
            },
          },
          paymentStatus: {
            headerTitle: 'Transaksi',
            render: ({ paymentStatus }) => (
              <Text size="small" weight="reg" style={{ letterSpacing: 1.5 }}>
                {getPaymentStatusString(paymentStatus)}
              </Text>
            ),
          },
          top: {
            headerTitle: 'TOP',
            render: ({ top }) => (
              <View style={styles.buttonContainer}>
                {top ? (
                  <Icon name="check_circle" size="small" color={PRIMARY} />
                ) : (
                  <Text size="small" weight="reg">
                    —
                  </Text>
                )}
              </View>
            ),
          },
          actions: {
            render: (order) => (
              <View style={styles.actionTableWrapper}>
                <Icon
                  size="small"
                  name="description"
                  color={GRAY}
                  hoverColor={PRIMARY}
                  onPress={() => onDetail(order)}
                />
              </View>
            ),
            noHeaderName: true,
          },
        }}
        loadMore={({ first, skip }) => {
          fetchMoreItems(fetchMore, {
            query:
              dataKey === 'orders' ? GET_COMPLETED_ORDER_LIST : GET_CLOSINGS,
            variables: {
              ...variables,
              first,
              skip,
            },
            dataKey,
          });
        }}
        onChangeRowsPerPage={onRowsPerPageChange}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  root: {
    flex: 1,
    paddingVertical: 40,
    paddingHorizontal: 80,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 20,
  },
  bodyWrapper: {
    flex: 1,
    paddingTop: 20,
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionTableWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  column: { paddingVertical: 8 },
  emptyContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 20,
  },
  firstRowText: { letterSpacing: 1.5, paddingBottom: 8 },
});

export const ClosingDayTable = withToast(ClosingDayTableComponent);
