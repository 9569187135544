import { Box } from '@material-ui/core';
import {
  BLUE_SAPPHIRE,
  DAFFODIL_YELLOW,
  MAIZE_YELLOW,
  SAFFRON_ORANGE,
  SHINE_RED,
  VIVID_GREEN,
  VODKA_PURPLE,
} from 'constants/colors';
import { Icon, Text } from 'core-ui';
import React from 'react';

type SentimentLinearBarProps = {
  veryHappy: SentimentItem;
  happy: SentimentItem;
  neutral: SentimentItem;
  sad: SentimentItem;
  activeBarColor?: string;
  unactiveBarColor?: string;
  minValue?: number;
  maxValue?: number;
};

export type SentimentItem = {
  value: number;
  orders: number;
};

export function SentimentLinearBar(props: SentimentLinearBarProps) {
  const {
    activeBarColor,
    unactiveBarColor,
    veryHappy,
    happy,
    neutral,
    sad,
  } = props;

  const _progressBar = (value: number) => {
    const minValue = props.minValue || 0;
    const maxValue = props.maxValue || 100;
    const percent = ((value - minValue) / (maxValue - minValue)) * 100;

    return (
      <Box
        width={'100%'}
        height={'8px'}
        borderRadius={8}
        style={{ backgroundColor: unactiveBarColor || VODKA_PURPLE }}
      >
        <Box
          width={Number.isNaN(percent) ? 0 : percent + '%'}
          height={'8px'}
          borderRadius={8}
          style={{ backgroundColor: activeBarColor || BLUE_SAPPHIRE }}
        ></Box>
      </Box>
    );
  };

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box
          borderRadius={28}
          padding={'4px'}
          style={{ backgroundColor: VIVID_GREEN }}
          mr={2}
        >
          <Icon isCustomSVG name="emoticonVeryHappy" size="medium" />
        </Box>
        <Box display="flex" flexDirection="column" width={'100%'}>
          <Box display="flex" justifyContent="space-between" mb={'4px'}>
            <Box display="flex" alignItems="center">
              <Icon
                isCustomSVG
                name="starFull"
                color={MAIZE_YELLOW}
                size="small"
              />
              <Text weight="bold" size="xsmall">
                4 (Sangat Baik)
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Text weight="bold" size="xsmall">
                {veryHappy.orders} Pesanan
              </Text>
            </Box>
          </Box>
          {_progressBar(veryHappy.value)}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
        <Box
          borderRadius={28}
          padding={'4px'}
          style={{ backgroundColor: DAFFODIL_YELLOW }}
          mr={2}
        >
          <Icon isCustomSVG name="emoticonHappy" size="medium" />
        </Box>
        <Box display="flex" flexDirection="column" width={'100%'}>
          <Box display="flex" justifyContent="space-between" mb={'4px'}>
            <Box display="flex" alignItems="center">
              <Icon
                isCustomSVG
                name="starFull"
                color={MAIZE_YELLOW}
                size="small"
              />
              <Text weight="bold" size="xsmall">
                3 (Baik)
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Text weight="bold" size="xsmall">
                {happy.orders} Pesanan
              </Text>
            </Box>
          </Box>
          {_progressBar(happy.value)}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
        <Box
          borderRadius={28}
          padding={'4px'}
          style={{ backgroundColor: SAFFRON_ORANGE }}
          mr={2}
        >
          <Icon isCustomSVG name="emoticonNeutral" size="medium" />
        </Box>
        <Box display="flex" flexDirection="column" width={'100%'}>
          <Box display="flex" justifyContent="space-between" mb={'4px'}>
            <Box display="flex" alignItems="center">
              <Icon
                isCustomSVG
                name="starFull"
                color={MAIZE_YELLOW}
                size="small"
              />
              <Text weight="bold" size="xsmall">
                2 (Cukup Baik)
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Text weight="bold" size="xsmall">
                {neutral.orders} Pesanan
              </Text>
            </Box>
          </Box>
          {_progressBar(neutral.value)}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" mt={1}>
        <Box
          borderRadius={28}
          padding={'4px'}
          style={{ backgroundColor: SHINE_RED }}
          mr={2}
        >
          <Icon isCustomSVG name="emoticonSad" size="medium" />
        </Box>
        <Box display="flex" flexDirection="column" width={'100%'}>
          <Box display="flex" justifyContent="space-between" mb={'4px'}>
            <Box display="flex" alignItems="center">
              <Icon
                isCustomSVG
                name="starFull"
                color={MAIZE_YELLOW}
                size="small"
              />
              <Text weight="bold" size="xsmall">
                1 (Kurang Baik)
              </Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Text weight="bold" size="xsmall">
                {sad.orders} Pesanan
              </Text>
            </Box>
          </Box>
          {_progressBar(sad.value)}
        </Box>
      </Box>
    </>
  );
}
