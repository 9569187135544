import React from 'react';
import { BLACK } from '../../../constants/colors';
import { Modal, Text } from '../../../core-ui';
import { Coupon } from '../../../graphql/queries';

export function DeleteModal(props: {
  loading: boolean;
  data: Coupon;
  onSubmit: () => void;
  onClose: () => void;
}) {
  const { loading, data, onSubmit, onClose } = props;
  return (
    <Modal
      isVisible
      hideHeaderClose
      title="Hapus Kupon"
      closeButtonText="Tidak"
      buttonText="Hapus"
      submitLoading={loading}
      onSubmit={() => {
        onSubmit();
        onClose();
      }}
      onClose={onClose}
      description={
        <Text size="small">
          Apakah Anda yakin ingin menghapus{' '}
          <Text size="small" weight="bold" color={BLACK}>
            {data.title}
          </Text>{' '}
          dari daftar Kupon?
        </Text>
      }
    />
  );
}
