import { RadioButton, TextField } from 'core-ui';
import { ClaimType } from 'graphql/queries';
import { formatThousandSeparator } from 'helpers';
import { sanitizeNumericInput } from 'helpers/formatNumber';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { AdvanceField } from 'scenes/program/components';

export function ClaimAmount() {
  const { values, onValuesChange } = useFormContext();

  const { claimType, claimLimit } = values;

  // #region Fields update handler
  const onClaimTypeChange = (value: string) => {
    onValuesChange({
      claimType: value as ClaimType,
    });
  };

  const onClaimLimitChange = (value: string) => {
    onValuesChange({
      claimLimit: sanitizeNumericInput(value),
    });
  };
  // #endregion

  return (
    <>
      <AdvanceField label="Jumlah Klaim">
        <View style={styles.flex}>
          <RadioButton<string>
            label="Keseluruhan"
            value="WHOLE"
            selected={claimType === 'WHOLE'}
            onSelect={onClaimTypeChange}
          />
        </View>
        <View style={styles.flex}>
          <RadioButton<string>
            label="Pertoko"
            value="PER_STORE"
            selected={claimType === 'PER_STORE'}
            onSelect={onClaimTypeChange}
          />
        </View>
      </AdvanceField>
      <TextField
        stretch
        labelHorizontal
        label=" "
        placeholder="Jumlah Klaim"
        value={claimLimit ? formatThousandSeparator(Number(claimLimit)) : ''}
        onChangeText={onClaimLimitChange}
      />
    </>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
});
