import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Query } from '../../../components';
import { PRIMARY } from '../../../constants/colors';
import { Accordion, Icon, Loading, Modal, Text } from '../../../core-ui';
import { GET_SEGMENTS_TREE, SegmentTreeList } from '../../../graphql/queries';
import { omit } from '../../../helpers';

type SegmentSelectionModalProps = {
  onClose: () => void;
  onSelect: (
    selectedSegment: string[],
    selectedSubSegments: string[],
    isCancel?: boolean,
  ) => void;
  currentSegments: string[];
  currentSubSegments: string[];
};

export default function SegmentSelectionModal({
  onClose,
  onSelect,
  currentSegments,
  currentSubSegments,
}: SegmentSelectionModalProps) {
  const [selected, setSelected] = useState<{
    [segmentId: string]: string[];
  }>({});

  const handleSelectAll = (segmentId: string, subSegmentsIds: string[]) => {
    const selectedSegment = selected[segmentId];

    if (!selectedSegment) {
      setSelected((prevSelected) => ({
        ...prevSelected,
        [segmentId]: subSegmentsIds,
      }));

      return;
    }

    const alreadyCheckedAll = subSegmentsIds.every((subSegmentId) => {
      return selectedSegment.includes(subSegmentId);
    });

    if (alreadyCheckedAll) {
      setSelected((prevSelected) => omit(prevSelected, [segmentId]));

      return;
    }

    const data = subSegmentsIds.filter(
      (id, index, self) => self.indexOf(id) === index,
    );

    setSelected((prevSelected) => ({
      ...prevSelected,
      [segmentId]: data,
    }));
  };

  const handleSelect = (segmentId: string, subSegmentId: string) => {
    const selectedSegment = selected[segmentId];

    if (!selectedSegment) {
      setSelected((prevSelected) => ({
        ...prevSelected,
        [segmentId]: [subSegmentId],
      }));

      return;
    }

    const alreadyChecked = selectedSegment.includes(subSegmentId);

    if (alreadyChecked) {
      const data = selectedSegment.filter((id) => id !== subSegmentId);

      if (selectedSegment.length === 1) {
        setSelected((prevSelected) => omit(prevSelected, [segmentId]));

        return;
      }

      setSelected((prevSelected) => ({
        ...prevSelected,
        [segmentId]: data,
      }));

      return;
    }

    const data = [...selectedSegment, subSegmentId].filter(
      (id, index, self) => {
        return self.indexOf(id) === index;
      },
    );

    setSelected((prevSelected) => ({
      ...prevSelected,
      [segmentId]: data,
    }));
  };

  const onSubmit = () => {
    onSelect(Object.keys(selected), Object.values(selected).flat());
    onClose();
  };

  const onModalClose = () => {
    setSelected({});
    onSelect(currentSegments, currentSubSegments, true);
    onClose();
  };

  return (
    <Modal
      title="Pilih Segmentasi"
      description="Tentukan segmentasi yang dapat mendapatkan header berikut"
      isVisible
      onClose={onModalClose}
      onSubmit={onSubmit}
      buttonText="Pilih"
      closeButtonText="Batal"
      maxHeight
    >
      <Query<SegmentTreeList>
        query={GET_SEGMENTS_TREE}
        keyData="segments"
        fetchPolicy="no-cache"
        onCompleted={({ segments }) => {
          const selectedSegment = segments.filter((segment) =>
            segment.subSegments.some((subSegment) =>
              currentSubSegments.includes(subSegment.id),
            ),
          );

          const selectedData = selectedSegment.reduce((prev, curr) => {
            const subSegments = curr.subSegments.filter((subSegment) =>
              currentSubSegments.includes(subSegment.id),
            );

            return {
              ...prev,
              [curr.id]: subSegments.map((subSegment) => subSegment.id),
            };
          }, {});

          setSelected(selectedData);
        }}
      >
        {({ data, loading }) =>
          loading ? (
            <Loading />
          ) : (
            <Accordion
              items={[...(data?.segments || [])].map((segment) => ({
                id: segment.id,
                title: segment.title,
                headerLeft: (
                  <Checkbox
                    style={{ padding: 0, marginRight: 10 }}
                    indeterminate={
                      selected[segment.id]
                        ? !segment.subSegments.every((subSegment) => {
                            const selectedSegment = selected[segment.id];
                            return selectedSegment.includes(subSegment.id);
                          })
                        : false
                    }
                    checked={
                      selected[segment.id]
                        ? segment.subSegments.every((subSegment) => {
                            const selectedSegment = selected[segment.id];
                            return selectedSegment.includes(subSegment.id);
                          })
                        : false
                    }
                    icon={
                      <Icon
                        name="check_box_outline_blank"
                        color="inherit"
                        size="small"
                      />
                    }
                    checkedIcon={
                      <Icon name="check_box" color={PRIMARY} size="small" />
                    }
                    indeterminateIcon={
                      <Icon
                        name="indeterminate_check_box"
                        color={PRIMARY}
                        size="small"
                      />
                    }
                    onChange={() =>
                      handleSelectAll(
                        segment.id,
                        segment.subSegments.map((subsegment) => subsegment.id),
                      )
                    }
                  />
                ),
                children: segment.subSegments.map((subSegment) => (
                  <View style={[styles.subSegmentCheckbox]} key={subSegment.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ padding: 0, marginRight: 10 }}
                          checked={
                            selected[segment.id]
                              ? selected[segment.id].includes(subSegment.id)
                              : false
                          }
                          icon={
                            <Icon
                              name="check_box_outline_blank"
                              color="inherit"
                              size="small"
                            />
                          }
                          checkedIcon={
                            <Icon
                              name="check_box"
                              color={PRIMARY}
                              size="small"
                            />
                          }
                          onChange={() =>
                            handleSelect(segment.id, subSegment.id)
                          }
                        />
                      }
                      label={<Text>{subSegment.title.toUpperCase()}</Text>}
                    />
                  </View>
                )),
              }))}
            />
          )
        }
      </Query>
    </Modal>
  );
}

const styles = StyleSheet.create({
  subSegmentCheckbox: {
    marginBottom: 8,
    paddingHorizontal: 20,
  },
});
