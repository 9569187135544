import { BLACK, DARK_GRAY } from 'constants/colors';
import { Modal, Text } from 'core-ui';
import { DELETE_PROGRAM } from 'graphql/mutations';
import { Program } from 'graphql/queries';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType } from 'react';
import { Mutation } from 'react-apollo';

type DeleteConfirmModalOwnProps = {
  isVisible: boolean;
  onClose: () => void;
  refetch: () => void;
  data: Program | null;
};
export type DeleteConfirmModalProps = ToastContextProps &
  DeleteConfirmModalOwnProps;

function DeleteConfirmModalComponent({
  isVisible,
  onClose,
  openToast,
  refetch,
  data,
}: DeleteConfirmModalProps) {
  const onDeleteError = () => {
    openToast('fail', 'Program gagal dihapus.');
  };

  return (
    <Mutation<any>
      mutation={DELETE_PROGRAM}
      onCompleted={() => {
        openToast('success', 'Program telah dihapus.');
        refetch();
        onClose();
      }}
      onError={onDeleteError}
    >
      {(deleteProgram, { loading }) => (
        <Modal
          hideHeaderClose
          isVisible={isVisible}
          closeButtonText="Tidak"
          title="Hapus Program"
          buttonText="Hapus"
          submitLoading={loading}
          description={
            <Text size="small" color={DARK_GRAY}>
              Apakah Anda yakin ingin menghapus{' '}
              <Text size="small" weight="bold" color={BLACK}>
                {data && data.title}
              </Text>{' '}
              dari daftar program?
            </Text>
          }
          onClose={onClose}
          onSubmit={() => {
            if (data) {
              deleteProgram({ variables: { id: data.id } });
            }
          }}
        />
      )}
    </Mutation>
  );
}

export const DeleteConfirmModal = withToast(
  DeleteConfirmModalComponent,
) as ComponentType<DeleteConfirmModalOwnProps>;
