import React, { Component } from 'react';
import { View } from 'react-native';
import { CurrencyField, IncrementField } from '../../../core-ui';

type InputFieldProps = {
  initialValue?: number;
  selected: boolean;
  type: 'currency' | 'increment';
  minValue?: number;
  maxValue?: number;
  onUpdate: (newValue: number) => void;
};

type InputFieldState = {
  value: number;
};

export default class InputField extends Component<
  InputFieldProps,
  InputFieldState
> {
  state = {
    value: this.props.initialValue || 0,
  };

  render() {
    const { type, selected, minValue = 0, maxValue } = this.props;
    const { value } = this.state;
    return type === 'currency' ? (
      <View style={{ width: 120 }}>
        <CurrencyField
          stretch
          currencyValue={selected && value ? value : 0}
          editable={selected}
          onCurrencyChange={this._onChangeValue}
          onBlur={this._onBlur}
        />
      </View>
    ) : (
      <View style={{ width: 75 }}>
        <IncrementField
          stretch
          value={selected && value ? value : 0}
          editable={selected}
          onChangeValue={this._onChangeValue}
          onButtonPress={(newValue) => {
            if (newValue <= 0) return;
            this.setState({ value: newValue }, () => this._onBlur());
          }}
          onBlur={this._onBlur}
          minValue={minValue}
          maxValue={maxValue}
        />
      </View>
    );
  }

  _onChangeValue = (value: number) => {
    if (value <= 0) return;
    this.setState({ value });
  };

  _onBlur = () => {
    const { value } = this.state;
    const { onUpdate } = this.props;
    onUpdate && onUpdate(value);
  };
}
