import { format } from 'date-fns';
import id from 'date-fns/locale/id';
import { useMemo } from 'react';
import { FilterValues } from '../context';

export function useDateValues({
  startDate,
  endDate,
  periodTime,
}: Pick<FilterValues, 'startDate' | 'endDate' | 'periodTime'>) {
  const dateValues = useMemo(() => {
    if (!startDate || !endDate) {
      return '';
    }

    if (startDate.getTime() === endDate.getTime() && periodTime === 'monthly') {
      return format(startDate, 'MMMM yyyy', { locale: id });
    } else if (startDate.getTime() === endDate.getTime()) {
      return format(startDate, 'dd MMMM yyyy', { locale: id });
    }

    if (startDate.getMonth() === endDate.getMonth()) {
      return (
        format(startDate, 'dd', { locale: id }) +
        ' - ' +
        format(endDate, 'dd MMMM yyyy', { locale: id })
      );
    }

    if (periodTime === 'monthly') {
      return `${format(startDate, 'MMMM yyyy', {
        locale: id,
      })} - ${format(endDate, 'MMMM yyyy', { locale: id })}`;
    }

    return (
      format(startDate, 'dd MMMM yyyy', { locale: id }) +
      (endDate ? ` - ${format(endDate, 'dd MMMM yyyy', { locale: id })}` : '')
    );
  }, [startDate, endDate, periodTime]);

  return dateValues;
}
