import { PaginationState } from '@tanstack/react-table';
import { GREEN_SOFT, RED_SOFT } from 'constants/colors';
import { differenceInDays, format } from 'date-fns';
import {
  GET_MONITOR_PROGRAMS,
  GetMonitorProgramListResult,
  GetMonitorProgramListVariables,
  MonitorProgram,
  ProgramType,
} from 'graphql/queries';
import { formatShortMonth } from 'helpers/formatDate';
import { formatDecimal, formatThousandSeparator } from 'helpers/formatNumber';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { getProgramTypeString } from 'scenes/program/helpers/getProgramString';
import { ColumnType, Query, ReactTable } from '../../../components';
import { Text } from '../../../core-ui';
import { LinearProgressbar } from '../components/LinearProgressbar';

type Props = {
  segment: string;
  search: string;
  pagination: PaginationState;
  setPagination: Dispatch<SetStateAction<PaginationState>>;
  rowSelection?: Record<string, boolean>;
  setRowSelection?: Dispatch<SetStateAction<Record<string, boolean>>>;
};

export default function MonitorProgramList({
  pagination,
  setPagination,
  search,
  segment,
}: Props) {
  const cols: ColumnType<MonitorProgram>[] = useMemo(
    () => [
      {
        accessorKey: 'title',
        header: 'Nama Program',
        size: 200,
      },
      {
        accessorKey: 'programType',
        header: 'Jenis Program',
        cell: ({ getValue }) => getProgramTypeString(getValue<ProgramType>()),
      },
      {
        accessorKey: 'segment',
        header: 'Segmentasi',
        size: 250,
        cell: ({ getValue }) => {
          const segments = getValue<string>()
            .toString()
            .split(',');
          return <Text size="small">- {segments.join('\n- ')}</Text>;
        },
      },
      {
        accessorKey: 'sku',
        header: 'SKU',
        size: 350,
        cell: ({ getValue }) => {
          const skus = getValue<string>()
            .toString()
            .split(',');
          return <Text size="small">- {skus.join('\n- ')}</Text>;
        },
      },
      {
        accessorKey: 'maxQty',
        header: 'Kuota/Target',
        size: 100,
        cell: ({ row }) => {
          return (
            <Text
              size="xsmall"
              color={row.original.maxQty < 0 ? RED_SOFT : 'black'}
            >
              {formatThousandSeparator(row.original.maxQty)}
            </Text>
          );
        },
      },
      {
        accessorKey: 'programClaimCount',
        header: 'Realisasi',
        size: 100,
        cell: ({ row }) => {
          const programClaimCount =
            row.original.programClaimCount < 0
              ? 0
              : row.original.programClaimCount;

          return (
            <Text
              size="xsmall"
              color={programClaimCount < 0 ? RED_SOFT : 'black'}
            >
              {programClaimCount}
            </Text>
          );
        },
      },
      {
        accessorKey: 'achievement',
        header: 'Achievement (%)',
        cell: ({ getValue, row }) => {
          const value = getValue<number>() > 0 ? getValue<number>() : 0;
          const achievement = Number(formatDecimal(value * 100, 1));
          const timegone = calculateTimeGone(
            row.original.startDate,
            row.original.endDate,
          ).percentage;

          return (
            <LinearProgressbar
              withText
              value={achievement}
              color={achievement < timegone ? RED_SOFT : GREEN_SOFT}
            />
          );
        },
      },
      {
        accessorKey: 'gap',
        header: 'Gap',
        size: 100,
        cell: ({ row }) => {
          return (
            <Text
              size="xsmall"
              color={row.original.gap < 0 ? RED_SOFT : 'black'}
            >
              {formatThousandSeparator(row.original.gap)}
            </Text>
          );
        },
      },
      {
        id: 'validDate',
        header: 'Periode',
        size: 250,
        cell: ({ row }) => {
          const { startDate, endDate } = row.original;
          return `${formatShortMonth(startDate)} - ${formatShortMonth(
            endDate,
          )}`;
        },
      },
      {
        accessorKey: 'timeGone',
        header: 'Timegone (%)',
        cell: ({ row }) => {
          const startDate = new Date(row.original.startDate!);
          const endDate = new Date(row.original.endDate!);
          const today = new Date();

          const intervalFromStart = differenceInDays(today, startDate);
          const intervalToEnd = differenceInDays(endDate, startDate);

          const percentage = Math.floor(
            (intervalFromStart / intervalToEnd) * 100,
          );
          const timeGone = isNaN(percentage)
            ? 0
            : percentage > 100
            ? 100
            : percentage;

          return <LinearProgressbar value={Math.floor(timeGone)} withText />;
        },
      },
      {
        id: 'action',
        cell: ({ row }) => (
          <Link to={`/monitoring-program/depo/${row.original.id}`}>
            Lihat Detail
          </Link>
        ),
      },
    ],
    [],
  );

  const calculateTimeGone = useCallback(
    (start: Nullable<string>, end: Nullable<string>) => {
      const startDate = new Date(start!);
      const endDate = new Date(end!);
      const today = new Date();

      const intervalFromStart = differenceInDays(today, startDate);
      const intervalToEnd = differenceInDays(endDate, startDate);

      const diffOfDays = intervalToEnd - intervalFromStart;
      const currentRemainingDays = diffOfDays > 0 ? diffOfDays : 0;
      const formattedStartDate = format(startDate, 'dd/MM/yyyy');
      const formattedEndDate = format(endDate, 'dd/MM/yyyy');
      const runningDays = `${
        intervalFromStart > intervalToEnd ? intervalToEnd : intervalFromStart
      }/${intervalToEnd}`;
      const percentage = Math.floor((intervalFromStart / intervalToEnd) * 100);

      return {
        currentRemainingDays,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        runningDays,
        percentage: percentage > 100 ? 100 : percentage,
      };
    },
    [],
  );

  return (
    <Query<GetMonitorProgramListResult, GetMonitorProgramListVariables>
      query={GET_MONITOR_PROGRAMS}
      variables={{
        first: pagination.pageSize,
        skip: pagination.pageIndex * pagination.pageSize,
        segment,
        title: search,
      }}
      fetchPolicy="no-cache"
      keyData="monitorProgram"
      notifyOnNetworkStatusChange
      disableLoading
    >
      {({ loading, data }) => {
        return (
          <ReactTable
            isLoading={loading}
            data={data?.monitorProgramsList || []}
            columns={cols}
            total={data?.count || 0}
            pagination={pagination}
            setPagination={setPagination}
          />
        );
      }}
    </Query>
  );
}
