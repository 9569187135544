export const YEARS = Array.from(
  { length: 101 },
  (_, i) => new Date().getFullYear() - i,
);

export const MONTHS = [
  'Januari',
  'Februari',
  'Maret',
  'April',
  'Mei',
  'Juni',
  'Juli',
  'Agustus',
  'September',
  'Oktober',
  'November',
  'Desember',
];
