import { Box, Button, withStyles } from '@material-ui/core';
import { PaperBox } from 'components';
import { GRAY5 } from 'constants/colors';
import { FONT_SIZE } from 'constants/textPresets';
import { Button as TVIPButton, Text } from 'core-ui';
import { pick } from 'helpers';
import { useToggle } from 'hooks';
import React, { useMemo, useState } from 'react';
import { FilterProvider, FilterValues } from '../context';
import { useDateValues } from '../hooks';
import { DatePickerModal } from './DatePickerModal';
import { DepotSelection } from './DepotSelection';
import { ProductSelection } from './ProductSelection';
import { SegmentSelection } from './SegmentSelection';

export type FilterProps = {
  withSKUSelection?: boolean;
  withSegmentationSelection?: boolean;
  dailyOnly?: boolean;
  onFilter: (values: FilterValues) => void;
};

const DateSelectionButton = withStyles({
  startIcon: {
    margin: '0',
    backgroundColor: GRAY5,
    padding: '15px 6px',
    alignSelf: 'stretch',
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    justifyContent: 'space-between',
    padding: 0,
    minHeight: 50,
  },
})(Button);

export const DEFAULT_FILTER_STATE: FilterValues = {
  startDate: null,
  endDate: null,
  periodTime: 'daily',
  products: [],
  depot: 'all',
  segmentation: 'all',
};

export const MAP_PERIOD_TIME = {
  daily: 'Per Hari',
  weekly: 'Per Minggu',
  monthly: 'Per Bulan',
};

export function Filter({
  withSegmentationSelection,
  withSKUSelection,
  onFilter,
  dailyOnly,
}: FilterProps) {
  const [filter, setFilter] = useState<FilterValues>(DEFAULT_FILTER_STATE);
  const [isDatePickerOpen, toggleDatePicker] = useToggle();

  const calculateGridTemplate = useMemo(() => {
    if (withSegmentationSelection && !withSKUSelection) {
      return {
        columns: 'repeat(3, 1fr)',
        rows: '1fr',
      };
    }

    if (withSegmentationSelection && withSKUSelection) {
      return {
        columns: 'repeat(2, 1fr)',
        rows: 'repeat(2, 1fr)',
      };
    }

    return {
      columns: 'repeat(2, 1fr)',
      rows: '1fr',
    };
  }, [withSKUSelection, withSegmentationSelection]);

  const dateValues = useDateValues(
    pick(filter, ['startDate', 'endDate', 'periodTime']),
  );

  const isOneRow = calculateGridTemplate.rows === '1fr';

  const onFilterChange = (values: Partial<FilterValues>) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...values,
    }));
  };

  const onSubmit = () => {
    onFilter({
      ...filter,
      depot: filter.depot === 'all' ? null : filter.depot,
      segmentation: filter.segmentation === 'all' ? null : filter.segmentation,
    });
  };

  return (
    <FilterProvider values={filter} setValues={onFilterChange}>
      <PaperBox
        padding={4}
        display="flex"
        flexDirection="column"
        alignItems="end"
        style={{
          gap: '1rem',
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns={calculateGridTemplate.columns}
          gridTemplateRows={calculateGridTemplate.rows}
          gridGap="1rem"
          width="100%"
        >
          <Box
            display="grid"
            alignItems="center"
            gridTemplateColumns={
              isOneRow ? 'fit-content(150px) 1fr' : '1fr 3fr'
            }
            style={{
              gap: 12,
            }}
          >
            <Text>Periode Waktu</Text>
            <DateSelectionButton
              startIcon={
                <Text size="xsmall">{MAP_PERIOD_TIME[filter.periodTime]}</Text>
              }
              variant="outlined"
              onClick={toggleDatePicker}
            >
              <Box
                flex={1}
                p="15px"
                style={{
                  fontFamily: 'Rubik-Regular',
                  fontSize: FONT_SIZE.small,
                  whiteSpace: 'nowrap',
                  overflowX: 'scroll',
                  textAlign: 'left',
                }}
              >
                {dateValues}
              </Box>
            </DateSelectionButton>
          </Box>
          {withSKUSelection && (
            <Box
              display="grid"
              gridTemplateColumns={
                isOneRow ? 'fit-content(150px) 1fr' : '1fr 3fr'
              }
              alignItems="center"
              style={{
                gap: 12,
              }}
            >
              <Text>SKU</Text>
              <ProductSelection />
            </Box>
          )}
          <Box
            display="grid"
            alignItems="center"
            gridTemplateColumns={
              isOneRow ? 'fit-content(150px) 1fr' : '1fr 3fr'
            }
            style={{
              gap: 12,
            }}
          >
            <Text>Depo</Text>
            <DepotSelection />
          </Box>
          {withSegmentationSelection && (
            <Box
              display="grid"
              gridTemplateColumns={
                isOneRow ? 'fit-content(150px) 1fr' : '1fr 3fr'
              }
              alignItems="center"
              style={{
                gap: 12,
              }}
            >
              <Text>Segmentasi</Text>
              <SegmentSelection />
            </Box>
          )}
        </Box>
        <TVIPButton color="primary" onPress={onSubmit}>
          Filter Data
        </TVIPButton>
      </PaperBox>
      <DatePickerModal
        dailyOnly={dailyOnly}
        isOpen={isDatePickerOpen}
        onClose={toggleDatePicker}
      />
    </FilterProvider>
  );
}
