import { Box } from '@material-ui/core';
import { MAIZE_YELLOW } from 'constants/colors';
import { Icon } from 'core-ui';
import React from 'react';

type Props = {
  rating: number;
};

export function Rating(props: Props) {
  const { rating } = props;

  const elements = [];
  for (let i = 0; i < 4; i++) {
    if (i < Math.floor(rating)) {
      elements.push(
        <Icon isCustomSVG name="starFull" color={MAIZE_YELLOW} size="medium" />,
      );
    } else if (
      rating % (Math.floor(rating) === 0 ? 1 : Math.floor(rating)) > 0.0 &&
      i === Math.floor(rating)
    ) {
      elements.push(
        <Icon isCustomSVG name="starHalf" color={MAIZE_YELLOW} size="medium" />,
      );
    } else {
      elements.push(
        <Icon
          isCustomSVG
          name="starEmpty"
          color={MAIZE_YELLOW}
          size="medium"
        />,
      );
    }
  }
  return <Box display="flex">{elements}</Box>;
}
