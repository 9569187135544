import React, { useState } from 'react';
import { BLACK, DARK_GRAY } from '../../../constants/colors';
import { Modal, Text, TextField } from '../../../core-ui';
import { User } from '../../../graphql/queries';
import { convertPascalCase } from '../../../helpers';

type SuspendModalProps = {
  loading: boolean;
  data: Array<User>;
  suspend: (user: User, suspendReason: string) => () => void;
  unsuspend: (user: User) => () => void;
  actionDataID: Nullable<string>;
  isSuspendVisible: boolean;
  closeSuspend: () => void;
};

export function SuspendModal({
  loading,
  data,
  suspend,
  unsuspend,
  actionDataID,
  isSuspendVisible,
  closeSuspend,
}: SuspendModalProps) {
  const [suspendReason, setSuspendReason] = useState('');
  const currentData = data.find(({ id }) => id === actionDataID);
  if (currentData) {
    const { suspended } = currentData;
    return !suspended ? (
      <Modal
        hideHeaderClose
        isVisible={isSuspendVisible}
        title="Suspend Pelanggan"
        closeButtonText="Tidak"
        buttonText="Suspend"
        submitLoading={loading}
        onSubmit={suspend(currentData, suspendReason)}
        description={
          <Text size="small" color={DARK_GRAY}>
            Apakah Anda yakin ingin suspend{' '}
            <Text size="small" weight="bold" color={BLACK}>
              {convertPascalCase(currentData.name)}, ID {currentData.szID}
            </Text>{' '}
            ?
          </Text>
        }
        onClose={closeSuspend}
      >
        <TextField
          stretch
          multiline
          placeholder="Alasan Suspend"
          label="Alasan Suspend"
          value={suspendReason}
          onChangeText={setSuspendReason}
        />
      </Modal>
    ) : (
      <Modal
        hideHeaderClose
        isVisible={isSuspendVisible}
        title="Unsuspend Pelanggan"
        closeButtonText="Tidak"
        buttonText="Unsuspend"
        submitLoading={loading}
        onSubmit={unsuspend(currentData)}
        description={
          <Text size="small" color={DARK_GRAY}>
            Apakah Anda yakin ingin unsuspend{' '}
            <Text size="small" weight="bold" color={BLACK}>
              {convertPascalCase(currentData.storeName)}, ID {currentData.id}
            </Text>{' '}
            ?
          </Text>
        }
        onClose={closeSuspend}
      />
    );
  }

  return null;
}
