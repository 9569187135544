import { BarDatum, BarSvgProps, ResponsiveBar } from '@nivo/bar';
import React from 'react';

export type BarChartProps<Datum extends BarDatum> = Omit<
  BarSvgProps<Datum>,
  'height' | 'width'
>;

export function BarChart<Datum extends BarDatum>(props: BarChartProps<Datum>) {
  return <ResponsiveBar {...props} />;
}
