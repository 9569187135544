import React, { ReactNode } from 'react';
import { StyleSheet, View, Image } from 'react-native';
import { DARK_GRAY } from '../../../constants/colors';
import { Text, Modal } from '../../../core-ui';
import { Coupon } from '../../../graphql/queries';
import {
  convertPascalCase,
  formatFullDate,
  formatThousandSeparator,
} from '../../../helpers';

type Detail = { [key in keyof Coupon]?: string };

const DETAIL_DATA: Detail = {
  title: 'Nama Kupon',
  couponType: 'Jenis Kupon',
  couponRewardValue: 'Jumlah Cashback',
  couponLimit: 'Jumlah Kupon',
  couponPrice: 'Harga Poin/Kupon',
  startDate: 'Masa Berlaku Kupon',
  active: 'Status Kupon',
  description: 'Deskripsi Kupon',
  couponImage: 'Foto Kupon',
};

export function DetailModal(props: { data: Coupon; onClose: () => void }) {
  const { data, onClose } = props;

  const content: Array<ReactNode> = Object.keys(DETAIL_DATA)
    .map((key) => {
      if (!key) {
        return undefined;
      }

      const dataKey = key as keyof typeof DETAIL_DATA;
      const value = (() => {
        switch (dataKey) {
          case 'couponRewardValue':
            return `Rp. ${formatThousandSeparator(data.couponRewardValue)}`;
          case 'couponLimit':
            return `${data.couponLimit} Kupon`;
          case 'couponPrice':
            return `${data.couponPrice} Poin`;
          case 'startDate':
            return `${formatFullDate(
              new Date(data.startDate),
            )} - ${formatFullDate(new Date(data.endDate))}`;
          case 'active':
            return data.active ? 'Aktif' : 'Tidak Aktif';
          case 'title':
          case 'couponType':
            return convertPascalCase(data[dataKey]);
          case 'couponImage': {
            return (
              <Image
                resizeMethod="auto"
                resizeMode="contain"
                source={{ uri: data.couponImage }}
                style={styles.productImage}
              />
            );
          }
          default:
            return data[dataKey];
        }
      })();

      return (
        <View key={key} style={styles.dataRow}>
          <View style={[styles.dataWrapper, styles.flex]}>
            <Text size="xsmall" color={DARK_GRAY}>
              {DETAIL_DATA[dataKey]}
            </Text>
          </View>
          <View style={[styles.dataWrapper, styles.colonSeparator]}>
            <Text size="xsmall" color={DARK_GRAY}>
              :
            </Text>
          </View>
          <View style={[styles.dataWrapper, styles.valueColumn]}>
            <Text>{value}</Text>
          </View>
        </View>
      );
    })
    .filter((item) => item !== undefined);

  return (
    <Modal isVisible title="Informasi Kupon" onClose={onClose}>
      {content}
    </Modal>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  dataRow: { flexDirection: 'row', alignItems: 'flex-start', paddingTop: 20 },
  dataWrapper: { minHeight: 22, justifyContent: 'center' },
  valueColumn: { flex: 2 },
  colonSeparator: { paddingHorizontal: 20 },
  productImage: {
    width: 100,
    height: 100,
  },
});
