import React, { Component } from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';
import { graphql } from 'react-apollo';

import {
  GET_SEGMENTS_TREE,
  SegmentTreeListData,
} from '../../../graphql/queries';

import { ChosenProps, Picker, Option } from '../../../components';

type SegmentTreeListProps = { segmentQuery: SegmentTreeListData };
type OwnProps = {
  isFilter?: boolean;
  selectedSegment: Nullable<string>;
  onSegmentChange?: (option: Nullable<Option>) => void;
  containerStyle?: StyleProp<ViewStyle>;
} & Pick<ChosenProps, 'style'>;
type Props = SegmentTreeListProps & OwnProps;

type State = {
  segmentID: Nullable<string>;
};

export class SegmentDropdown extends Component<Props, State> {
  state: State = { segmentID: null };

  render() {
    const {
      segmentQuery,
      style,
      selectedSegment,
      onSegmentChange,
      containerStyle,
      ...otherProps
    } = this.props;

    let segmentOptions: Array<Option> = [];

    if (segmentQuery.segments) {
      segmentOptions = segmentQuery.segments.map(({ id, title }) => ({
        value: id,
        label: title,
      }));
    }

    return (
      <View
        style={[{ display: 'flex', flexDirection: 'column' }, containerStyle]}
      >
        <Picker
          label="Segmentasi"
          isFilter={true}
          placeholder="Segmentasi"
          style={[{ zIndex: 2 }, style]}
          selectedOption={selectedSegment}
          options={segmentOptions}
          onChange={(selected) => {
            this.setState({
              segmentID: selected ? selected.value : selected,
            });
            onSegmentChange && onSegmentChange(selected);
          }}
          {...otherProps}
        />
      </View>
    );
  }
}

export default graphql<OwnProps, SegmentTreeListData, {}, SegmentTreeListProps>(
  GET_SEGMENTS_TREE,
  { name: 'segmentQuery' },
)(SegmentDropdown);
