import { MayHaveLabel, PieSvgProps, ResponsivePie } from '@nivo/pie';
import React from 'react';

export type PieChartProps<Datum extends MayHaveLabel> = Omit<
  PieSvgProps<Datum>,
  'height' | 'width'
>;

export function PieChart<Datum extends MayHaveLabel>(
  props: PieChartProps<Datum>,
) {
  return (
    <ResponsivePie
      enableArcLabels={false}
      enableArcLinkLabels={false}
      colors={{
        datum: 'data.color',
      }}
      {...props}
    />
  );
}
