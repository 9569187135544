import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Query } from 'components';
import { GET_SEGMENTS, Segment, SegmentList } from 'graphql/queries';
import React from 'react';
import { useFilterContext } from '../context';

const ALL_SEGMENTATION = {
  id: 'all',
  segmentID: '',
  title: 'SEMUA SEGMENTASI',
};

export function SegmentSelection() {
  const { values, setValues } = useFilterContext();

  const onChange = (_: React.ChangeEvent<{}>, value: Nullable<Segment>) => {
    setValues({
      segmentation: value?.id,
    });
  };

  const segmentationValue = (segmentations: Segment[]) => {
    if (!values.segmentation) return undefined;

    return values.segmentation === 'all'
      ? ALL_SEGMENTATION
      : segmentations.find((d) => d.id === values.segmentation);
  };

  return (
    <Query<SegmentList> query={GET_SEGMENTS} disableLoading>
      {({ data, loading }) => (
        <Autocomplete<Segment, false, true>
          onChange={onChange}
          getOptionLabel={(option) => option.title}
          renderInput={({ InputLabelProps, ...props }) => (
            <TextField variant="outlined" {...props} />
          )}
          options={[ALL_SEGMENTATION, ...(data?.segments || [])]}
          loading={loading}
          value={segmentationValue(data?.segments || [])}
          disableClearable
        />
      )}
    </Query>
  );
}
