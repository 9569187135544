import { Box, Grid } from '@material-ui/core';
import { PaginationState } from '@tanstack/react-table';
import {
  ColumnType,
  DepotPicker,
  Query,
  ReactTable,
  ResetFilterButton,
  SubDistrictPicker,
} from 'components';
import { GRAY, PRIMARY } from 'constants/colors';
import { Icon } from 'core-ui';
import {
  AccessProps,
  GET_GALLON_GUARANTEE,
  GetOrderListResult,
  Order,
} from 'graphql/queries';
import { asyncStorage, convertDepoToPostgresqlID } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { OrderDetailModal } from 'scenes/order/components';
import { useSearch } from '../../contexts';
import { gallonGuaranteeListColumns } from '../columns';

export type GallonGuaranteeListProps = AccessProps;

export function GallonGuaranteeList({ access }: GallonGuaranteeListProps) {
  const [selectedDepo, setSelectedDepo] = useState('');
  const [selectedSubDistrict, setSelectedSubDistrict] = useState('');
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [idPostresqlDepo, setIdPostresqlDepo] = useState('');
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [actionData, setActionData] = useState<Order | null>(null);

  const searchContent = useSearch();

  const columns = useMemo<ColumnType<Order>[]>(
    () => [
      ...gallonGuaranteeListColumns,
      {
        id: 'actions',
        size: 20,
        cell: ({ row }) => {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                gap: 4,
              }}
            >
              <Icon
                size="small"
                name="description"
                color={GRAY}
                hoverColor={PRIMARY}
                onPress={() => {
                  setIsDetailVisible(true);
                  setActionData(row.original);
                }}
              />
            </Box>
          );
        },
      },
    ],
    [],
  );

  const queryVariables = useMemo(() => {
    return {
      where: {
        status_not: 'WAITING',
        bottleGuarantee_some: { id_not: null },
        depot: {
          id_contains: selectedDepo || idPostresqlDepo || '',
          coverage_some: {
            subDistrict: {
              id_contains: selectedSubDistrict || '',
            },
          },
        },
        transactionItems_some: {
          products_some: {
            productData: {
              uom: 'GALON',
            },
          },
        },
        OR: [
          {
            invoiceNumber_contains: searchContent,
          },
          {
            poNumber_contains: searchContent,
          },
          {
            user: {
              storeName_contains: searchContent,
            },
          },
          {
            depot: {
              title_contains: searchContent,
            },
          },
          {
            depot: {
              coverage_some: {
                subDistrict: {
                  name_contains: searchContent,
                },
              },
            },
          },
        ],
      },
    };
  }, [selectedDepo, selectedSubDistrict, searchContent, idPostresqlDepo]);

  const clearFilter = () => {
    setSelectedDepo('');
    setSelectedSubDistrict('');
  };

  useEffect(() => {
    (async () => {
      const nameUserDepo = await asyncStorage.getName();

      try {
        const idPostresqlDepo = convertDepoToPostgresqlID(String(nameUserDepo));

        setIdPostresqlDepo(idPostresqlDepo);
      } catch (error) {
        setIdPostresqlDepo('');
      }
    })();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="20px 0"
      >
        <Grid container spacing={2} alignItems="center" style={{ zIndex: 2 }}>
          <Grid item>
            <DepotPicker
              isFilter
              selectedOption={selectedDepo}
              onChange={(selected) =>
                setSelectedDepo(selected ? selected.value : '')
              }
              hidden={access.role !== 'SUPER_ADMIN'}
            />
          </Grid>
          <Grid item>
            <SubDistrictPicker
              isFilter
              selectedOption={selectedSubDistrict}
              onChange={(selected) =>
                setSelectedSubDistrict(selected ? selected.value : '')
              }
            />
          </Grid>
        </Grid>
        <ResetFilterButton onPress={clearFilter} />
      </Box>
      <Query<GetOrderListResult>
        query={GET_GALLON_GUARANTEE}
        keyData="orders"
        variables={{
          ...queryVariables,
          first: pagination.pageSize,
          skip: pagination.pageSize * pagination.pageIndex,
        }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        disableLoading
      >
        {({ data, loading }) => (
          <>
            <ReactTable
              columns={columns}
              data={data?.orders ?? []}
              total={data?.count ?? 0}
              pagination={pagination}
              setPagination={setPagination}
              isLoading={loading}
            />
            {actionData ? (
              <OrderDetailModal
                isVisible={isDetailVisible}
                order={actionData}
                onClose={() => setIsDetailVisible(false)}
              />
            ) : null}
          </>
        )}
      </Query>
    </Box>
  );
}
