import gql from 'graphql-tag';
import { Segment, SegmentTree } from './segmentQuery';
import { SubSegment } from './subSegmentQuery';

export interface HeaderHighlight {
  id: string;
  title: string;
  description: string;
  headerImage: string;
  order: number;
  segments: Array<SegmentTree>;
  subSegments: Array<SubSegment>;
}

export type GetHeaderHighlights = {
  headers: Array<HeaderHighlight>;
  count: number;
};

export type GetHeaderHighlight = {
  header: HeaderHighlight;
};

export const GET_HEADER_HIGHLIGHTS = gql`
  query getHeaderHighlights(
    $skip: Int
    $first: Int
    $where: HeaderWhereInput
    $order: HeaderOrderByInput
  ) {
    count: dataCount(node: HEADER, headerWhere: $where)
    headers(where: $where, skip: $skip, first: $first, order: $order) {
      id
      title
      description
      headerImage
      order
      segments {
        id
        title
        subSegments {
          id
          title
        }
      }
    }
  }
`;

export const GET_HEADER_HIGHLIGHT = gql`
  query getHeaderHighlight($id: ID!) {
    header(id: $id) {
      id
      title
      description
      headerImage
      order
      segments {
        id
      }
      subSegments {
        id
      }
    }
  }
`;
