import { Box, Grid } from '@material-ui/core';
import { PaginationState } from '@tanstack/react-table';
import { FetchMoreOptions } from 'apollo-client';
import {
  ColumnType,
  DepotPicker,
  Query,
  ReactTable,
  ResetFilterButton,
  SubDistrictPicker,
} from 'components';
import { GRAY, PRIMARY } from 'constants/colors';
import { Button, DatePickerField, Icon } from 'core-ui';
import {
  AccessProps,
  GET_NEW_ORDER_NOTIFICATION,
  GET_NEW_REJECTED_ORDER_NOTIFICATION,
  GET_ORDER_LIST,
  GetOrderListResult,
  GetOrderListVariables,
  Order,
} from 'graphql/queries';
import { asyncStorage, convertDepoToPostgresqlID, refetchItems } from 'helpers';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, {
  ComponentType,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Subscription } from 'react-apollo';
import { OrderDetailModal } from 'scenes/order/components';
import { useSearch } from '../../contexts';
import { newOrderListColumns } from '../columns';
import { RejectModal } from './components';
import { ApproveModal } from './components/ApproveModal/ApproveModal';

export type NewOrderListOwnProps = AccessProps & {
  renderSyncModal: (refetchFn: () => void) => ReactElement;
};
export type NewOrderListProps = NewOrderListOwnProps & ToastContextProps;

function NewOrderListComponent({
  access,
  openToast,
  renderSyncModal,
}: NewOrderListProps) {
  const [selectedDepo, setSelectedDepo] = useState('');
  const [selectedSubDistrict, setSelectedSubDistrict] = useState('');
  const [selectedOrderDate, setSelectedOrderDate] = useState<Date | null>(null);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [token, setToken] = useState('');
  const [idPostresqlDepo, setIdPostresqlDepo] = useState('');
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [actionData, setActionData] = useState<Order | null>(null);
  const [isRejectVisible, setIsRejectVisible] = useState(false);
  const [isApproveVisible, setIsApproveVisible] = useState(false);

  const searchContent = useSearch();

  const columns = useMemo<ColumnType<Order>[]>(
    () => [
      ...newOrderListColumns,
      {
        id: 'actions',
        size: 220,
        cell: ({ row }) => {
          return (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{
                gap: 4,
              }}
            >
              <Icon
                size="small"
                name="description"
                color={GRAY}
                hoverColor={PRIMARY}
                onPress={() => {
                  setIsDetailVisible(true);
                  setActionData(row.original);
                }}
              />
              {access.update ? (
                <>
                  <Button
                    text="Tolak"
                    color="secondary"
                    inverted
                    onPress={() => {
                      setIsRejectVisible(true);
                      setActionData(row.original);
                    }}
                  />
                  <Button
                    text="Terima"
                    onPress={() => {
                      setIsApproveVisible(true);
                      setActionData(row.original);
                    }}
                  />
                </>
              ) : null}
            </Box>
          );
        },
      },
    ],
    [access.update],
  );

  const clearFilter = () => {
    setSelectedDepo('');
    setSelectedSubDistrict('');
    setSelectedOrderDate(null);
  };

  const orderDate = useMemo(() => {
    const createdAt_gt = selectedOrderDate && new Date(selectedOrderDate);
    const createdAt_lt =
      createdAt_gt &&
      new Date(new Date(createdAt_gt).setHours(23, 59, 59, 999));
    const orderDate = createdAt_gt &&
      createdAt_lt && {
        createdAt_lt: createdAt_lt.toISOString(),
        createdAt_gt: createdAt_gt.toISOString(),
      };

    return orderDate;
  }, [selectedOrderDate]);

  const refetchFn = useCallback(
    (fetchMoreFn: (options: FetchMoreOptions) => void) => () => {
      const createdAt_gt = selectedOrderDate && new Date(selectedOrderDate);
      const createdAt_lt =
        createdAt_gt &&
        new Date(new Date(createdAt_gt).setHours(23, 59, 59, 999));
      const orderDate = createdAt_gt &&
        createdAt_lt && {
          createdAt_lt: createdAt_lt.toISOString(),
          createdAt_gt: createdAt_gt.toISOString(),
        };

      refetchItems<GetOrderListResult, GetOrderListVariables>(fetchMoreFn, {
        query: GET_ORDER_LIST,
        variables: {
          whereData: searchContent || '',
          whereDepot: selectedDepo || idPostresqlDepo || '',
          whereSubDistrict: selectedSubDistrict || '',
          whereStatus: ['WAITING'],
          ...orderDate,
          first: pagination.pageSize,
          skip: pagination.pageIndex * pagination.pageSize,
        },
        dataKey: 'orders',
        rowsPerPage: pagination.pageSize,
        page: pagination.pageIndex,
      });
    },
    [
      searchContent,
      pagination,
      selectedDepo,
      selectedSubDistrict,
      selectedOrderDate,
      idPostresqlDepo,
    ],
  );

  useEffect(() => {
    (async () => {
      const nameUserDepo = await asyncStorage.getName();
      const token = await asyncStorage.getToken();

      setToken(String(token));

      try {
        const idPostresqlDepo = convertDepoToPostgresqlID(String(nameUserDepo));

        setIdPostresqlDepo(idPostresqlDepo);
      } catch (error) {
        setIdPostresqlDepo('');
      }
    })();
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        m="20px 0"
      >
        <Grid container spacing={2} alignItems="center" style={{ zIndex: 2 }}>
          <Grid item>
            <DepotPicker
              isFilter
              selectedOption={selectedDepo}
              onChange={(selected) =>
                setSelectedDepo(selected ? selected.value : '')
              }
              hidden={access.role !== 'SUPER_ADMIN'}
            />
          </Grid>
          <Grid item>
            <SubDistrictPicker
              isFilter
              selectedOption={selectedSubDistrict}
              onChange={(selected) =>
                setSelectedSubDistrict(selected ? selected.value : '')
              }
            />
          </Grid>
          <Grid item>
            <DatePickerField
              isFilter
              label="Tanggal Order"
              placeholder="Tanggal Order"
              selectedDate={selectedOrderDate}
              onChange={(date) => setSelectedOrderDate(date)}
            />
          </Grid>
        </Grid>
        <ResetFilterButton onPress={clearFilter} />
      </Box>
      <Query<GetOrderListResult, GetOrderListVariables>
        query={GET_ORDER_LIST}
        keyData="orders"
        variables={{
          whereData: searchContent,
          whereDepot: selectedDepo || idPostresqlDepo || '',
          whereSubDistrict: selectedSubDistrict || '',
          whereStatus: ['WAITING'],
          ...orderDate,
          first: pagination.pageSize,
          skip: pagination.pageSize * pagination.pageIndex,
        }}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange
        disableLoading
      >
        {({ data, loading, fetchMore, refetch }) => (
          <>
            {renderSyncModal(refetchFn(fetchMore))}
            <Subscription<{ id: string }>
              subscription={GET_NEW_ORDER_NOTIFICATION}
              onSubscriptionData={async () => {
                openToast('success', 'Order baru diterima!');

                try {
                  await refetch();
                } catch (error) {
                  openToast('fail', String(error));
                }
              }}
              variables={{
                token: access.role === 'SUPER_ADMIN' ? '' : token,
              }}
            />
            <Subscription<{ id: string }>
              subscription={GET_NEW_REJECTED_ORDER_NOTIFICATION}
              onSubscriptionData={async () => {
                try {
                  await refetch();
                } catch (error) {
                  openToast('fail', String(error));
                }
              }}
            />
            <ReactTable
              columns={columns}
              data={data?.orders ?? []}
              total={data?.count ?? 0}
              pagination={pagination}
              setPagination={setPagination}
              isLoading={loading}
            />
            <ApproveModal
              data={actionData}
              onClose={() => setIsApproveVisible(false)}
              refetchFn={refetchFn(fetchMore)}
              isVisible={isApproveVisible}
            />
            <RejectModal
              data={actionData}
              onClose={() => setIsRejectVisible(false)}
              isVisible={isRejectVisible}
              refetchFn={refetchFn(fetchMore)}
            />
            {actionData ? (
              <OrderDetailModal
                isVisible={isDetailVisible}
                order={actionData}
                onClose={() => setIsDetailVisible(false)}
              />
            ) : null}
          </>
        )}
      </Query>
    </Box>
  );
}

export const NewOrderList = withToast(NewOrderListComponent) as ComponentType<
  NewOrderListOwnProps
>;
