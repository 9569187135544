import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';

import { Text, Icon } from '../../../core-ui';
import { Table, Query, RowsPerPage } from '../../../components';
import {
  formatNumberDate,
  fetchMoreItems,
  refetchItems,
} from '../../../helpers';
import { GRAY, PRIMARY, RED } from '../../../constants/colors';
import withToast, { ToastContextProps } from '../../../helpers/withToast';

import {
  AccessProps,
  GET_COUPONS,
  Coupon,
  GetCouponsResult,
} from '../../../graphql/queries';
import { DELETE_COUPON, DeleteCouponVars } from '../../../graphql/mutations';
import { DetailModal } from './DetailModal';
import { DeleteModal } from './DeleteModal';

type ContentProps = AccessProps & { searchKey: string };

function ContentComponent({
  access,
  searchKey,
  openToast,
}: ContentProps & Partial<ToastContextProps>) {
  const [page, setPage] = useState(0);
  const [rows, setRows] = useState<RowsPerPage>(10);
  const [resetPage, setResetPage] = useState(false);
  const [isDetailVisible, setDetailVisible] = useState(false);
  const [isDeleteVisible, setDeleteVisible] = useState(false);
  const [couponData, setCouponData] = useState<Nullable<Coupon>>(null);

  return (
    <Query<GetCouponsResult>
      query={GET_COUPONS}
      keyData="coupons"
      fetchPolicy="network-only"
      variables={{
        searchKeyword: searchKey.toLowerCase(),
        first: rows,
        skip: 0,
      }}
      notifyOnNetworkStatusChange
    >
      {({ data, loading, fetchMore }) =>
        data && (
          <Mutation<Coupon, DeleteCouponVars>
            mutation={DELETE_COUPON}
            onCompleted={() => {
              openToast?.('success', 'Kupon telah dihapus.');
              refetchItems<GetCouponsResult>(fetchMore, {
                // TODO: REMOVE TS IGNORE
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                query: GET_COUPONS,
                variables: {
                  searchKeyword: searchKey.toLowerCase(),
                  first: rows,
                  skip: page * rows,
                },
                dataKey: 'coupons',
                rowsPerPage: rows,
                page,
              });
            }}
            onError={() => openToast?.('fail', 'Kupon gagal dihapus.')}
          >
            {(deleteCoupon, { loading: modalLoading }) => (
              <View style={styles.flex}>
                <Table
                  page={page}
                  dataCount={data.count}
                  rowsPerPage={rows}
                  data={data.coupons}
                  isLoading={loading}
                  resetPage={resetPage}
                  structure={{
                    title: { headerTitle: 'Nama Kupon' },
                    date: {
                      headerTitle: 'Masa Berlaku',
                      render: ({ startDate, endDate }) => (
                        <Text size="small">
                          {formatNumberDate(new Date(startDate), true, false)}
                          {' - '}
                          {formatNumberDate(new Date(endDate), true, false)}
                        </Text>
                      ),
                    },
                    couponClaimCount: {
                      headerTitle: 'Sisa Klaim',
                      render: ({ couponClaimCount, couponLimit }) =>
                        couponLimit - Number(couponClaimCount),
                    },
                    status: {
                      headerTitle: 'Aktif',
                      render: ({ active }) => (
                        <Text size="small">
                          {active ? 'AKTIF' : 'TIDAK AKTIF'}
                        </Text>
                      ),
                    },
                    action: {
                      noHeaderName: true,
                      render: (coupon) => (
                        <View style={styles.actionWrapper}>
                          <Icon
                            size="small"
                            name="description"
                            color={GRAY}
                            hoverColor={PRIMARY}
                            onPress={() => {
                              setDetailVisible(true);
                              setCouponData(coupon);
                            }}
                          />
                          {access.update && (
                            <Link to={`coupons/${coupon.id}`}>
                              <Icon
                                size="small"
                                name="edit"
                                color={GRAY}
                                hoverColor={PRIMARY}
                              />
                            </Link>
                          )}
                          {access.delete && (
                            <Icon
                              size="small"
                              name="delete"
                              color={GRAY}
                              hoverColor={RED}
                              onPress={() => {
                                setDeleteVisible(true);
                                setCouponData(coupon);
                              }}
                            />
                          )}
                        </View>
                      ),
                    },
                  }}
                  loadMore={({ first, skip }) => {
                    fetchMoreItems<GetCouponsResult>(fetchMore, {
                      // TODO: REMOVE TS IGNORE
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      query: GET_COUPONS,
                      variables: {
                        searchKeyword: searchKey.toLowerCase(),
                        first,
                        skip,
                      },
                      dataKey: 'coupons',
                    });
                  }}
                  onChangePage={setPage}
                  setResetPage={setResetPage}
                  onChangeRowsPerPage={setRows}
                />
                {isDetailVisible && couponData && (
                  <DetailModal
                    data={couponData}
                    onClose={() => {
                      setDetailVisible(false);
                      setCouponData(null);
                    }}
                  />
                )}
                {isDeleteVisible && couponData && (
                  <DeleteModal
                    loading={modalLoading}
                    data={couponData}
                    onSubmit={() => {
                      deleteCoupon({ variables: { id: couponData.id } });
                    }}
                    onClose={() => {
                      setDeleteVisible(false);
                      setCouponData(null);
                    }}
                  />
                )}
              </View>
            )}
          </Mutation>
        )
      }
    </Query>
  );
}

const styles = StyleSheet.create({
  flex: { flex: 1 },
  actionWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
});

export const Content = withToast(ContentComponent);
