import { useEffect, useRef } from 'react';

export type CallbackRef = (instance: Event) => void;

export function useOutsideClick<TRef>(callback: CallbackRef) {
  const ref = useRef<TRef>(null);
  const callbackRef = useRef<CallbackRef>(callback);

  useEffect(() => {
    const handler = (event: Event) => {
      callbackRef.current(event);
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  return ref;
}
