import { RETAIL } from 'constants/priceSegment';
import { Separator } from 'core-ui';
import withToast, { ToastContextProps } from 'helpers/withToast';
import React, { ComponentType, useState } from 'react';
import { useFormContext } from 'scenes/program/NewProgramFormScene/contexts';
import { CustomField } from 'scenes/program/components';
import { LoyaltyProductModal } from 'scenes/program/modals';

type TVIPProductSelectionProps = ToastContextProps;

function TVIPProductSelectionComponent({
  openToast,
}: TVIPProductSelectionProps) {
  const [isLoyaltyProductVisible, setIsLoyaltyProductVisible] = useState(false);

  const { values, onValuesChange } = useFormContext();

  const { selectedSegments, selectedSubSegments, tvipProduct } = values;

  // HARD CODED FOR NOW
  // CAN BE CHANGED IN THE FUTURE
  const priceSegmentCode = RETAIL;

  return (
    <>
      <Separator secondary />
      <CustomField
        headerText="Pilih Jenis Barang"
        bodyText="Tentukan produk TVIP yang akan dijadikan sebagai reward."
        buttonText="Pilih Jenis Barang"
        selectedText={tvipProduct ? 'Produk terpilih' : null}
        onPress={() => {
          if (selectedSegments.length > 0 && selectedSubSegments.length > 0) {
            setIsLoyaltyProductVisible(true);
          } else {
            openToast('fail', 'Pilih segment dan subsegment dahulu.');
          }
        }}
      />
      {isLoyaltyProductVisible &&
        selectedSegments.length > 0 &&
        selectedSubSegments.length > 0 && (
          <LoyaltyProductModal
            priceSegmentCode={priceSegmentCode}
            onClose={() => setIsLoyaltyProductVisible(false)}
            selectedProduct={tvipProduct}
            onChangeSelected={(newSelectedProduct) =>
              onValuesChange({
                tvipProduct: newSelectedProduct,
              })
            }
          />
        )}
    </>
  );
}

export const TVIPProductSelection = withToast(
  TVIPProductSelectionComponent,
) as ComponentType;
